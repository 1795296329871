import { STATIC_IMAGES } from "../../../assets/staticImages";
import { SimpleInputType } from "../../../common/components/simpleInputEl";
import { ISurvey } from "../interface/survey";
import { ISurveyDetailBlockType, ISurveyDetailImageBlock, ISurveyDetailInputBlock, ISurveyDetailInputMultipleBlock } from "../interface/surveyDetailBlock";

export const surveySmoking: ISurvey = {
  title: "Vyplň informace o svých kuřáckých zvyklostech",
  img: STATIC_IMAGES.ashtray,
  successVarName: "profileStrength_smoking",
  showPredicate: (val: any) => val == null,
  pages: [
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_1.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_NUMBER,
          text: "Od kolika let pravidelně kouříš?",
          varName: "AnaData_smokingSince",
        } as ISurveyDetailInputBlock
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_2.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION,
          text: "Který z následujících tabákových a nikotinových produktů jsi už zkouše{{VerbPast}}",
          varName: "AnaData_products",
          alternatives: [
            {
              label: "Klasické cigarety",
              value: "1",
            },
            {
              label: "Doutník",
              value: "2",
            },
            {
              label: "Dýmku",
              value: "3",
            },
            {
              label: "Vodní dýmku",
              value: "4",
            },
            {
              label: "Šňupací tabák",
              value: "5",
            },
            {
              label: "Bezdýmý tabák (snuss)",
              value: "6",
            },
            {
              label: "Žvýkací tabák",
              value: "7",
            },
            {
              label: "Elektronickou cigaretu s nikotinem",
              value: "8",
            },
            {
              label: "Elektronickou cigaretu bez nikotinu",
              value: "9",
            },
            {
              label: "Elektronickou cigaretu s marihuanou (THC, CBD)",
              value: "10",
            },
            {
              label: "Zahřívaný tabák (např. IQOS, Glo)",
              value: "11",
            },
            {
              label: "Narkotinové sáčky (např. LYFT, KILLA, ZYN)",
              value: "12",
            },
          ]
        } as ISurveyDetailInputMultipleBlock,
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_3.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION,
          text: "Který z těchto produktů užíváš v současnosti (aspoň jednou týdně)?",
          varName: "AnaData_usage",
          alternatives: [
            {
              label: "Klasické cigarety",
              value: "1",
            },
            {
              label: "Doutník",
              value: "2",
            },
            {
              label: "Dýmku",
              value: "3",
            },
            {
              label: "Vodní dýmku",
              value: "4",
            },
            {
              label: "Šňupací tabák",
              value: "5",
            },
            {
              label: "Bezdýmý tabák (snuss)",
              value: "6",
            },
            {
              label: "Žvýkací tabák",
              value: "7",
            },
            {
              label: "Elektronickou cigaretu s nikotinem",
              value: "8",
            },
            {
              label: "Elektronickou cigaretu bez nikotinu",
              value: "9",
            },
            {
              label: "Elektronickou cigaretu s marihuanou (THC, CBD)",
              value: "10",
            },
            {
              label: "Zahřívaný tabák (např. IQOS, Glo)",
              value: "11",
            },
            {
              label: "Narkotinové sáčky (např. LYFT, KILLA, ZYN)",
              value: "12",
            },
          ]
        } as ISurveyDetailInputMultipleBlock,
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_7.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION,
          text: "Proč ses rozhod{{VerbPast}} přestat kouřit?",
          varName: "AnaData_reason",
          alternatives: [
            {
              label: "Kvůli zdraví",
              value: "1",
            },
            {
              label: "Kvůli rodině",
              value: "2",
            },
            {
              label: "Kvůli financím",
              value: "3",
            },
            {
              label: "Kvůli tlaku partnera, dětí, přátel, kolegů",
              value: "4",
            },
            {
              label: "Kvůli zaměstnání",
              value: "5",
            },
            {
              label: "Abych se zbavi{{VerbPast}} závislosti",
              value: "6",
            },
            {
              label: "Kvůli obavě z koronaviru",
              value: "7",
            },
            {
              label: "Jiný důvod:",
              value: "8",
            },
          ],
          lastOptionSimpleInput: {
            inputType: SimpleInputType.TEXT,
            varName: "AnaData_reason_text"
          }
        } as ISurveyDetailInputMultipleBlock,
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_onboarding_5.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE,
          text: "Kolikrát v životě jsi už zkouše{{VerbPast}} přestat kouřit?",
          varName: "AnaData_attempts",
          alternatives: [
            {
              label: "Nikdy",
              value: "0",
            },
            {
              label: "Alespoň jednou",
              value: "1",
            },
          ],
          lastOptionSimpleInput: {
            inputType: SimpleInputType.NUMBER,
            varName: "AnaData_attempts_text"
          }
        } as ISurveyDetailInputMultipleBlock,  
      ]
    },
    {
      skip: (userVariables: { [key: string]: any }) => userVariables["AnaData_attempts"] === "0",
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_5.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE,
          text: "Jak dlouho jsi vydrže{{VerbPast}} nekouřit při posledním pokusu?",
          varName: "AnaData_lastDuration",
          alternatives: [
            {
              label: "Méně než 1 den",
              value: "1",
            },
            {
              label: "Méně než týden",
              value: "2",
            },
            {
              label: "1 týden až 1 měsíc",
              value: "3",
            },
            {
              label: "1 - 3 měsíce",
              value: "4",
            },
            {
              label: "3 - 6 měsíců",
              value: "5",
            },
            {
              label: "Půl roku až rok",
              value: "6",
            },
            {
              label: "1 rok a více",
              value: "7",
            },
          ]
        } as ISurveyDetailInputMultipleBlock,  
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_6.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION,
          text: "Jak ses o odvykání pokouše{{VerbPast}}?",
          varName: "AnaData_methods",
          alternatives: [
            {
              label: "Bez odborné pomoci a bez léků",
              value: "1",
            },
            {
              label: "S náhradním nikotinem (žvýkačky, náplasti, sprej, pastilky)",
              value: "2",
            },
            {
              label: "S pomoci léků (Champix, Wellbutrin, Elontril, Zyban)",
              value: "3",
            },
            {
              label: "S pomocí lékaře",
              value: "4",
            },
            {
              label: "S pomocí adiktologa, psychologa nebo terapeuta ",
              value: "5",
            },
            {
              label: "S pomocí lékárníka",
              value: "6",
            },
            {
              label: "S pomocí telefonické linky pro odvykání",
              value: "7",
            },
            {
              label: "S pomocí informačních webových stránek",
              value: "8",
            },
            {
              label: "S pomocí mobilní aplikace",
              value: "9",
            },
            {
              label: "Jiná metoda:",
              value: "10",
            },
          ],
          lastOptionSimpleInput: {
            inputType: SimpleInputType.TEXT,
            varName: "AnaData_methods_text"
          }
        } as ISurveyDetailInputMultipleBlock,
      ]
    }
  ],
}