import React, { useEffect, useState } from "react"

const DebugContext = React.createContext<any>([false, () => {}])

// Debug mode for controlling session skipping and resetting
function DebugProvider({children}: any) {

  const [isDebugMode, setIsDebugMode] = useState<boolean>(false);

  useEffect(() => {
    if(localStorage["isDebugMode"] != null && localStorage["isDebugMode"]) {
      setIsDebugMode(localStorage["isDebugMode"] === "true");
    }
  }, [])

  useEffect(() => {
    localStorage["isDebugMode"] = isDebugMode;
  }, [isDebugMode])

  return (
    <DebugContext.Provider value={[isDebugMode, setIsDebugMode]}>
      {children}
    </DebugContext.Provider>
  )
}

export { DebugProvider, DebugContext };