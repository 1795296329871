import React, { useEffect, useState } from "react";
import { IBadge } from "./interfaces/badge";
import { IBadgeDetailBlockType, IBadgeDetailTextBlock, IBadgeDetailImageBlock, IBadgeDetailInputBlock } from "./interfaces/badgeDetailBlocks";
import TextEl from "../../common/components/textEl";
import SimpleInputEl, { SimpleInputType } from "../../common/components/simpleInputEl";
import ImageTextEl from "../../common/components/imageEl";
import { STATIC_IMAGES } from "../../assets/staticImages";
import "./badgeDetailBuilder.scss";

interface BadgeDetailBuilderProps {
  badge?: IBadge;
  languageVariables: { [key: string]: string },
  userVariables: { [key: string]: string };
  cancel: () => void;
  save: (usedVarNames: string[], userVars: {[key: string]: string}) => void;
  isDetailVisible: boolean;
  currentBadgeIndex: number | undefined;
  setCurrentBadgeIndex: (newIndex: number | undefined) => void;
  badgeElementsNumber: number;
}

const BadgeDetailBuilder = ({
  badge,
  languageVariables,
  userVariables,
  cancel,
  save,
  isDetailVisible,
  currentBadgeIndex,
  setCurrentBadgeIndex,
  badgeElementsNumber, // for showing arrows to move between badges
}: BadgeDetailBuilderProps) => {

  const iconFile = require(`./../../assets/images/phoneApp/${badge?.imageUrl ?? "adiquit_icon_locked_content.png"}`);

  const [temporaryUserVariables, setTemporaryUserVariables] = useState<{ [key: string]: string }>(
    Object.assign({}, userVariables));

  const [elements, setElements] = useState<JSX.Element[]>([]);

  useEffect(() => {
    // Process InputFallBlocks - deciding between two variables which to show
    if (badge != null && badge.detailBlocks != null) {
      for (const index in badge.detailBlocks) {
        const curDetailBlock = badge.detailBlocks[index];
        if (curDetailBlock.type === IBadgeDetailBlockType.INPUT_TEXT_FALLBACK) {
          let chosenVarN = curDetailBlock.varName[0];
          for (const varN of curDetailBlock.varName) {
            if(userVariables[varN] != null) {
              chosenVarN = varN;
              break;
            }
          }
          const newBlock: IBadgeDetailInputBlock  = {
            type: IBadgeDetailBlockType.INPUT_TEXT,
            text: curDetailBlock.text,
            varName: chosenVarN
          }
          badge.detailBlocks[index] = newBlock;
        }
      }
    }
  }, [badge, userVariables])

  useEffect(() => {
    setTemporaryUserVariables(userVariables);
  }, [userVariables])

  useEffect(() => {
    buildBadgeDetail();
  }, [temporaryUserVariables])


  function buildBadgeDetail() {
    if (badge == null || badge.detailBlocks === undefined) {
      return;
    }
    const newArray: JSX.Element[] = []
    badge.detailBlocks.forEach(detailBlock =>{
      switch(detailBlock.type) {
        case IBadgeDetailBlockType.TEXT_BLOCK:
          newArray.push(buildTextBlock(detailBlock));
          break;
        case IBadgeDetailBlockType.INPUT_TEXT:
          newArray.push(buildInputTextBlock(detailBlock));
          break;
        case IBadgeDetailBlockType.INPUT_NUMBER:
          newArray.push(buildInputNumberBlock(detailBlock));
          break;
        case IBadgeDetailBlockType.IMAGE_BLOCK:
          newArray.push(buildImageBlock(detailBlock));
          break;
      }
    })
    setElements(newArray);
  }

  function buildTextBlock(detailBlock: IBadgeDetailTextBlock): JSX.Element {
    return <TextEl
      text={detailBlock.text}
      presentVarNames={Object.keys(languageVariables)}
      apiVariables={temporaryUserVariables}
      key={detailBlock.text}
    />
  }

  function buildInputTextBlock(detailBlock: IBadgeDetailInputBlock): JSX.Element {
    return <SimpleInputEl
      labelText={detailBlock.text}
      inputName={detailBlock.varName}
      apiVariables={temporaryUserVariables}
      setApiVariables={setTemporaryUserVariables}
      inputType={SimpleInputType.TEXTAREA}
      cssClassName={"success-user-variables-input"}
      key={detailBlock.text}
      required={true}
    />
  }

  function buildInputNumberBlock(detailBlock: IBadgeDetailInputBlock): JSX.Element {
    return <SimpleInputEl
      inputName={detailBlock.varName}
      labelText={detailBlock.text}
      apiVariables={temporaryUserVariables}
      setApiVariables={setTemporaryUserVariables}
      inputType={SimpleInputType.NUMBER}
      cssClassName={"success-user-variables-input"}
      key={detailBlock.varName}
      required={true}
    />
  }

  function buildImageBlock(detailBlock: IBadgeDetailImageBlock): JSX.Element {
    return <ImageTextEl
      imageUrl={detailBlock.imgUrl}
      className={"detail-image"}
      key={detailBlock.imgUrl}
    />
  }

  function saveUserVariables(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    // validation
    if(!isFormValid()) {
      return; 
    }
    console.log("inputVars", temporaryUserVariables)
    save(getVariablesInDetailBlocks(), temporaryUserVariables);
  }

  function getVariablesInDetailBlocks(): string[] {
    const usedVariables: string[] = [];
    badge?.detailBlocks?.forEach(detailBlock => {
      if(detailBlock.type === IBadgeDetailBlockType.INPUT_NUMBER ||
         detailBlock.type === IBadgeDetailBlockType.INPUT_TEXT) {
          usedVariables.push(detailBlock.varName);
        }
    })
    return usedVariables;
  }

  function isFormValid(): boolean {    
    if(badge != null && badge.detailBlocks != null) {
      for (const block of badge?.detailBlocks) {
        // NaN
        if(block.type === IBadgeDetailBlockType.INPUT_NUMBER) {
          if (isNaN(+temporaryUserVariables[block.varName])) {
            return false
          }
        }
        // empty
        if(block.type === IBadgeDetailBlockType.INPUT_NUMBER ||
          block.type === IBadgeDetailBlockType.INPUT_TEXT) {
          if (temporaryUserVariables[block.varName] === "" || temporaryUserVariables[block.varName] === null) {
            return false
          }
        }
      }
    }
    return true
  }

  function cancelUserVariables(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    setTemporaryUserVariables(userVariables)
    cancel();
  }

  return (
      <div className="badge-detail-form" style={{display: isDetailVisible ? "flex" : "none"}}>
        <button onClick={(e) => {cancelUserVariables(e)}} className="cancel-button">
          <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
        </button>
        <div className="badge-detail-headline">
          <img src={iconFile} alt={badge?.imageUrl}/>
          <span className="badge-detail-title">{badge?.title}</span>
        </div>
        <form>
          {elements.map(el => el)}
          {badge != null && badge?.detailBlocks != null &&
          badge?.detailBlocks?.filter(block => block.type === IBadgeDetailBlockType.INPUT_NUMBER ||
            block.type === IBadgeDetailBlockType.INPUT_TEXT).length > 0 &&
            <div className="badge-detail-footer">
              <button onClick={(e) => {cancelUserVariables(e)}}>Zrušit</button>
              <button type="submit" onClick={(e) => {saveUserVariables(e)}}>Uložit</button>
            </div>}
        </form>
      </div>
  );
};

export default BadgeDetailBuilder