import React, { useEffect, useState } from "react"
import * as crypto from "crypto-js";
import { API_ROUTES } from "../api/apiRoutes";
import logoutUser from "../api/requests/logoutUser";

const AuthContext = React.createContext<any>([undefined, () => {}, () => {}])

function AuthProvider({children}: any) {

  const [token, setToken] = useState<string>("");

  useEffect(() => {
    // Load token from cache so user doesn't have to log in if he was logged in before
    if(token === "" && localStorage["adiquitToken"] && localStorage["adiquitToken"] !== "") {
      setToken(localStorage["adiquitToken"]);
      console.log("AuthProvider loaded token from cache")
      return;
    }
  }, [])

  function login(email: string, password: string): Promise<boolean> {
    // Server expect those informatio encoded together
    const to_encode = crypto.enc.Utf8.parse(email + ':' + password);
    const encoded = crypto.enc.Base64.stringify(to_encode);
    const requestOptions = {
      method: 'POST',
      headers: {
        "Authorization": "Basic " + encoded,
        "Content-Type": "application/json",
      },
    };
    return fetch(API_ROUTES.loginUrl, requestOptions)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.token) {
          console.log("Logging successful!",);
          setToken(data.token);
          localStorage["adiquitToken"] = data.token;
          return new Promise<boolean>(resolve => {resolve(true);});
        } else {
          console.log("špatné přihlašovací údaje")
          return new Promise<boolean>(resolve => {resolve(false);});
        }
      }).catch(e => {
        console.log("Error logging in", e);
        return new Promise<boolean>(resolve => {resolve(false);});
      })
  }

  const logout = () => {
    logoutUser(token).then(x => {
      // Unfortunately doesn't work as cookies which control session are HTTP ONLY, so server mussremove them!
      document.cookie = "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      document.cookie = "serafin_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      localStorage.clear();
      setToken("");
    });
  }

  return (
    <AuthContext.Provider value={[token, login, logout]}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext };