import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/authProvider';

interface LoggedRouteProps {
  Component: () => JSX.Element;
  path: string;
}

const LoggedRoute = ({ Component, path }: LoggedRouteProps) => {
  const [token, login, logout] = useContext(AuthContext);
  const [nextPageToGo, setNextPageToGo] = useState<string>("");
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => {
    // Check if user trying to access different page than login
    if(location.pathname !== nextPageToGo && location.pathname !== "/login") {
      if(nextPageToGo !== "/login" && nextPageToGo !== "/") {
        setNextPageToGo(location.pathname);
      }
    }
  },[token, location.pathname])

  useEffect(() => {
    if(token !== "") {
      if (nextPageToGo !== location.pathname) {
        history.push(nextPageToGo)
      }
    }
  }, [nextPageToGo])

  return (
    <Route
      path={path}
      render={props =>
        token !== "" ? (
          <Component />
        ) : (
          // Redirect user to login page if not logged and tries to access any app page (except for onboarding and login)
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

export default LoggedRoute;