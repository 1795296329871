import React, { useContext, useEffect, useRef, useState } from "react"
import { HashLink as Link } from 'react-router-hash-link';
import { ListUtils } from "../../common/utils/listUtils";
import NavBar, { NavBarItem } from "../../common/navbar/navBar";
import { IMemento, mementoData } from "../plan/data/mementos";
import { badgeData } from "../success/data/badges";
import { IBadge } from "../success/interfaces/badge";
import "./home.scss"
import { IStatistic } from "../success/interfaces/statistic";
import getUserStatistics from "../../api/requests/getUserStatistics";
import { AuthContext } from "../../context/authProvider";
import getUserSummary from "../../api/requests/getUserSummary";
import { IUserSummary } from "../../api/interfaces/userSummary";
import getPage from "../../api/requests/getPage";
import { STATIC_IMAGES } from "../../assets/staticImages";
import TextEl from "../../common/components/textEl";
import Discount from "./discount";
import PaymentArgument from "../appointment/payment/paymentArgument";
import getPaymentInfo from "../../api/requests/getPaymentInfo";
import { paymentArguments } from "../appointment/data/paymentArguments";
import FinalWindow from "../appointment/finalWindow";
import { finalGoodbyePages } from "../appointment/data/finalGoodbyePages";
import getCertainVariables from "../../api/requests/getCertainVariables";
import sendUserVariables from "../../api/requests/sendUserVariables";

//a
import PageWrapper from "../../common/components/pageWrapper";
import HomeWidgetWrapper from "./components/HomeWidgetWrapper";
import Badges from "../success/components/Badges";
import ProfileStrengthIndicator from "../profil/ProfileStrengthIndicator";
import RandomTip from "../help/RandomTip";
import FeedbackFormular from "../../common/components/FeedbackFormular";

const Home = () => {
  const [token, login, logout] = useContext(AuthContext);
  const languageVariables = ["AdjArch", "VerbPast", "AdjEnd"]
  const finalPhaseVarNames = ["PHASE", "web_final_showed"];
  const [finalPhaseVariables, setFinalPhaseVariables] = useState<{[key: string]: any} | undefined>()
  

  const [statistics, setStatistics] = useState<IStatistic[]>([]);
  const [badges, setBadges] = useState<IBadge[]>(badgeData.slice().reverse());
  const [userSummary, setUserSummary] = useState<{[key: string]: any}>({variables: {}});
  
  const [apiData, setApiData] = useState<any>();
  const welcomePageTextSessionAvailable = "Ahoj {{Name5p}}, jestli máš čas, klikni na tlačítko a posuneme se dále na tvé cestě k nekouření."
  const welcomePageTextNoContext = "Zatím nemám nic nového - připravené téma jsme už probrali. Mezitím můžeš zkusit využít tipy v sekci 'Má podpora'. Co nevidět se ti zase ozvu!"
  

  const mementos: IMemento[] = mementoData.slice().reverse();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [randomStatistic, setRandomStatistic] = useState<IStatistic | undefined>(undefined);

  const randomStatisticImage = useRef();

  const [lastGainedBadgeTitle, setLastGainedBadgeTitle] = useState<string | undefined>(undefined);
  const lastGainedBadgeImage = useRef();

  const [lastMemento, setLastMemento] = useState<IMemento | undefined>(undefined);
  const lastMementoImage = useRef();

  const appointment_home_images = [STATIC_IMAGES.appointment_home_image_1, STATIC_IMAGES.appointment_home_image_2, STATIC_IMAGES.appointment_home_image_3, STATIC_IMAGES.appointment_home_image_4, STATIC_IMAGES.appointment_home_image_5]; 
  const random_a_h_i_array_idx = Math.floor(Math.random() * appointment_home_images.length);
  const random_appointment_home_image = appointment_home_images[random_a_h_i_array_idx];
  
  var to_appointment_link_display_style = "none"

  useEffect(() => {
    Notification.requestPermission();
    setIsLoading(true);
    getUserStatistics(token).then(stats => {setStatistics(stats)})

    fetchFinalPhaseVariables();
    //fetchPaymentInfo();

    getUserSummary(token).then((summary: IUserSummary | undefined) => {
      if(summary) {
        console.log("received: ", summary)
        setUserSummary(summary)
        setIsLoading(false);
      }
    })
    
    getPage(token).then((data: any) => {
      console.log("Appointment data RECEIVED", data);
      setApiData(data);
    })
  }, [])

  useEffect(() => {    
    if(statistics.length > 0) {
      console.log("Statistics", ListUtils.getRandomListElement<IStatistic>(statistics))
      const rndStat = ListUtils.getRandomListElement<IStatistic>(statistics);
      randomStatisticImage.current = require(`./../../assets/images/phoneApp/${rndStat.icon}.png`)
      setRandomStatistic(rndStat)
    }
  }, [statistics])

  useEffect(() => {

    const gainedBadges = badges.filter(badge => userSummary.variables[badge.userVariableName] &&
      badge.showPredicate(userSummary.variables[badge.userVariableName]))
    if (gainedBadges.length > 0) {
      const badgeTitle = languageVariables.reduce((acc, varName: string) => {
        return acc.replace(`{{${varName}}}`, userSummary.variables[varName]);
      }, gainedBadges[0].title)
      setLastGainedBadgeTitle(badgeTitle);
      lastGainedBadgeImage.current = require(`./../../assets/images/phoneApp/${gainedBadges[0].imageUrl}`)
    }

    const gainedMementos = mementos.filter(memento => userSummary.variables[memento.variableName] != null);
    if (gainedMementos.length > 0) {
      setLastMemento(gainedMementos[0]);
      lastMementoImage.current = require(`./../../assets/images/phoneApp/${gainedMementos[0].imageName}`)
    }

  }, [userSummary])

  const [paymentArgIndex, setPaymentArgIndex] = useState<number>(0);
  const [goPayUrl, setGoPayUrl] = useState<string>();
  const discountEl = useRef<HTMLDivElement>(null);
  const paymentArgsEl = useRef<HTMLDivElement>(null);
  const toggleWindow = (window: HTMLDivElement | null) => {
    if (window) {
      if (window.style.display !== "none") {
        window.style.display = "none";
      } else {
        window.style.display = "flex";
      }
    }
    setPaymentArgIndex(0);
  }

  function setPaymentArgIndexTo(newIndex: number) {
    if (newIndex < 0 || newIndex >= paymentArguments.length) {
      return;
    }
    setPaymentArgIndex(newIndex)
  }

  function fetchPaymentInfo() {
    getPaymentInfo(token).then((x: any) => {
      if(x) {
        setGoPayUrl(x.payment.gw_url)
      }
    })
  }

  const [displayFinalWindow, setDisplayFinalWindow] = useState<boolean>(false);
  const [finalGoodbyePageIndex, setFinalGoodbyePageIndex] = useState<number>(0);

  function fetchFinalPhaseVariables() {
    getCertainVariables(finalPhaseVarNames, token).then((x: any) => {
      if(x?.variables) {
        setFinalPhaseVariables(x.variables);
        console.log("phinal phase vars: ", x.variables)
      }
    })
  }

  useEffect(() => {
    if (finalPhaseVariables && (finalPhaseVariables["PHASE"] === "FIN" || finalPhaseVariables["PHASE"] === "SUR")  &&
      finalPhaseVariables["web_final_showed"] !== true) {
      setDisplayFinalWindow(true);
      sendUserVariables({web_final_showed: true}, token)
    }
  }, [finalPhaseVariables])

  function closeFinalWindow() {
    setDisplayFinalWindow(false);
  }

  function changeFinalWindowPage() {
    if(finalGoodbyePageIndex >= finalGoodbyePages.length - 1) {
      closeFinalWindow();
    } else {
      setFinalGoodbyePageIndex(finalGoodbyePageIndex + 1);
    }
  }

  if (apiData)
  {
    if (!(apiData.dead_end && !userSummary.must_pay))
    {
      to_appointment_link_display_style = "block"
    }
  }
  else
  {
    to_appointment_link_display_style = "none"
  }

  return (
    <PageWrapper>
      <div className="home-wrapper">
        <NavBar chosenItem={NavBarItem.Home}></NavBar>
        <div className="home-content">
          <h1 className="home-title">Ahoj {userSummary.variables["Name5p"]}, rád tě vidím!</h1>
            <div className="home-content-panels">
              <div className="home-content-panel-1">
                {/* PANEL 1 */}
                <HomeWidgetWrapper width="100%" height="575px" title="Sezení" link="/appointment">
                  {apiData ? <img className="widget-big-img" src={random_appointment_home_image} alt="appointment-image"/> : "Načítám obrázek"}
                  <div className="widget-appointment-wrapper">
                    {apiData ?
                      <>
                        <TextEl
                          text={apiData.dead_end && !userSummary.must_pay ? welcomePageTextNoContext : welcomePageTextSessionAvailable}
                          apiVariables={userSummary.variables}
                          presentVarNames={Object.keys(userSummary.variables)}
                          key={apiData.dead_end && !userSummary.must_pay ? welcomePageTextNoContext : welcomePageTextSessionAvailable}
                        />
                        <Link to="/appointment" className="to_appointment_link" style={{ display: to_appointment_link_display_style}}>Pojďme na to!</Link>
                      </>
                      :
                      "Načítám informaci o sezení"}
                  </div>
                </HomeWidgetWrapper>

              </div>
              <div className="home-content-panel-2">
                {/* PANEL 2 */}
                <HomeWidgetWrapper width="100%" height="120px" title="Síla profilu" link="/profil">
                  <ProfileStrengthIndicator />
                </HomeWidgetWrapper>

                {/* PANEL 2 */}
                <HomeWidgetWrapper width="100%" height="170px" title="Rychlá pomoc" link="/help">
                  <RandomTip next_tip={false} />
                </HomeWidgetWrapper>
                {/* PANEL 1  bot*/}
                <HomeWidgetWrapper width="100%" height="120px" title="Mé statistiky" link="/success">
                  {isLoading ? <div>"Načítám tvé statistiky  ..."</div> :
                    <div className="widget-statistics-wrapper">
                      <img className="widget-small-img" src={randomStatistic != null ? randomStatisticImage.current : STATIC_IMAGES.lockedContent} alt="statistic"/>
                      <div>
                        {(randomStatistic != null ?
                          `${randomStatistic?.title}: ${randomStatistic?.val}${randomStatistic?.val_dim}` :
                          `Zatím jsme toho spolu moc nezažili, ale brzy se to změní!`) 
                        }
                      </div>
                    </div>
                  }                
                </HomeWidgetWrapper>  
                {/* PANEL 2 bot */}
                <HomeWidgetWrapper width="100%" height="120px" title="Můj plán" link="/plan">                  
                  {isLoading ? <div>"Načítám tvůj plán  ..."</div> :
                    <div className="widget-memento-wrapper">                  
                      <img className="widget-small-img" src={STATIC_IMAGES.feedback} alt="feedback"/>
                      <div className="widget-memento-text">
                        {lastMemento != null ?
                        `${lastMemento.title}: ${userSummary.variables[lastMemento.variableName]}` :
                        "V našem společném plánu ještě nic nemáme, ale brzy to napravíme!"}
                      </div>
                    </div>
                  }                
                </HomeWidgetWrapper>
              </div>
            </div>
            {/* NO PANEL */}
            <div className="widget-badges-wrapper">
              <HomeWidgetWrapper width="100%" height="177.5px" title="Získané odznaky" link="/success">               
                {isLoading ? <div>"Načítám tvé úspěchy..."</div> :
                  <Badges badge_type="acquired" />
                }                                            
              </HomeWidgetWrapper>
            </div>
              
            <div className="home-content-panels">
              <div className="home-content-panel-1">
              </div>
              <div className="home-content-panel-2">
              </div>
            </div>

          {/* DISCOUNT button and window not active because server doesnt provide url to Go Pay yet */}
          {/* {userSummary && userSummary.must_pay && userSummary.in_trial && userSummary.pricing.amount !== userSummary.pricing.full_amount &&
            Date.parse(userSummary.pricing?.discount_until) > Date.now() &&
            <Discount userSummary={userSummary} />
          }

          <div ref={paymentArgsEl} className="payment-argument-form" style={{display: "none"}}>
            <PaymentArgument
              paymentArgument={paymentArguments[paymentArgIndex]}
              cancel={toggleWindow}
              windowRefEl={paymentArgsEl}
              changePaymentArgs={setPaymentArgIndexTo}
              paymentArgIndex={paymentArgIndex}
              apiVariables={userSummary.variables}
              goPayUrl={goPayUrl}
            />
          </div> */}
          
          {/* Window for final goodbye after getting last badge */}
          {userSummary &&
            <div className="appointment-final-goodbye" style={{display: displayFinalWindow ? "flex" : "none"}}>
              <FinalWindow
                finalGoodbyePage={finalGoodbyePages[finalGoodbyePageIndex]}
                cancel={closeFinalWindow}
                changePage={changeFinalWindowPage}
                apiVariables={userSummary.variables}
              />
            </div>}
            <FeedbackFormular formular_src="https://docs.google.com/forms/d/e/1FAIpQLSdGjhwdkcwykJcPJfapRqGfbJSn3ppVp08VOFHhBTpH6x1FtQ/viewform?embedded=true" />
        </div> 

      </div>    
    </PageWrapper>
  )
}

export default Home;