export class StringUtils {
  // "variable2 and variable1" instead of "{{variable2}} and {{variable1}}"
  // needed for norwegian varnames in string from server in appointments
  public static replaceAllVariablesInTextWithoutMarkup(txt: string, varNames: string[],
    apiVars: { [key: string]: string}) {
    return varNames.reduce((acc, varName) => {
      return acc.split(`${varName}`).join(apiVars[varName]);
    }, txt)
  }

  // "Ahoj, {{Name5p}}" gets replaced by "Ahoj, apiVars[Name5p]"
  public static replaceAllVariablesInText( txt: string, varNames: string[],
    apiVars: { [key: string]: string },
  ): string {
    return varNames.reduce((acc, varName) => {
      return acc.split(`{{${varName}}}`).join(apiVars[varName]);
    }, txt)
  }
}