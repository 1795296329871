import { ISimpleRegisterInfo } from "../../pages/onboarding/interfaces/simpleRegisterInfo";
import { API_ROUTES } from "../apiRoutes";

function simpleRegister(simpleRegisterInfo: ISimpleRegisterInfo): Promise<string | undefined> {
  return fetch(API_ROUTES.simpleRegisterUrl,
    {
      body: JSON.stringify(simpleRegisterInfo),
      method: "POST",
      headers: {"Content-Type": "application/json"}
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if(data.token) {
        return new Promise<string>(resolve => resolve(data.token));
      } else {
        return new Promise<undefined>(resolve => resolve(undefined))
      }
    })
    .catch(e => {
      return new Promise<undefined>(resolve => resolve(undefined))
    })
}

export default simpleRegister;