import React, { useContext, useEffect, useRef, useState } from "react";
import "./FeedbackFormular.scss";

import { STATIC_IMAGES } from "../../assets/staticImages";

const show_feedback_formular_buttons = false

type Props = 
{
    formular_src: string;
}

const FeedbackFormular = (props: Props) => {
    const feedbackWindow = useRef<HTMLDivElement>(null);

    const toggleWindow = (window: HTMLDivElement | null) => {
        if (window) {
          if (window.style.display !== "none") {
            window.style.display = "none";
          } else {
            window.style.display = "flex";
          }
        }
    }
    if (show_feedback_formular_buttons)
    {
        return (
            <>
                <div className="feedback-wrapper">
                    <button className="feedback-button" onClick={(e) => {toggleWindow(feedbackWindow.current)}}>
                        <span className="feedback-button-title">Formulář pro zpětnou vazbu</span>
                        <img className="feedback-button-img" src={STATIC_IMAGES.pen} />
                    </button>
                </div>

                <div ref={feedbackWindow} className="feedback-formular-window" style={{display: "none"}}>
                    <button className="cancel-button" onClick={(e) => {toggleWindow(feedbackWindow.current)}}>
                        <img className="cancel-button-img" src={STATIC_IMAGES.cancelImg} alt="cancel" />
                    </button>
                    <div className="formular-iframe">
                        <iframe src={props.formular_src} width="400" height="430">Načítání formuláře</iframe>
                    </div>
                </div>
            </>
        );
    }
    else
    {
        return(<></>)
    }
}

export default FeedbackFormular;