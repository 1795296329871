import React, { useContext, useEffect, useRef, useState } from "react"
import NavBar, { NavBarItem } from "../../common/navbar/navBar";
import "./appointment.scss"
import "./session/elements.scss"
import { IApiVariableValue } from "./interfaces/apiVariableValue";
import { AuthContext } from "../../context/authProvider";
import sendPageVariables from "../../api/requests/sendPageVariables";
import sendUserVariables from "../../api/requests/sendUserVariables";
import Session from "./session/session";
import { IUserSummary } from "../../api/interfaces/userSummary";
import getUserSummary from "../../api/requests/getUserSummary";
import { ObjectUtils } from "../../common/utils/objectUtils";
import getPage from "../../api/requests/getPage";
import restartSession from "../../api/requests/restartSession";
import skipSession from "../../api/requests/skipSession";
import { useParams } from "react-router";
import Intro from "./intro";
import Payment from "./payment/payment";
import { STATIC_IMAGES } from "../../assets/staticImages";
import { IPendingNotification } from "../../api/interfaces/replyNotification";
import getPendingReplyNotification from "../../api/requests/getPendingReplyNotification";
import sendNotificationReply from "../../api/requests/sendNotificationReply";
import getCertainVariables from "../../api/requests/getCertainVariables";
import { badgeData } from "../success/data/badges";
import { bachelorExample } from "../../api/mockData/appointments/bachelorExample";

//a
import PageWrapper from "../../common/components/pageWrapper";
import FeedbackFormular from "../../common/components/FeedbackFormular";

const Appointment = () => {

  const urlParam: { [key: string]: any } = useParams();

  const languageVariablesVarNames = ["VerbPast", "AdjArch", "AdjEnd"];
  const badgeVarNames = ["Name5p", "EEcp", "PHASE", "FU"];
  const [token, login, logout] = useContext(AuthContext);
  
  const [inSession, setInSession] = useState<boolean>(false);

  const appointmentContainer = useRef<HTMLDivElement>(null);

  const [apiData, setApiData] = useState< {[key: string]: any} >();
  const [userSummary, setUserSummary] = useState<{ [key: string]: any }>();
  const [pendingReplyNotification, setPendingReplyNotification] = useState<IPendingNotification | {}>();
  const [badgeVariables, setBadgeVariables] = useState<{[key: string]: any} | undefined>()
  useEffect(() => {
    // // TESTING, MOCK RESOURCES
    // const data = bachelorExample;
    // if(!inSession) {
    //   fetchPendingReplyNotification();
    // }
    // fetchBadgeVariables();
    // fetchUserSummary();
    // console.log("data: ", data)
    // setApiData(data);
    
    // API RESOURCES
    if(!inSession) {
      fetchPendingReplyNotification();
    }
    fetchBadgeVariables();
    fetchUserSummary();
    getPage(token)
      .then((data: any) => {
        console.log("Appointment data RECEIVED", data);
        if(appointmentContainer && appointmentContainer.current) {
          appointmentContainer.current.scrollTop = 0;
        }
        setApiData(data);
      })
  }, []);

  function fetchPendingReplyNotification() {
    getPendingReplyNotification(token).then((x: IPendingNotification | undefined | {}) => {
      if(x != null) {
        console.log("Pending reply notification RECEIVED: ", x)
        setPendingReplyNotification(x)
      }
    });
  }

  function fetchUserSummary() {
    getUserSummary(token).then((summary: IUserSummary | undefined) => {
      if(summary) {
        console.log("received: ", summary)
        setUserSummary(summary)
      }
    })
  }

  function fetchBadgeVariables() {
    getCertainVariables(badgeVarNames, token).then((x: any) => {
      if(x?.variables) {
        setBadgeVariables(x.variables);
      }
    })
  }

  function sendAnswer(apiVariables: { [key: string]: IApiVariableValue }) {
    const variablesSentToServer: {[key: string]: any} = {};
    Object.keys(apiVariables)
      .filter(key => apiVariables[key].dynamicInputBinded)
      .forEach(key => { variablesSentToServer[key] = apiVariables[key].value })
    // IF there are any variables in this page that need to be sent to server
    if(Object.keys(variablesSentToServer).length !== 0) {
      // save variables first and than get next page
      sendUserVariables(variablesSentToServer, token).then(result => {
        sendPageVariables({}, token).then(data => {
          if(!apiData?.dead_end) {
            setApiData(data)
          }
        })
      });
    } else {
      // only get next page
      sendPageVariables({}, token).then(data => {
        if(!apiData?.dead_end) {
          setApiData(data)
        } else {
          handleBadgeVariablesChanged();
          setInSession(false);
        }
      });
    }
  }

  function restartSessionClick() {
    restartSession(token).then(x => getPage(token)
    .then((data: any) => {
      console.log("Appointment data RECEIVED", data.data);
      setApiData(data);
    }))
  }

  function skipSessionClick() {
    skipSession(token).then(x => {
      // timeout because no way how to see when is the page ready
      setTimeout(function(){
        getPage(token).then((data: any) => {
          console.log("Appointment data RECEIVED", data.data);
          setApiData(data);
        })
      }, 3000);
    })
  }

  function replyToNotification(reply: boolean) {
    // Reply to pending notification
    sendNotificationReply(reply, token).then(x => {
      console.log(x);
      setPendingReplyNotification({});
      getPage(token).then((data: any) => {
        setApiData(data);
      })
    });
  }

  function handleBadgeVariablesChanged() {
    getCertainVariables(badgeVarNames, token).then((res: any) => {
      handleNewBadgeAcquired(res.variables);
    })
  }

  function handleNewBadgeAcquired(newBadgeVariables: any) {
    // Check if user got any new badge with this appointment
    if(newBadgeVariables && badgeVariables && userSummary) {
      const oldAcquiredBadges = badgeData.filter(badge => badgeVariables[badge.userVariableName] &&
        badge.showPredicate(badgeVariables[badge.userVariableName]));
      const newAcquiredBadges = badgeData.filter(badge => newBadgeVariables[badge.userVariableName] &&
        badge.showPredicate(newBadgeVariables[badge.userVariableName]));
      if(oldAcquiredBadges.length < newAcquiredBadges.length) {
        const newlyAcquired = newAcquiredBadges[newAcquiredBadges.length - 1];
        const newlyAcquiredTitle = languageVariablesVarNames.reduce((acc, varName: string) => {
          return acc.replace(`{{${varName}}}`, userSummary.variables[varName]);
        }, newlyAcquired.title);
        new Notification('Získal jsi nový odznak!', { body: newlyAcquiredTitle, icon: require(`../../assets/images/phoneApp/${newlyAcquired.imageUrl}`)})
      }
    }
  }

  function chooseAppointmentWindow(data: {[key: string]: any}, summary: {[key: string]: any},
     pendingNotification: IPendingNotification | {}): JSX.Element {
    if(summary.must_pay) {
      return <Payment apiData={data} userSummary={summary}/>;
    } else if(inSession || urlParam?.parameter === "readonly") {
      return <Session apiData={data} sendAnswer={sendAnswer} token={token}
        key={data.title_admin} readonly={urlParam?.parameter === "readonly"}
        languageVariables={ObjectUtils.pickObjectSubset(summary["variables"], languageVariablesVarNames)}/>;
    } else {
      return <Intro apiData={data} setInSession={setInSession} userSummary={summary}
        restartSessionClick={restartSessionClick} skipSessionClick={skipSessionClick}
        pendingNotification={pendingNotification} replyToNotification={replyToNotification} />
    }
  }

  return (
    <PageWrapper>
      <NavBar chosenItem={NavBarItem.Appointment}></NavBar>
      <div className="appointment-wrapper">
          <div ref={appointmentContainer} className="appointment-content">
            {apiData != null && userSummary != null && pendingReplyNotification != null && badgeVariables ?
              chooseAppointmentWindow(apiData, userSummary, pendingReplyNotification)
            : 
              "Načítám sezení..."
            }
        </div>
        <FeedbackFormular formular_src="https://docs.google.com/forms/d/e/1FAIpQLSeAchzRKHN2VyOTA8gWARpTCqxPDOje5aHktpr5HxfEoXlLBg/viewform?embedded=true" />
      </div>
    </PageWrapper>  
  )
}

export default Appointment;