import React from "react";
import { STATIC_IMAGES } from "../../assets/staticImages";
import "./hints.scss";

type Props = 
{
    text: string;
    order: number;
    show: boolean;
}

const Hints = (props: Props) =>
{
    const hideButtonId = "hideButton" + props.order.toString();
    const hintContainerId = "hintContainer" + props.order.toString();
    const showButtonId = "showButton" + props.order.toString();

    const hideButton = document.getElementById(hideButtonId);
    const hintContainer = document.getElementById(hintContainerId);
    const showButton = document.getElementById(showButtonId);
    var bool_show = props.show;

    //TODO vymyslet types, check korektnost
    function hide(element: any)
    {
        element.style.display = "none";
        bool_show = false;
    }
    function show(element: any)
    {
        element.style.display = "block";
        bool_show = true;
    }



    hideButton?.addEventListener("click", () => {hide(hintContainer); show(showButton);});
    showButton?.addEventListener("click", () => {show(hintContainer); hide(showButton);});

    return (
        <div className="hint-wrapper">
            <button className="show-button" id={showButtonId}>?</button>
            <div className="hint-container" id={hintContainerId}>
                <p className="hint-p">{props.text}</p>
                <button className="hide-button" id={hideButtonId}>Rozumím</button>
            </div>
        </div>
    )
}

export default Hints;