import React from "react"
import { paymentArgumentFeedbackEl } from "./../data/paymentArguments"
import MultipleSelectionInputEl from "./../../../common/components/multipleSelectionInputEl"

interface IPaymentArgumentPageFeedbackProps {
  apiVariables: { [key: string]: any };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: string | string[]}>>;
}

// Special page of Payment Argument Window
const PaymentArgumentFeedback = ({
  apiVariables,
  setApiVariables,
}: IPaymentArgumentPageFeedbackProps) => {

  return (
    <>
      <MultipleSelectionInputEl
        alternatives={paymentArgumentFeedbackEl.alternatives}
        apiVariables={apiVariables}
        labelText={""}
        setApiVariables={setApiVariables}
        varName={"paymentArgsFeedback"}
        />
      <div>
        Uveď jiný důvod nebo rozveď předchozí
      </div>
      <textarea onChange={e => {
              let newApiVariables = Object.assign({}, apiVariables);
              newApiVariables["paymentFeedbackOwn"] = e.target.value
              setApiVariables(newApiVariables)
          }} />
    </>
  )

}

export default PaymentArgumentFeedback