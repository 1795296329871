import React, { useEffect, useState } from "react"
import DOMPurify from 'dompurify';
import TextEl from "./textEl";

export enum SimpleInputType {
  TEXT,
  TEXTAREA,
  NUMBER
}

interface SimpleInputElProps {
  labelText?: string;
  inputName: string;
  apiVariables: { [key: string]: string };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: any}>>;
  inputType: SimpleInputType;
  cssClassName?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const SimpleInputEl = ({
  labelText,
  inputName,
  apiVariables,
  setApiVariables,
  inputType,
  cssClassName, // add optional class for input styling
  required,
  placeholder,
  disabled,
}: SimpleInputElProps) => {

    const [validationMessage, setValidationMessage] = useState<string>("")

    function changeVariables(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      let newApiVariables = {...apiVariables};
      // Better to sanitize HTML to prevent XSS attacks (embedding iframe with malicious code into input)
      newApiVariables[inputName]= DOMPurify.sanitize(e.target.value)
      if (handleValidity(newApiVariables, e)) {
        setValidationMessage("")
      }
      setApiVariables(newApiVariables)
    }

    function handleValidity(newApiVariables: { [key: string]: string }, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const curValue = newApiVariables[inputName];
      if (required && (curValue == null || curValue === "")) {
        setValidationMessage("Toto pole nesmí zůstat prázdné");
        return false;
      }
      if(inputType === SimpleInputType.NUMBER && !e.target.checkValidity()) {
        setValidationMessage("Použij prosím jenom číslice");
        return false;
      }
      return true
    }
  
    return (
      <React.Fragment>
        {labelText &&
          <TextEl text={labelText} apiVariables={apiVariables} presentVarNames={Object.keys(apiVariables)} />}
        {
        // draw just one input, decided by the simple input type 
        (inputType === SimpleInputType.TEXT &&
          <React.Fragment>
            <input
              required={required ?? false}
              value={apiVariables[inputName] || ""}
              onChange={changeVariables}
              className={cssClassName ?? ""}
              placeholder={placeholder ?? ""}
              disabled={disabled ?? false}
              type="text"
              />
              <span style={{color: "red"}}>{validationMessage}</span>
          </React.Fragment>) 
          ||
          (inputType === SimpleInputType.TEXTAREA &&
            <React.Fragment>
              <textarea
                required={required ?? false}
                value={apiVariables[inputName] || ""}
                style={{width: "100%", resize: "none"}}
                onChange={changeVariables}
                className={cssClassName ?? ""}
                placeholder={placeholder ?? ""}
                disabled={disabled ?? false}
              />
              <span style={{color: "red"}}>{validationMessage}</span>
            </React.Fragment>)
          ||
          (inputType === SimpleInputType.NUMBER &&
            <React.Fragment>
              <input 
                required={required ?? false}
                pattern="[0-9]*" // Checks if only numbers are in input
                value={apiVariables[inputName] || ""}
                style={{width: "100%", resize: "none"}}
                onChange={changeVariables}
                className={cssClassName ?? ""}
                placeholder={placeholder ?? ""}
                disabled={disabled ?? false}
                type="text"
              />
              <span style={{color: "red"}}>{validationMessage}</span>
            </React.Fragment>)
        }
      </React.Fragment>
    );
};

export default SimpleInputEl