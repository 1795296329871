export interface IMemento {
  title: string;
  variableName: string;
  imageName: string;
}

export const mementoData: IMemento[] = [
  {
    title: "Můj důvod",
    variableName: "ReaQui",
    imageName: "ic_profile_heart.svg"
  },
  {
    title: "Řešení chuti na cigaretu",
    variableName: "SmoUrPlan",
    imageName: "ic_profile_swiss_knife.svg"
  },
  {
    title: "Můj nekuřácký plán",
    variableName: "CessationPlan",
    imageName: "ic_profile_checklist.svg"
  },
  {
    title: "Mé rizikové situace",
    variableName: "HRSitPlan",
    imageName: "ic_profile_danger.svg"
  },
  {
    title: "Můj podporovatel",
    variableName: "SupPer",
    imageName: "ic_profile_first_aid_kit.svg"
  }
]