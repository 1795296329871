import React, { useContext, useState } from "react"
import * as crypto from "crypto-js";
import "./login.scss"
import { AuthContext, AuthProvider } from "../../context/authProvider";
import { environment } from "../../config/environment";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { STATIC_IMAGES } from "../../assets/staticImages";

//V
import PageWrapper from "../../common/components/pageWrapper";

const Login = () => {
  const urlParam: { [key: string]: any } = useParams();
  const changePasswordUrl = environment.serverUrl + "/recover_password/"
  const [token, login, logout] = useContext(AuthContext);
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  let history = useHistory();

  const [logged, setLogged] = useState<boolean>(false)

  function handleLogin(e: any) {
    e.preventDefault();
    login(email, password).then((logSuccessful: boolean) => {      
      if (!logSuccessful) {
        const wrong_cred = document.getElementById('wrong');
        const right_cred = document.getElementById('right');
        if (wrong_cred != null && right_cred != null) {
          wrong_cred.style.display = "block";
          right_cred.style.display = "none";
        }
      } else {
        history.push("/home")
      }
    })
  }

  return (
    <PageWrapper>
        <div className="login-wrapper">
          <img src={STATIC_IMAGES.logo} alt="adiquit-logo" />
          {urlParam?.parameter === "register-success" &&
            <div className="registration-success">
              <p className="registration-success-text">Registrace proběhla úspěšně, nyní se prosím přihlaš.</p>
            </div>}
          <form className="login-form">
            <label className="login-form-label" htmlFor="">E-mailová adresa: </label>
            <input className="login-form-input" type="email" onChange={(e) => setEmail(e.target.value)}/>
            <label className="login-form-label" htmlFor="">Heslo: </label>
            <input className="login-form-input" type="password" onChange={(e) => setPassword(e.target.value)}/>
            <button className="login-form-button" onClick={(e) => handleLogin(e)}>Přihlásit se</button>
            <a className="login-form-button" href={changePasswordUrl} >Zapomenuté heslo</a>
            <Link className="login-form-button" to="/onboarding" >Ještě nemám účet</Link>          
          </form>          
          <div id="wrong" style={{display: "none"}}>Špatné přihlašovácí údaje</div>
          <div id="right" style={{display: "none"}}>Přihlášení úspěšné</div>
        </div>        
    </PageWrapper>
  )
}

export default Login;