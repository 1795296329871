import React from "react";
import { STATIC_IMAGES } from "../../../assets/staticImages";
import RewardItem from "./rewardItem";
import "./rewards.scss"


const Rewards = () => {
    
    return (
        <ul className="rewards-list">
            <div className="rewards-list-title">Kliknutím vyber odměnu</div>
            <RewardItem 
                key="natur-house"
                text="odborníci na zdravé hubnutí" 
                imageLink={STATIC_IMAGES.reward_image_1} 
                linkText="Natur House" 
                link="https://www.naturhouse-cz.cz/"
            />
            <RewardItem 
                key="lekarna-cz"
                text="Lékárna na jedno kliknutí" 
                imageLink={STATIC_IMAGES.reward_image_2} 
                linkText="Lékárna.cz" 
                link="https://lekarna.cz/odvykani-koureni/"
            />
            <RewardItem 
                key="defumoxan"
                text="Lék na odvykání kouření" 
                imageLink={STATIC_IMAGES.reward_image_3} 
                linkText="Defumoxan" 
                link="https://defumoxan.cz"
            />
            <RewardItem 
                key="mutumutu"
                text="Zdravé pojištění" 
                imageLink={STATIC_IMAGES.reward_image_4} 
                linkText="Mutumutu" 
                link="https://mutumutu.cz"
            />
        </ul>
    )
}

export default Rewards;