import React from "react";

interface ImageTextElProps {
  imageUrl: string;
  className?: string;
}

const ImageTextEl = ({
  imageUrl,
  className
}: ImageTextElProps) => {

  const iconFile = require(`./../../assets/images/phoneApp/${imageUrl}`)

  return (
  <React.Fragment>
    <img src={iconFile} alt={imageUrl} className={className} />
  </React.Fragment>);
};

export default ImageTextEl