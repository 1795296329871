import { API_ROUTES } from "../apiRoutes";

function getPage(token: string): Promise<{} | undefined> {
  const requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Token " + token, "Content-Type": "application/json" },
  };
  return  fetch(API_ROUTES.getPageUrl, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return new Promise<{}>(resolve => resolve(data));
    }).catch(e => {
      return new Promise<undefined>(resolve => resolve(undefined));
    })
}

export default getPage;