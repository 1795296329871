import { API_ROUTES } from "../apiRoutes";
import { IUserSummary } from "../interfaces/userSummary";

function getUserSummary(token: string): Promise<IUserSummary | undefined> {
  return fetch(API_ROUTES.userSummaryUrl, {
    method: "GET",
    headers: {
      "Authorization": "Token " + token,
      "Accept-Language": "cs",
    },
  }).then(response =>
    response.json()
  ).then(data => {
    return new Promise<IUserSummary>(resolve => {resolve(data);})
  }).catch(error => {
    return new Promise<undefined>(resolve => resolve(undefined))
  })
}

export default getUserSummary;