import { API_ROUTES } from "../apiRoutes";

function createCheckpoint(token: string): Promise<{} | undefined> {
  const requestOptions = {
    method: 'POST',
    headers: {
      "Authorization": "Token " + token,
      "Content-Type": "application/json",
    },
  };
  return  fetch(API_ROUTES.createCheckpointUrl, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return new Promise<{}>(resolve => resolve(data));
    }).catch(e => {
      return new Promise<undefined>(resolve => resolve(undefined));
    })
}

export default createCheckpoint;