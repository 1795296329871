import React, { useState } from "react"
import { IApiVariableValue } from "../../interfaces/apiVariableValue"
import DOMPurify from 'dompurify';
import TextEl from "./apTextEl";

export enum ApSimpleInputType {
  TEXT = "text",
  TEXTAREA = "textarea",
  NUMERIC = "number",
  PASSWORD = "password",
  EMAIL = "email",
}

interface ApSimpleInputElProps {
  labelText: string;
  varName: string;
  apiVariables: { [key: string]: IApiVariableValue };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>;
  inputType: ApSimpleInputType;
  required?: boolean;
}

const ApSimpleInputEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  inputType,
  required,
}: ApSimpleInputElProps) => {

  const [validationMessage, setValidatinMessage] = useState<string>();

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newApiVariables: any = Object.assign({}, apiVariables);
    // Better to sanitize HTML when the session parse HTML, so it prevents XSS attack
    newApiVariables[varName].value = DOMPurify.sanitize(e.target.value)
    handleValidity(newApiVariables, e);
    setApiVariables(newApiVariables)
  }

  function handleValidity(newApiVariables: { [key: string]: IApiVariableValue }, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const curValue = newApiVariables[varName];
    let isValid: boolean = true;
    if (required && (curValue.value == null || curValue.value === "")) {
      setValidatinMessage("Toto pole nesmí zůstat prázdné");
      isValid = false;
    }
    if(inputType === ApSimpleInputType.NUMERIC && !e.target.checkValidity()) {
      setValidatinMessage("Použij prosím jenom číslice");
      isValid = false;
    }
    if(isValid) {
      setValidatinMessage("");
      curValue.valid = true;
    } else {
      curValue.valid = false;
    }
  }
  
  return (
    <React.Fragment>
      <TextEl text={labelText} apiVariables={apiVariables}/>
      <div className="app-input-container">
        {inputType ===  ApSimpleInputType.TEXTAREA &&
          <textarea
            className="app-input"
            value={apiVariables[varName].value || ""}
            onChange={e => handleOnChange(e)}/>}
        {inputType ===  ApSimpleInputType.NUMERIC &&
          <input
            className="app-input"
            pattern="[0-9]*"
            value={apiVariables[varName].value || ""}
            onChange={e => handleOnChange(e)}/>}
        {(inputType ===  ApSimpleInputType.TEXT || inputType ===  ApSimpleInputType.EMAIL ||
          inputType ===  ApSimpleInputType.PASSWORD) &&
          <input
            type={inputType}
            className="app-input"
            value={apiVariables[varName].value || ""}
            onChange={e => handleOnChange(e)}/>}      
        {validationMessage !== "" &&
          <div className="element-validation-message">{validationMessage}</div>}
      </div>
    </React.Fragment>
  );
};

export default ApSimpleInputEl