import React, { useEffect, useRef, useState } from "react";
import evaluateExpression from "../../../api/requests/evaluateExpression";
import { ObjectUtils } from "../../../common/utils/objectUtils";
import { BuildingBlocksFactory } from "./buildingBlocksFactory";
import { vbTranslation } from "../data/vbTranslation";
import { IExpressionCacheValue } from "../interfaces/expressionCacheValue";
import { ICacheableDynamicElement } from "../interfaces/cacheableDynamicElement";
import { IApiVariableValue } from "../interfaces/apiVariableValue";
import { SessionAdapter } from "./sessionAdapter";
import { StringUtils } from "../../../common/utils/stringUtils";

interface ISessionProps {
  apiData: any;
  token: string;
  sendAnswer: (apiVariables: { [key: string]: IApiVariableValue }) => void;
  languageVariables: { [key: string]: any };
  readonly: boolean;
}

const Session = ({apiData, token, sendAnswer, languageVariables, readonly}: ISessionProps) => {
  
  const [elementList, setElementsList] = useState<ICacheableDynamicElement[]>([])
  const [apiVariables, setApiVariables] = useState<{[key: string]: IApiVariableValue}>({})
  const [parsedApiData, setParsedApiData] = useState<any>();
  const [toggleVariables, setToggleVariables] = useState<{[key: string]: boolean}>({}); // extra variables for toggling content so they dont mix up with apiVariables
  
  const apiTranslation: {[key: string]: {[nextKey: string]: string}} = vbTranslation;

  const [expressionCache, setExpressionCache] = useState<{[key: string]: IExpressionCacheValue}>({}); 
  const [lastExpressionResult, setLastExpressionResult] = useState<{expression: string, result: boolean} | undefined>();
  
  const [isLoading, setIsLoading] = useState<boolean>(true); // show loading screen

  useEffect(() => {
    if(apiData) {
      const sessionAdapter = new SessionAdapter(apiData);
      setParsedApiData(sessionAdapter.getParsedSessionData())
    }
  },[])

  useEffect(() => {
    if(parsedApiData) {
      let apiVariables = getApiVariables(apiData.data);
      parseApiTranslationLanguageVariables(apiVariables);
      setApiVariables(apiVariables);    
      sendConditionalSetRequests(apiData.data);
    }
  }, [parsedApiData])

  useEffect(() => {
    // Put expression result for conditional block from server into expression cache
    if(lastExpressionResult) {
      const newExpressionCache = {...expressionCache};
      newExpressionCache[lastExpressionResult.expression] = {result: lastExpressionResult.result, sent: true};
      setExpressionCache(newExpressionCache);
    }
  }, [lastExpressionResult])

  useEffect(() => {
    if (parsedApiData && Object.keys(apiVariables).length > 0) {
      setElementsList(getDynamicAppointment())
      if(isLoading && Object.keys(expressionCache).every(x => expressionCache[x].result != null)) {
        setIsLoading(false);
      }
    };
    // rerender if any input change or expression cache change (so answer was obtained from server)
  }, [apiVariables, expressionCache])

  useEffect(() => {
    if (parsedApiData && elementList.length !== 0) {
      let result: ICacheableDynamicElement[] = []
      const buildingBlocksFactory = new BuildingBlocksFactory(
        elementList, apiVariables, toggleVariables, setApiVariables, setToggleVariables,
        expressionCache, parsedApiData.dead_end, sendAnswerButton, readonly)
      parsedApiData.data.forEach(function (element: any, index: number) {
        result.push(buildingBlocksFactory.buildToggle(element, index)) ;
      });
      setElementsList(result);
    }
  }, [toggleVariables])

  function sendAnswerButton() {
    if(readonly) {
      return;
    }
    if (Object.keys(apiVariables)
      .filter(key => apiVariables[key].dynamicInputBinded && !apiVariables[key].valid)
      .length > 0 ) {
        console.log("answer NOT sent, some variables not valid")
      return;
    }
    console.log("ANSWER SENT")
    // Set loading so user cannot click "next" twice and skip two pages
    setIsLoading(true);
    sendAnswer(apiVariables);
  }

  function parseApiTranslationLanguageVariables(apiVariables: {[key: string]: IApiVariableValue}) {
    for (const varValue of Object.keys(apiTranslation["HRSit"])) {
      apiTranslation["HRSit"][varValue] = Object.keys(apiVariables).reduce((acc, varName) => {
        return acc.split(`{{${varName}}}`).join(apiVariables[varName].value.toString());
      }, apiTranslation["HRSit"][varValue]);
    }
  }

  function sendConditionalSetRequests(data: any): void {
    const newExpressionCache = {...expressionCache};
    // Go through conditional requests and send them to server
    data.forEach(function (element: any, index: number) {
      if(element.content_type === "conditionalset" && element.content !== "" && element.content?.length !== 0 && 
        expressionCache[element.content[0].expression] == null)
      {
        if(Object.keys(newExpressionCache).length === 0) {
          setIsLoading(true);
        }
        for (let condition of element.content) {
          newExpressionCache[condition.expression] = {result: undefined, sent: true};
        }  
        for(let condition of element.content) {
          evaluateExpression(condition.expression, token).then(x => {
            setLastExpressionResult({expression: condition.expression, result: x});
          })
        }
      }
    });
    setExpressionCache(newExpressionCache);
  }

  function getDynamicAppointment() {
    let result: ICacheableDynamicElement[] = []
    const buildingBlocksFactory = new BuildingBlocksFactory(
      elementList, apiVariables, toggleVariables, setApiVariables, setToggleVariables,
      expressionCache, parsedApiData.dead_end, sendAnswerButton, readonly)
      parsedApiData.data.forEach(function (element: any, index: number) {
      result.push(buildingBlocksFactory.buildBlock(element, index));
    });
    return result;
  }
  
  function markVariablesNeedingTranslation(apiVars: {[key: string]: IApiVariableValue}) {
    Object.keys(apiVars).forEach(varName => {
      if(apiTranslation[varName]) {
        (apiVars[varName]).isTranslationNeeded = true;
      }
    })
  }

  function getApiVariables(data: any): {[key: string]: IApiVariableValue} {
    // Unite variables from all elements
    let one_way_binded_variables: {[key: string]: IApiVariableValue} = {};
    let two_way_binded_variables: {[key: string]: IApiVariableValue} = {};

    // Go through all elements and their variables: {}
    data.filter((element: any) => element.variables !== undefined).forEach((element: any) => {
        one_way_binded_variables = {
        ...one_way_binded_variables,
        ...(ObjectUtils.objectMap(element.variables,
          x => ({ value: x?.toString() ?? "", dynamicInputBinded: false, translateNeeded: false, valid: true })
        ))
      }
    })

    // Go through all form elements in form and their variable_name property
    data.filter((element: any) => element.content_type === "form").forEach((element: any) => {
      element.content.filter((formElement: any) => formElement.variable_name !== undefined).forEach(
        (formElement: any) => {
          two_way_binded_variables[formElement.variable_name] = {
            value: formElement.value?.toString() ?? "",
            dynamicInputBinded: true,
            isTranslationNeeded: false,
            valid: !formElement.required,
          }
      });
    })

    // Go through toggleSets and toggleMulti and their variable_name
    data.filter((element: any) => element.content_type === "toggleset" || element.content_type === "togglesetmulti").forEach((element: any) => {
      if(element.content !== "" && element.content !== {} && element.content.variable_name !== "") {
        two_way_binded_variables[element.content.variable_name] = {
          value: one_way_binded_variables[element.content.variable_name]?.value ?? (element.content_type === "togglesetmulti" ? [] : ""),
          dynamicInputBinded: true,
          isTranslationNeeded: false,
          valid: false,
        }
      }
    })
    
    // Go through language variables from summary
    for (const langVar of Object.keys(languageVariables)) {
      if(two_way_binded_variables[langVar] == null) {
        one_way_binded_variables[langVar] = {
          value: languageVariables[langVar],
          dynamicInputBinded: false,
          isTranslationNeeded: false,
          valid: true,
        }
      }
    }

    const currentVariables = {...one_way_binded_variables, ...two_way_binded_variables}
    markVariablesNeedingTranslation(currentVariables);

    return currentVariables;
  }

  return (
    <>
      {isLoading ?
          <div className="appointment-loading">
            <div>Sestavuji stránku, dej mi malou chvíli...</div>
            <div className="loading-spinner"></div>
          </div>
        :
          <>
            {elementList.map(x => x.element)}
            <div className="margin-bottom-element">&nbsp;</div>
          </>
      }
    </>
  );
}

export default Session