export class ObjectUtils {

  // Map function for object, it maps values of each key
  public static objectMap(object: {[key: string]: any}, fn: (value: any) => any): {[key: string]: any} {
    return Object.keys(object).reduce(function(result: any, key: string) {
      result[key] = fn(object[key])
      return result
    }, {})
  }

  public static pickObjectSubset(obj: {[key: string]: any}, keySubset: string[]): {[key: string]: any} {
    const newObject: {[key: string]: any} = {};
    keySubset.forEach(key => { newObject[key] = obj[key]; });
    return newObject;
  }
  
  // Returns true if object doesn't have any key
  public static isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
}