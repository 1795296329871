import { API_ROUTES } from "../apiRoutes";

function sendUserVariables(body: {[key: string]: any}, token: string): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: { "Authorization": "Token " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body)
  };
  return  fetch(API_ROUTES.setUserVariablesUrl, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      if(data.success) {
        return new Promise<boolean>(resolve => resolve(true));
      } else {
        return new Promise<boolean>(resolve => resolve(false))
      }
    }).catch(e => {
      return new Promise<boolean>(resolve => resolve(false));
    })
}

export default sendUserVariables;