export const STATIC_IMAGES = {
  successStatisticsBackground: require("./images/statistics.png"),
  successBadgesBackground: require("./images/fire.png"),
  questionMark: require("./images/phoneApp/adiquit_icon_question_mark.png"),
  ligthBulb: require("./images/phoneApp/adiquit_icon_lightbulb_questionmark.png"),
  money: require("./images/phoneApp/adiquit_icon_money.png"),
  present: require("./images/phoneApp/adiquit_icon_present.png"),
  cancelImg: require("./images/cancel.png"),
  doctor: require("./images/doctor.png"),
  arrow: require("./images/arrow.png"),
  community: require("./images/phoneApp/adiquit_icon_community.png"),
  cigarette: require("./images/adiquit_icon_cigarette_burning.png"),
  conversation: require("./images/conversation.png"),
  blog: require("./images/adiquit_icon_quote.png"),
  pen: require("./images/adiquit_icon_pen.png"),
  back: require("./images/adiquit_icon_back.png"),
  info: require("./images/adiquit_icon_info.png"),
  adiquit: require("./images/adiquit_image_onboarding_1.png"),
  badge: require("./images/badge.png"),
  feedback: require("./images/feedback.png"),
  bank: require("./images/bank.png"),
  lockedContent: require("./images/phoneApp/adiquit_icon_lock.png"),

  mockBadge1: require("./images/certificate.png"),
  mockBadge2: require("./images/repair.png"),
  mockBadge3: require("./images/light.png"),
  mockBadge4: require("./images/clever_man.png"),

  cigarettes: require("./images/onboarding_cigarette_box.png"),
  goal: require("./images/onboarding_mountain_goal.png"),
  green_heart: require("./images/green_heart.png"),
  cigarette_stub: require("./images/cigarette_stub.png"),
  man: require("./images/man.png"),
  woman: require("./images/woman.png"),
  change_password: require("./images/change_password.png"),
  logoutIcon: require("./images/logout.png"),
  delete_account: require("./images/delete_account.png"),
  lockedContentIconFilePath: require(`./images/phoneApp/adiquit_icon_locked_content.png`),

  logo: require("./images/logo.png"),
  home: require("./images/home.png"),
  graph: require("./images/graph.png"),
  medal: require("./images/medal.png"),
  checklist: require("./images/checklist.png"),
  account: require("./images/account.png"),
  cigaretteStub: require("./images/cigarette_stub.png"),
  greenHearth: require("./images/phoneApp/adiquit_icon_health.png"),

  okCheckmark: require("./images/adiquit_icon_ok_checkmark.png"),
  noCross: require("./images/adiquit_icon_no_cross.png"),

  money2: require("./images/phoneApp/adiquit_icon_money_2.png"),
  ashtray: require("./images/phoneApp/adiquit_icon_ashtray.png"),
  

  badge0b: require("./images/phoneApp/adiquit_badge_0b.png"),
  badge1b: require("./images/phoneApp/adiquit_badge_1b.png"),
  badge2b: require("./images/phoneApp/adiquit_badge_2b.png"),
  badge3b: require("./images/phoneApp/adiquit_badge_3b.png"),
  badge4b: require("./images/phoneApp/adiquit_badge_4b.png"),
  badge5b: require("./images/phoneApp/adiquit_badge_5b.png"),
  badge6b: require("./images/phoneApp/adiquit_badge_6b.png"),
  badge7b: require("./images/phoneApp/adiquit_badge_7b.png"),
  badge8b: require("./images/phoneApp/adiquit_badge_8b.png"),
  badge9b: require("./images/phoneApp/adiquit_badge_9b.png"),
  badge10b: require("./images/phoneApp/adiquit_badge_10b.png"),
  badge11b: require("./images/phoneApp/adiquit_badge_11b.png"),
  badge12b: require("./images/phoneApp/adiquit_badge_12b.png"),
  badge13b: require("./images/phoneApp/adiquit_badge_13b.png"),
  badge14b: require("./images/phoneApp/adiquit_badge_14b.png"),
  badge15b: require("./images/phoneApp/adiquit_badge_15b.png"),
  badgeMap: require("./images/phoneApp/adiquit_badge_map.png"),

  adiquit_image_questionnaire_1: require("./images/phoneApp/adiquit_image_questionnaire_1.png"),
  adiquit_image_questionnaire_2: require("./images/phoneApp/adiquit_image_questionnaire_2.png"),
  adiquit_image_questionnaire_3: require("./images/phoneApp/adiquit_image_questionnaire_3.png"),
  adiquit_image_questionnaire_4: require("./images/phoneApp/adiquit_image_questionnaire_4.png"),
  adiquit_image_questionnaire_5: require("./images/phoneApp/adiquit_image_questionnaire_5.png"),
  adiquit_image_questionnaire_6: require("./images/phoneApp/adiquit_image_questionnaire_6.png"),
  adiquit_image_questionnaire_7: require("./images/phoneApp/adiquit_image_questionnaire_7.png"),
  adiquit_image_questionnaire_8: require("./images/phoneApp/adiquit_image_questionnaire_8.png"),
  adiquit_image_questionnaire_9: require("./images/phoneApp/adiquit_image_questionnaire_9.png"),
  adiquit_image_questionnaire_10: require("./images/phoneApp/adiquit_image_questionnaire_10.png"),
  adiquit_image_questionnaire_11: require("./images/phoneApp/adiquit_image_questionnaire_11.png"),
  adiquit_image_questionnaire_12: require("./images/phoneApp/adiquit_image_questionnaire_12.png"),
  adiquit_image_questionnaire_13: require("./images/phoneApp/adiquit_image_questionnaire_13.png"),
  adiquit_image_questionnaire_14: require("./images/phoneApp/adiquit_image_questionnaire_14.png"),
  adiquit_image_questionnaire_15: require("./images/phoneApp/adiquit_image_questionnaire_15.png"),
  adiquit_image_questionnaire_16: require("./images/phoneApp/adiquit_image_questionnaire_16.png"),

  //These static images are used for background images on different pages
  help_background_image: require("./images/phoneApp/aq_support.png"),
  plan_background_image: require("./images/phoneApp/aq_strategy.png"),
  success_rewards_background_image: require("./images/aq_rewards_background_image.png"),
  payment_background_image: require("./images/phoneApp/adiquit_hero_image_livingroom_tv.png"),
  appointment_home_image_1: require("./images/phoneApp/adiquit_hero_image_surgery_1.png"),
  appointment_home_image_2: require("./images/phoneApp/adiquit_hero_image_surgery_2.png"),
  appointment_home_image_3: require("./images/phoneApp/adiquit_hero_image_lecture.png"),
  appointment_home_image_4: require("./images/phoneApp/adiquit_hero_image_home_office_1.png"),
  appointment_home_image_5: require("./images/phoneApp/adiquit_hero_image_living_room.png"),


  reward_image_1: require("./images/rewards_naturhouse_logo.png"),
  reward_image_2: require("./images/rewards_lekarnacz_logo.png"),
  reward_image_3: require("./images/rewards_defumoxan_logo.png"),
  reward_image_4: require("./images/rewards_mutumutu_logo.png"),
}