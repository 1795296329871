import React, { useState } from "react"
import { STATIC_IMAGES } from "../../../assets/staticImages"
import SimpleInputEl, { SimpleInputType } from "../../../common/components/simpleInputEl"

interface ISpecialCode {
  specialCodeRef: React.RefObject<HTMLDivElement>;
  toggleWindow: (windowRefEl: HTMLDivElement | null) => void;
  sendCode: (code: string) => void;
}

const SpecialCode = ({
  specialCodeRef,
  toggleWindow,
  sendCode,
}: ISpecialCode) => {

  const [code, setCode] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<string>("");
  
  function handleSendCode() {
    if(code === "") {
      setValidationMessage("Pole nesmí být prázdné")
    } else {
      setValidationMessage("")
    }
    sendCode(code);
  }

  return (
    <>
      <button onClick={(e) => {toggleWindow(specialCodeRef.current)}} className="cancel-button">
        <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
      </button>
      <h2>Pro získání slevy zadejte slevový / Firemní kód</h2>
      <input
        type="text"
        required
        onChange={e => setCode(e.target.value)}
      />
      <span style={{color: "red"}}>{validationMessage}</span>
      <button type="submit" className="special-code-ok" onClick={e => handleSendCode()}>OK</button>
    </>
  )

}

export default SpecialCode