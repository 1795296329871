import { IBadge } from "../interfaces/badge";
import { IBadgeDetailInputBlock, IBadgeDetailBlockType, IBadgeDetailImageBlock, IBadgeDetailTextBlock, IBadgeDetailInputFallBackBlock } from "../interfaces/badgeDetailBlocks";

export const badgeData: IBadge[] = [
  {
    title: "Instalace aplikace",
    subtitle: "Už máš průvodce, můžeme vyrazit na cestu!",
    userVariableName: "Name5p",
    showPredicate: (val: any) => true,
    imageUrl: "adiquit_badge_0b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Máš aplikaci Adiquit a také svou cestovní mapu. Je to běh na dlouhou dobu a proto je cesta rozdělena do tří etap:</p>"
      }, 
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p><h2>1) Příprava</h2>V prvních 10 dnech se spolu připravíme na odvykání. Každý den otevřeme jedno důležité téma. Je ale potřeba, aby ses mi každý den věnova{{VerbPast}}. Stačí 3-10 minut.</p>"
      },
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p><h2>2) Odvykání</h2>Poslední cigareta a jdeme do toho. V dalších 1-3 týdnech s tebou budu každý den a pak se ohlásím obden. Ty budeš dávat pozor na svou cestu k nekouření, já budu dávat pozor na tebe.</p>"
      },
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p><h2>3) Stále nekouříš?</h2>Po nějaké době se připomenu. A bude mě zajímat, jestli stále nekouříš a jak jsi by{{VerbPast}} se mnou spokojen{{AdjArch}}.</p>"
      } as IBadgeDetailTextBlock
    ],
    order: 1,
  },
  

  {
    title: "Už se známe",
    subtitle: "Seznámi{{VerbPast}} ses s Adamem. Nyní na odvykání budete dva.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 1,
    imageUrl: "adiquit_badge_1b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Jsem Adam, tvůj virtuální terapeut a pomůžu ti přestat s kouřením. Budu ti radit, pomáhat a držet palce.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Abych ti mohl pomoci, probrali jsme spolu několik důležitých informací o tvém kouření. Třeba:</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_NUMBER,
        text: "<h3>Kolik cigaret tak denně vykouříš?</h3>",
        varName: "NoCig1"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Každé ráno se ti budu hlásit. Na sezeních ti budu připomínat důvody, proč už nechceš kouřit. Pomůžu ti zvládnout náročné situace.<p></p>Jen si na mě každý den udělej několik minut času. Mnoha lidem pomohlo, když si pro sezení vyhradili pevný termín ve svém denním harmonogramu.</p>"
      } as IBadgeDetailTextBlock
    ],
    order: 2,   
  },
  {
    title: "Teď víš, proč přestat",
    subtitle: "Teď víš, proč přestat",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 2,
    imageUrl: "adiquit_badge_2b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Mluvili o tom, proč se chystáš přestat kouřit a jaký je tvůj hlavní důvod.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Chci přestat kouřit, protože...</h3>",
        varName: "ReaQui"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Mnoho lidí chtělo přestat kouřit, mnoho jich to zvládlo. Uspěli ti, kteří si řekli: <em>„Nekouřit je důležité. Dokážu to, i když mě to bude stát nějaké úsilí.“</em> A co ty?</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Přestat kouřit je pro mě důležité, protože...</h3>",
        varName: "ChangeTalkNeed"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Vezmi to tedy jako výzvu a pojď do toho. Své důvody pro odvykání už máš.</p>"
      } as IBadgeDetailTextBlock,
    ],
    order: 3,  
  },
  {
    title: "Chuť kouřit tě nepřekvapí",
    subtitle: "Už víš, jak ji překonat.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 3,
    imageUrl: "adiquit_badge_3b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Bavili jsme se, jak překonat chuť na cigaretu - ta chuť nezmizí ze dne na den. Proto je dobré mít plán, co v takových chvílích dělat. Ještě lepší je mít záložní plány. Můžeš je pak zkoušet a střídat podle okolností. Sestavi{{VerbPast}} sis pět vlastních osobních strategií, kterými budeš zkoušet vyzrát na chutě na cigaretu.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Když dostanu chuť kouřit, nezapálím si, ale udělám toto:</h3>",
        varName: "SmoUrPlan"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Určitě objevíš i další způsoby, jak se zbavit hříšných myšlenek. Používej ty, které fungují. A ještě jeden tip navíc:</p><p>Vyzkoušej svou strategii hned. Až dostaneš chuť kouřit, vydrž nekouřit 15 minut a zkus některý z vybraných triků. Vyzkoušej jich víc a všímej si, co funguje. Je to ale jenom nepovinná zkouška. Klidně si pak zapal. Netrap se, že stratregie nezabírá. Její chvíle přijde.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p>Také jsme začali sledovat spouštěče kouření. Od teď, pokaždé, když dostaneš chuť na cigaretu, odpověz si na tyto otázky:</p>
          <p><strong>1. Kdy kouřím?</strong> V jakých hodinách a v jakých situacích?</p>
          <p><strong>2. Kde jsem</strong>, když sahám po cigaretě?</p>
          <p><strong>3. Proč kouřím?</strong> Proč si chci zapálit právě teď?</p>
          <p>Když pochopíš, kdy, kde a proč kouříš, bude snadnější s tím přestat.</p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 4,    
  },
  {
    title: "Máš svůj plán",
    subtitle: "Sepsa{{VerbPast}} sis postup, jak budeš odvykat.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 4,
    imageUrl: "adiquit_badge_4b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Společně jsme sestavili plán, co budeš dělat první den bez cigarety. Možná bys ho chtě{{VerbPast}} ještě vylepšit - udělej to. Tady ho máš černý na bílém a tady si plán můžeš kdykoli připomenout.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT_FALLBACK,
        text: "<h3>Můj první den bez cigarety bude vypadat takto:</h3>",
        varName: ["CessationPlan", "PlanForCessationDay"]
      } as IBadgeDetailInputFallBackBlock,
    ],
    order: 5,
  },
  {
    title: "Nesejdeš z cesty",
    subtitle: "I když si zapálíš, víš, jak se vrátit k odvykání.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 5,
    imageUrl: "adiquit_badge_5b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Na cestě bez cigarety tě mohou potkat různé potíže. Můžeš  ztratit směr nebo sejít z cesty. Ale trochu zabloudit ještě neznamená ztratit se.</p><p>Když se ti to stane na kole nebo na výletě, uděláš co? Rychle se vzpamatuješ a vrátíš se na původní trasu. Z červené značky zpátky na zelenou. Zabloudit a zase se najít je jako zakopnout a zase vstát. Žádná ostuda.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p><strong>Uvědom si – Vyber si – Jednej – Pokračuj</strong></p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p>A ještě jednu věc jsme si říkali. Mluvili jsme o zaváhání versus selhání.
        Vzpomínáš? Jedna cigareta může odstartovat tvoje kouření nebo zůstane ojedinělým zaváháním - nehodou na cestě.
        <p>
          <strong>Zaváhání</strong> je, když si zapálíš a
          <ul>
            <li>uvědomíš si, že kouření nejde dohromady s odvykáním,</li>
            <li>vybereš si rozhodnutí cigaretu típnout,</li>
            <li>jednáš - zbavíš se cigaret a získáš zpět kontrolu nad situací.</li>
          </ul>
        </p>
        <p>
          <strong>Selhání</strong> je, když si zapálíš a
          <ul>
            <li>uvědomíš si, že kouření a odvykání nejdou dohromady,</li>
            <li>vybereš si, že znovu budeš kouřit, protože to chceš anebo nemůžeš jinak.</li>       
          </ul>
        </p>
        <p>Ale pamatuj, i když si jednou (nebo víckrát) zapálíš, vždycky se můžeš vrátit k odvykání.</p>
        <p></p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 6,
  },
  {
    title: "Znáš své spouštěče",
    subtitle: "Víš, na co si dát pozor.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 6,
    imageUrl: "adiquit_badge_6b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Situace, myšlenky nebo rituály mohou vést k chuti si zakouřit. Zkus dále pozorovat, kdy a kde se chuť na cigaretu přihlásí. Tyto situace si zapamatuj (nebo zapiš). Je dobré znát nejen to, co nás posiluje, ale také co nás oslabuje.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Chuť kouřit dostávám... kdy, kde, proč:</h3>",
        varName: "SmokingCuesList"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Čím lépe budeš znát své spouštěče, tím více oslabíš jejich moc.</p>"
      } as IBadgeDetailTextBlock,
    ],
    order: 7,
  },
  {
    title: "Od teď jinak",
    subtitle: "Už víš, co dělat v rizikových situacích.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 7,
    imageUrl: "adiquit_badge_7b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Spouštěče mohou chuť kouřit tu a tam vyvolávat. A to i dlouho po tom, co jsi přesta{{VerbPast}}. Co funguje?</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p><strong>Dočasně se vyhýbat rizikovým situacím</strong> Pár dní to určitě vydržíš.</p>
        <p><strong>Odejdi nebo změň činnost</strong> Když ucítíš, že tě chuť na cigaretu zmáhá, naruš situace, ve které se nacházíš.</p>
        <p><strong>Použij obranou strategii</strong> Zejména v situacích, které tě překvapí. Nezapoměň na tvůj mobil a Adiquit. Klepni na tlačítko SOS, nabídnu ti rychlou pomoc.</p>`
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.IMAGE_BLOCK,
        imgUrl: "ic_achievements_badge_ee07_detail_1.png"
      } as IBadgeDetailImageBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<strong>Upřesni svůj plán.</strong> Vždy měj připravená řešení, co budeš dělat v rizikových situacích.</h3>",
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Co udělám, když se dostanu do rizikové situace:</h3>",
        varName: "HRSitPlan"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Při odvykání se daří připraveným. <strong>A to ty jsi.</strong></p>"
      } as IBadgeDetailTextBlock,
    ],
    order: 8,
  },
  {
    title: "Tvé okolí tě podpoří",
    subtitle: "Nyní víš, na koho se obrátit, když ti bude nejhůř.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 8,
    imageUrl: "adiquit_badge_8b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Je tu někdo, kdo tě může ve tvé snaze přestat kouřit povzbuzovat? Pozorný partner, příbuzný, kamarád, kolega? Zatím si to rozmysli, zeptám se tě později...</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Můj podporovatel je...</h3>",
        varName: "SupPer"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>A dá se jít ještě dál. Říct okolí, že přestáváš kouřit. Doma, přátelům, v práci... prostě komukoliv.</p>"
      } as IBadgeDetailTextBlock,
    ],
    order: 9,
  },
  {
    title: "Víš, na čem ti záleží",
    subtitle: "Znáš své cíle a jdeš si za nimi.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 9,
    imageUrl: "adiquit_badge_9b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Tvé osobní hodnoty ovlivňují celý tvůj život. A také tvé odvykání. Vždy, když si toto uvědomíš, snadněji odoláš pochybnostem, které odvykání doprovází.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Podívej se, jak kouření ohrožuje tvé životní hodnoty:</h3>",
        varName: "ValuesAndSmo"
      } as IBadgeDetailInputBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p><strong>Proč je dobré, že to víme?</strong> Když se nechováme podle svého přesvědčení, žijeme ve stálém napětí.</p><p>Měj to na paměti, až ti bude cigareta chybět a ty se budeš ptát, proč to martýrium podstupuješ. <strong>Nepřestáváš jenom kouřit. Dáváš si svůj život do pořádku.</strong></p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 10,
  },
  {
    title: "Už jsi připraven{{AdjArch}}",
    subtitle: "Uděla{{VerbPast}} jsi vše pro to, abys moh{{VerbPast}} začít odvykat.",
    userVariableName: "EEcp",
    showPredicate: (val: any) => val >= 10,
    imageUrl: "adiquit_badge_10b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>V minulých dnech jsme probrali spoustu věcí, na které bude třeba myslet. Abys s kouřením nejen přesta{{VerbPast}}, ale abys s ním už ani nikdy nezača{{VerbPast}}.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Zároveň by to měl být tvůj poslední den s cigaretou. Zajímá tě, jak sis ho naplánova{{VerbPast}}? Koukni. A kdyžtak si ho naplánuj znovu.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Můj poslední den před začátkem odvykání...</h3>",
        varName: "PlanForDayBeforeCessationDay"
      } as IBadgeDetailInputBlock,
    ],
    order: 11,
  },


  {
    title: "Teď už znáš cestu",
    subtitle: "Přípravu na odvykání máš úspěšně za sebou. Dobrá práce!",
    userVariableName: "PHASE",
    showPredicate: (val: any) => /^(EQ|FU|SUR|FIN)$/i.test(val),
    imageUrl: "adiquit_badge_map.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Jsi připraven{{AdjArch}} <strong>a věř mi, že dobře!</strong> Pokud tě bude chuť na cigaretu pronásledovat, pamatuj, že jsem tady. Podívej se, co všechno jsme spolu probrali. Máš za sebou velký kus práce.</p>"
      } as IBadgeDetailTextBlock,
    ],
    order: 12,   
  },
  {
    title: "Zvlád{{VerbPast}} jsi den D",
    subtitle: "Udělat první krok je nejtežší. Tobě se to ale povedlo!",
    userVariableName: "PHASE",
    showPredicate: (val: any) => /^(FU|SUR|FIN)$/i.test(val),
    imageUrl: "adiquit_badge_11b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p><strong>První den bez cigarety!</strong> Woow! Velký den, kterým se začíná psát nový příběh. Jdi sebevědomě za svým snem!</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.INPUT_TEXT,
        text: "<h3>Chci přestat kouřit, protože...</h3>",
        varName: "ReaQui"
      } as IBadgeDetailInputBlock,
    ],
    order: 13,
  },
  {
    title: "Udržuješ správný kurz",
    subtitle: "Už 7 dnů ode dne D. Jen tak dál!",
    userVariableName: "FU",
    showPredicate: (val: any) => val >= 7,
    imageUrl: "adiquit_badge_12b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: "<p>Vyzkouše{{VerbPast}} sis některé situace bez cigaret.<br/>Vyzkouše{{VerbPast}} sis také, jak funguje tvůj plán.<br/> Vyzkouše{{VerbPast}} sis, jaké je to chvíli nekouřit.</p>"
      } as IBadgeDetailTextBlock,
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p>Teď si můžeš sám{{AdjArch}} pro sebe zhodnotit, jak to šlo. Zkus si odpovědět na tyto otázky:\n
        <ul>
            <li>Co mi nekouření přineslo?</li>
            <li>Co se mi podařilo?</li>
            <li>Proč chci jít dál za svým cílem nekouřit?</li>
        </ul></p>
        <p><strong>Udržuj dál správný kurz na cestě bez cigaret!</strong></p>
        <p></p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 14,   
  },
  {
    title: "Jen tak se neztratíš",
    subtitle: "Jdeš dál za svým cílem. Už 2 týdny ode dne D. Skvělá práce!",
    userVariableName: "FU",
    showPredicate: (val: any) => val >= 14,
    imageUrl: "adiquit_badge_13b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p>Máš za sebou další kus cesty. Každý den bez kouření tě posouvá dopředu. S každou odmítnutou cigaretou se stáváš silnější a silnější.
        </p><p>
        A když příjdou myšlenky si zapálit? To nevadí, děje se to většině kuřáků. Vzpomeň si, proč jsi to celé postupova{{VerbPast}}. Vzpomeň si, co ti fungovalo v prvních dnech nekouření. Vzpomeň si, kdo stojí za celým tím úspěchem.
        </p><p>
        Za tím vším <strong>jsi ty, tvé úsilí a tvé odhodlání</strong>. Jdi dál!</p><p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 15,
  },
  {
    title: "To nejtěžší máš za sebou!",
    subtitle: "Překona{{VerbPast}} jsi hlavní nástrahy odvykání. Drž se dál své cesty.",
    userVariableName: "FU",
    showPredicate: (val: any) => val >= 21,
    imageUrl: "adiquit_badge_14b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p>Jak už víš, první týdny bez cigaret nejsou jednoduché. Vše je najednou jinak. Zaběhlé situace, kdy jsi by{{VerbPast}} zvykl{{AdjEnd}} kouřit, je potřeba změnit.
        </p><p>
        Ty jsi to ale dokáza{{VerbPast}}. Překona{{VerbPast}} jsi abstinenční příznaky. Překona{{VerbPast}} jsi myšlenky na cigarety. A při chuti kouřit sis nezapáli{{VerbPast}}.
        </p><p>
        K odvykání patří i zaváhání. I to se stává. Důležité je se vždy znovu vrátit na cestu nekouření.</p><p></p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 16, 
  },
  {
    title: "Dosta{{VerbPast}} ses do cíle",
    subtitle: "Zvlád{{VerbPast}} jsi se mnou projít celou cestu za nekouřením.",
    userVariableName: "PHASE",
    showPredicate: (val: any) => /^(SUR|FIN)$/i.test(val),
    imageUrl: "adiquit_badge_15b.png",
    detailBlocks: [
      {
        type: IBadgeDetailBlockType.TEXT_BLOCK,
        text: `<p>Prošli jsme spolu dlouhou cestu. Každý den jsme se věnovali jednomu tématu a společně pracovali na tom, aby sis splni{{VerbPast}} svůj sen. Někdy to šlo jako po másle, jindy to bylo náročnější.
        </p><p>
        Ty jsi na sobě ale tvrdě pracova{{VerbPast}}. Podívej se na všechny úspěchy, kterých jsi dosáhnu{{VerbPast}}. Fantastické!
        </p><p>
        Dorazi{{VerbPast}} jsi do cíle. Teď už to zvládneš beze mě. Držím ti palce.
        </p><p>
        <strong>Užívej si vysněný život bez cigaret!</strong></p><p></p>`
      } as IBadgeDetailTextBlock,
    ],
    order: 17,   
  },
]