import { ILandingImage } from "../interfaces/landingImage";

// Landing images are chosen randomly from range in the right day/time
export const landingImages: ILandingImage[] = [
  {image: "adiquit_hero_image_barbecue", startTime: "18:00:00", endTime: "20:00:00", days: "56"},
  {image: "adiquit_hero_image_bicycle", startTime: "10:00:00", endTime: "17:00:00", days: "67"},
  {image: "adiquit_hero_image_bridge_1", startTime: "10:00:00", endTime: "17:00:00", days: "67"},
  {image: "adiquit_hero_image_camping_day", startTime: "18:00:00", endTime: "20:00:00", days: "56"},
  {image: "adiquit_hero_image_camping_night", startTime: "20:00:00", endTime: "22:00:00", days: "56"},
  {image: "adiquit_hero_image_city_1", startTime: "14:00:00", days: "17:00:00", endTime: "12345"},
  {image: "adiquit_hero_image_class", startTime: "08:00:00", days: "10:00:00", endTime: "12345"},
  {image: "adiquit_hero_image_coffee_shop_1", startTime: "15:00:00", endTime: "17:00:00", days: "12345"},
  {image: "adiquit_hero_image_coffeshop_night", startTime: "18:00:00", endTime: "20:00:00", days: "12345"},
  {image: "adiquit_hero_image_conference", startTime: "07:00:00", endTime: "09:00:00", days: "1234567"},
  {image: "adiquit_hero_image_conference", startTime: "13:00:00", endTime: "14:00:00", days: "1234567"},
  {image: "adiquit_hero_image_elevator", startTime: "08:00:00", endTime: "09:30:00", days: "12345"},
  {image: "adiquit_hero_image_elevator", startTime: "16:00:00", endTime: "16:30:00", days: "12345"},
  {image: "adiquit_hero_image_fishing_2", startTime: "05:00:00", endTime: "07:00:00", days: "567"},
  {image: "adiquit_hero_image_fishing_2", startTime: "17:00:00", endTime: "20:00:00", days: "567"},
  {image: "adiquit_hero_image_forest_1", startTime: "09:00:00", endTime: "16:00:00", days: "67"},
  {image: "adiquit_hero_image_hall_sitting", startTime: "07:30:00", endTime: "08:30:00", days: "12345"},
  {image: "adiquit_hero_image_home_office_1", startTime: "09:00:00", endTime: "12:00:00", days: "12345"},
  {image: "adiquit_hero_image_home_office_1", startTime: "13:00:00", endTime: "16:00:00", days: "12345"},
  {image: "adiquit_hero_image_home_office_2", startTime: "17:00:00", endTime: "19:00:00", days: "1234"},
  {image: "adiquit_hero_image_living_room", startTime: "16:00:00", endTime: "17:30:00", days: "1234567"},
  {image: "adiquit_hero_image_lookout_1", startTime: "15:00:00", endTime: "18:30:00", days: "67"},
  {image: "adiquit_hero_image_market", startTime: "17:00:00", endTime: "19:00:00", days: "1234567"},
  {image: "adiquit_hero_image_mountains", startTime: "09:00:00", endTime: "18:00:00", days: "67"},
  {image: "adiquit_hero_image_mushrooms", startTime: "15:00:00", endTime: "18:00:00", days: "67"},
  {image: "adiquit_hero_image_nature_1", startTime: "16:30:00", endTime: "18:30:00", days: "12345"},
  {image: "adiquit_hero_image_nature_1", startTime: "09:30:00", endTime: "16:30:00", days: "67"},
  {image: "adiquit_hero_image_park_1", startTime: "06:00:00", endTime: "07:30:00", days: "1234567"}, //rano
  {image: "adiquit_hero_image_park_2", startTime: "13:00:00", endTime: "14:00:00", days: "12345"}, //den
  {image: "adiquit_hero_image_park_3", startTime: "21:00:00", endTime: "23:00:00", days: "1234567"}, //vecer
  {image: "adiquit_hero_image_photography", startTime: "06:00:00", endTime: "18:00:00", days: "67"},
  {image: "adiquit_hero_image_planting", startTime: "19:30:00", endTime: "20:30:00", days: "1357"},
  {image: "adiquit_hero_image_platform", startTime: "07:30:00", endTime: "08:00:00", days: "135"},
  {image: "adiquit_hero_image_prague_1", startTime: "16:00:00", endTime: "17:00:00", days: "12345"},
  {image: "adiquit_hero_image_rain", startTime: "17:30:00", days: "18:30:00", endTime: "24"},
  {image: "adiquit_hero_image_reading_1", startTime: "17:00:00", endTime: "18:30:00", days: "135"},
  {image: "adiquit_hero_image_restaurant_outside", startTime: "11:30:00", endTime: "13:00:00", days: "35"},
  {image: "adiquit_hero_image_subway", startTime: "07:45:00", endTime: "08:15:00", days: "24"},
  {image: "adiquit_hero_image_subway", startTime: "15:45:00", endTime: "16:15:00", days: "24"},
  {image: "adiquit_hero_image_tennis_1", startTime: "17:30:00", endTime: "18:30:00", days: "24"},
  {image: "adiquit_hero_image_train", startTime: "15:30:00", endTime: "16:00:00", days: "135"},
  {image: "adiquit_hero_image_tram", startTime: "08:00:00", endTime: "08:30:00", days: "12345"},
  {image: "adiquit_hero_image_uni_library_1", startTime: "17:30:00", endTime: "18:30:00", days: "135"},
  {image: "adiquit_hero_image_university_library_laptop", startTime: "17:30:00", endTime: "18:30:00", days: "135"},
  {image: "adiquit_hero_image_university_outside", startTime: "18:30:00", endTime: "19:15:00", days: "135"},
  {image: "adiquit_hero_image_windmill_1", startTime: "09:00:00", endTime: "18:00:00", days: "67"},
  {image: "adiquit_hero_image_airport", startTime: "17:00:00", endTime: "18:00:00", days: "5"},
  {image: "adiquit_hero_image_crosswalk", startTime: "10:00:00", endTime: "11:30:00", days: "12345"},
  {image: "adiquit_hero_image_garden", startTime: "10:00:00", endTime: "11:00:00", days: "67"},
  {image: "adiquit_hero_image_jogging", startTime: "17:30:00", endTime: "18:30:00", days: "12345"},
  {image: "adiquit_hero_image_jogging", startTime: "9:30:00", endTime: "11:30:00", days: "67"},
  {image: "adiquit_hero_image_lecture", startTime: "09:00:00", endTime: "11:00:00", days: "12345"},
  {image: "adiquit_hero_image_livingroom_tv", startTime: "21:00:00", endTime: "23:00:00", days: "12345"},
  {image: "adiquit_hero_image_livingroom_tv", startTime: "22:00:00", endTime: "23:59:00", days: "67"},
  {image: "adiquit_hero_image_on_the_train", startTime: "8:30:00", endTime: "9:30:00", days: "24"},
  {image: "adiquit_hero_image_on_the_train", startTime: "15:30:00", endTime: "16:00:00", days: "135"},
]