import React, { useContext, useEffect, useState } from "react"
import NavBar, { NavBarItem } from "../../common/navbar/navBar";
import { userVariablesMock } from "../../api/mockData/userVariablesMock";
import Memento from "./components/memento";
import { IMemento, mementoData } from "./data/mementos";
//a
import Hints from "../common/hints";
import FeedbackFormular from "../../common/components/FeedbackFormular";

import "./plan.scss"
import getUserSummary from "../../api/requests/getUserSummary";
import { AuthContext } from "../../context/authProvider";
import { IUserSummary } from "../../api/interfaces/userSummary";
import sendUserVariables from "../../api/requests/sendUserVariables";
import { ObjectUtils } from "../../common/utils/objectUtils";
import { STATIC_IMAGES } from "../../assets/staticImages";
import PageWrapper from "../../common/components/pageWrapper";
import ProfileStrengthIndicator from "../profil/ProfileStrengthIndicator";

const Plan = () => {

  const [token, login, logout] = useContext(AuthContext);
  const [mementos, setMementos] = useState<IMemento[]>([]);
  const [userVariables, setUserVariables] = useState<{ [key: string]: any }>(userVariablesMock)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hint = "Tady najdeš přehled svých důvodů proč přestat kouřit a strategií pro úspěšné odvykání, které spolu sestavíme.";
  let showHint:boolean = false; //TODO co s tim?

  useEffect(() => {
    setIsLoading(true);
    getUserSummary(token).then((summary: IUserSummary | undefined) => {
      setIsLoading(false);
      if(summary) {
        const vars: {[key: string]: any} = summary.variables;
        setUserVariables(vars);
        setMementos(mementoData.filter(memento =>
          (vars[memento.variableName] !== "" && vars[memento.variableName] != null)));
      }
    })
  }, [])

  function saveUserVariables(variables: { [key: string]: any }, varName: string) {
    sendUserVariables(ObjectUtils.pickObjectSubset(variables, [varName]), token)
      .then(x => console.log("send and received: ", x));
    const varCopy = {...userVariables};
    varCopy[varName] = variables[varName];
    setUserVariables(varCopy);
  }

  return (
    <PageWrapper>
      <div className="plan-wrapper">
        <NavBar chosenItem={NavBarItem.Plan}></NavBar>
        <div className="plan-content">
          <h1 className="plan-title">Můj plán</h1>
          <div className="plan-content-panels">
            <div className="plan-content-panel-1">    
              <img className="plan-background-image" src={STATIC_IMAGES.plan_background_image}/>
              <Hints text={hint} order={1} show={showHint} />
            </div>
            <div className="plan-content-panel-2">
              <div className="plan-mementos-table">
                {isLoading ? "Načítá se obsah..." :
                  (mementos.length > 0 ?
                    mementos.map(memento =>
                        <Memento
                          key={memento.title + userVariables[memento.variableName]}
                          memento={memento}
                          userVariables={userVariables}
                          setUserVariables={saveUserVariables}
                          /> 
                          )
                          :
                          <p className="plan-no-mementos-p">Zatím tu ještě nic nemáme, ale brzy zde uvidíš přehled svých důvodů!</p>
                          )
                        }
                </div>
            </div>
          </div>
        </div>
        <FeedbackFormular formular_src="https://docs.google.com/forms/d/e/1FAIpQLSek0OoO5rObUyVRPqPlagw5CKpHOHbT8fvvit_YhnVLQTF6fA/viewform?embedded=true" />
      </div>
    </PageWrapper>
  )
}

export default Plan;