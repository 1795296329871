import React, { useContext, useEffect, useRef, useState } from "react"
import getPaymentAmout from "../../../api/requests/getPaymentAmout"
import getPaymentInfo from "../../../api/requests/getPaymentInfo"
import { STATIC_IMAGES } from "../../../assets/staticImages"
import TextEl from "../../../common/components/textEl"
import { AuthContext } from "../../../context/authProvider"
import { paymentArguments } from "../data/paymentArguments"
import PaymentArgument from "./paymentArgument"
import SpecialCode from "./specialCode"
import { createPortal } from "react-dom"

//DEBUG
/*
import { DebugContext } from "../../../context/debugProvider"
import restartSession from "../../../api/requests/restartSession"
import skipSession from "../../../api/requests/skipSession"
*/
//END OF DEBUG

interface IPaymentProps {
  apiData: { [key: string]: any };
  userSummary: { [key: string]: any };
}

const Payment = ({
  apiData,
  userSummary,
}: IPaymentProps) => {
  
  const [token, login, logout] = useContext(AuthContext);
  const paymentPageTitle = "<h1>První dny s Adiquit máš úspěšně za sebou</h1>"
  const paymentPageText = "<h2> Ahoj {{Name5p}}! </h2> <p>Oceňuju tvoje odhodlání. Přípravnou fázi máš skoro za sebou. Již brzy típneš poslední cigaretu, odvykání začne – a s ním i těžké časy.</p><p>Vím, co dělá lidem problémy a jak se dají zvládat. Bohužel, pojišťovny zatím moji digitální terapii nehradí, ačkoliv pacientům pomáhá a její vývoj stojí spoustu peněz.</p><h3>Takže je to na tobě — dál se už zadarmo neposuneme...</h3>"

  const [paymentArgIndex, setPaymentArgIndex] = useState<number>(0);
  const [goPayUrl, setGoPayUrl] = useState<string>();

  //DEBUG
  /*
  const [isDebugMode, setIsDebugMode] = useContext(DebugContext);
  const [checkpoints, setCheckpoints] = useState<any[]>([]);
  const [chosenCheckpointId, setChosenCheckpointId] = useState<number>();
  const [apiData2, setApiData] = useState< {[key: string]: any} >();

  function restartSessionClick() {
    restartSession(token).then(x => getPage(token)
    .then((data: any) => {
      console.log("Appointment data RECEIVED", data.data);
      setApiData(data);
    }))
  }

  function skipSessionClick() {
    skipSession(token).then(x => {
      // timeout because no way how to see when is the page ready
      setTimeout(function(){
        getPage(token).then((data: any) => {
          console.log("Appointment data RECEIVED", data.data);
          setApiData(data);
        })
      }, 3000);
    })

    function loadCheckpointClick() {
      if(!chosenCheckpointId) return;
      loadCheckpoint(token, chosenCheckpointId).then(x => 
        window.location.reload()
      );
    }
  
    function createCheckpointClick() {
      createCheckpoint(token).then(x => 
        window.location.reload()
      );
    }
  
    function deleteCheckpointClick() {
      if(!chosenCheckpointId) return;
      deleteCheckpoint(token, chosenCheckpointId).then(x => 
        window.location.reload()
      );
    
      //END OF DEBUG
  }
  */

  useEffect(() => {
    fetchPaymentInfo();
  }, [])

  const specialCodeEl = useRef<HTMLDivElement>(null);
  const paymentArgsEl = useRef<HTMLDivElement>(null);
  const toggleWindow = (window: HTMLDivElement | null) => {
    if (window) {
      if (window.style.display !== "none") {
        window.style.display = "none";
      } else {
        window.style.display = "flex";
      }
    }
    setPaymentArgIndex(0);
  }

  // Go to next page in the Payment argument window
  function setPaymentArgIndexTo(newIndex: number) {
    if (newIndex < 0 || newIndex >= paymentArguments.length) {
      return;
    }
    setPaymentArgIndex(newIndex)
  }

  function fetchPaymentInfo() {
    getPaymentInfo(token).then((x: any) => {
      if(x) {
        setGoPayUrl(x.payment.gw_url)
      }
    })
  }

  // Send code for discount/free access
  function sendCode(newCode: string) {
    console.log(newCode)
    if(newCode !== "") {
      toggleWindow(specialCodeEl.current);
      getPaymentAmout(token, newCode).then((x: any) => {
        if(x["errors"]?.code) {
          alert(x["errors"].code)
        } else {
          alert("Kód přijat!")
          window.location.reload();
        }
      });
    }
  }

  return (
    <>
      <TextEl
        text={paymentPageTitle}
        apiVariables={userSummary.variables}
        presentVarNames={Object.keys(userSummary.variables)}
        key={paymentPageTitle}
      />
      <img className="payment-background-image" src={STATIC_IMAGES.payment_background_image} />
      <TextEl
        text={paymentPageText}
        apiVariables={userSummary.variables}
        presentVarNames={Object.keys(userSummary.variables)}
        key={paymentPageText}
      />
      {goPayUrl != null ?
        <>
          <ul className="payment-icons-list">
            <li className="payment-icon">
              <img src={STATIC_IMAGES.money} alt="money" className="payment-icons-list-img"/>
              <a href={goPayUrl} className="payment-icons-list-link">Zaplatit {(userSummary.price_amount / 100).toFixed(2)} {userSummary.price_currency} za celou terapii</a>
            </li>
            <li className="payment-icon">
              <img src={STATIC_IMAGES.present} alt="present" className="payment-icons-list-img"/>
              <button onClick={e => toggleWindow(specialCodeEl.current)} className="payment-icons-list-button">Zadat Slevový / Firemní kód</button>
            </li>
            <li className="payment-icon">
              <img src={STATIC_IMAGES.ligthBulb} alt="ligthBulb" className="payment-icons-list-img"/>
              <button onClick={e => toggleWindow(paymentArgsEl.current)} className="payment-icons-list-button">Proč zaplatit?</button>
            </li>
          </ul>
          {/* DEBUG MODE */}
          {/*
          {isDebugMode === true ?
                <div className="debug-session-area" key={"debug: " + apiData + isDebugMode}>
                  <div className="debug-session-title">Debug Mode (episode: {apiData.title_admin}):</div>
                  <div className="debug-session-button-area">
                    <button onClick={e => {restartSessionClick()}}>Restart</button>
                    <button onClick={e => {skipSessionClick()}}>Další</button>
                  </div>
                  <div className="debug-session-title" style={{marginTop: "20px"}}>Checkpointy:</div>
                  {checkpoints.map(checkpoint => 
                    <button className={"debug-session-checkpoint " + (chosenCheckpointId === checkpoint.id ? "debug-session-checkpoint-chosen" : "")} 
                      onClick={e => setChosenCheckpointId(checkpoint.id)}>
                      {checkpoint.name + " " + checkpoint.created_at}
                    </button>
                  )}
                  <div className="debug-session-button-area">
                    <button onClick={e => {deleteCheckpointClick()}}>Smazat</button>
                    <button onClick={e => {loadCheckpointClick()}}>Načíst</button>
                    <button onClick={e => {createCheckpointClick()}}>Uložit</button>
                  </div>
                </div>
                : ""}
                  */}
        </>
        :
        <div style={{marginTop: "20px"}}>Načítám platební možnosti...</div>
      }
      {createPortal(
        <div ref={paymentArgsEl} className="payment-argument-form" style={{display: "none"}}>
          <PaymentArgument
            paymentArgument={paymentArguments[paymentArgIndex]}
            cancel={toggleWindow}
            windowRefEl={paymentArgsEl}
            changePaymentArgs={setPaymentArgIndexTo}
            paymentArgIndex={paymentArgIndex}
            apiVariables={userSummary.variables}
            goPayUrl={goPayUrl}
          />
        </div>, document.body
      )}

      {createPortal(
        <div ref={specialCodeEl} className="payment-form-special-code" style={{display: "none"}}>
          <SpecialCode
            toggleWindow={toggleWindow}
            specialCodeRef={specialCodeEl}
            sendCode={sendCode}
          />
        </div>, document.body
      )}
    </>
  )

}

export default Payment