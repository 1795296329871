import React from "react";
import { IApiVariableValue } from "../../interfaces/apiVariableValue";
import TextEl from "./apTextEl";

interface ApToggleElProps {
  toggleText: string;
  contentText: string;
  toggleVariables: {[key: string]: boolean};
  toggleVarName: string;
  imgTitle: string | undefined;
  setToggleVariables: React.Dispatch<React.SetStateAction<{[key: string]: boolean}>>;
  apiVariables: {[key: string]: IApiVariableValue},
}

const ApToggleEl = ({
  toggleText,
  contentText,
  toggleVariables, 
  toggleVarName, // extra variables for toggling content so they dont get sent to server
  imgTitle,
  setToggleVariables,
  apiVariables,
}: ApToggleElProps) => {

  return (
    <div className="toggle-container">
      <div className="toggle-headline" >
        {/* Button with image and plus/minus sign */}
        <button onClick={e => {
          let newToggleVariables: any = {...toggleVariables};
          newToggleVariables[toggleVarName] = !newToggleVariables[toggleVarName]
          setToggleVariables(newToggleVariables)
        }}>
          {imgTitle ? <img src={imgTitle} alt={imgTitle}/> : <></>}
          <div className="button-sign">
            {toggleVariables[toggleVarName] ? "-" : "+"}
          </div>
          <strong><TextEl text={toggleText} apiVariables={apiVariables}/></strong>
        </button>
      </div>
      <div className="toggle-content" style={{display: toggleVariables[toggleVarName] ? "block" : "none"}}>
        <TextEl text={contentText} apiVariables={apiVariables}/>
      </div>
    </div>
  );
};

export default ApToggleEl
