import React, { useState } from "react"
import { IApiVariableValue } from "../../interfaces/apiVariableValue"
import TextEl from "./apTextEl";

interface ApToggleSetMultiElAlternative {
  label: string;
  value: any;
  text: string;
}

interface ApToggleSetMultiElProps {
  labelText: string;
  varName: string;
  apiVariables: { [key: string]: IApiVariableValue };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>;
  alternatives: ApToggleSetMultiElAlternative[];
  required?: boolean;
}

// This is both multi selection and toggle, so if you click something it toggles content
const ApToggleSetMultiEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  alternatives,
  required,
}: ApToggleSetMultiElProps) => {
  
  const [validationMessage, setValidationMessage] = useState<string>("");

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {    
    let newApiVariables = {...apiVariables};
    const curValue = newApiVariables[varName].value;
    if(typeof curValue !== "string") {
      newApiVariables[varName].value = e.target.checked ?
        [...curValue, e.target.value] : curValue.filter((x: any) => x !== e.target.value);
    }
    handleValidity(newApiVariables);
    setApiVariables(newApiVariables);
  }

  function handleValidity(newApiVariables: { [key: string]: IApiVariableValue }) {
    const curValue = newApiVariables[varName];
    if (required && (curValue.value === "" || curValue.value === null || curValue.value?.length === 0)) {
      setValidationMessage("Vyber prosím alespoň jednu možnost");
      curValue.valid = false;
      return;
    }
    curValue.valid = true;
    setValidationMessage("");
  }

  return (
    <>
      <div className="multiplechoice">
        <div><strong><TextEl text={labelText} apiVariables={apiVariables}/></strong></div>
        {alternatives.map(alternative =>
          <div key={varName + alternative.value}>
            <div className="multiplechoice-toggle-alternative">
              <input id={varName + alternative.label} name={varName} type="checkbox" value={alternative.value}
              onChange={e => handleOnChange(e)}></input>
              <label htmlFor={varName + alternative.label}>
                <TextEl text={alternative.label} apiVariables={apiVariables}/>
              </label>
            </div>          
            {Array.isArray((apiVariables[varName]).value) &&
              (apiVariables[varName]).value.includes(alternative.value) &&
              <div className="multiplechoice-toggle-alternative-text">
              <TextEl text={alternative.text} apiVariables={apiVariables}/>
              </div>
            }
          </div>)}
      </div>
      {validationMessage !== "" &&
        <div className="element-validation-message">{validationMessage}</div>}
    </>
  );
};

export default ApToggleSetMultiEl