import { API_ROUTES } from "../apiRoutes";
import { IPendingNotification } from "../interfaces/replyNotification";

function getPendingReplyNotification(token: string): Promise<IPendingNotification | {} | undefined> {
  return fetch(API_ROUTES.getPendingReplyNotificationUrl, {
    method: "GET",
    headers: {
      "Authorization": "Token " + token,
      "Accept-Language": "cs"
    },
  }).then(response =>
    response.json()
  ).then(data => {
    return new Promise<IPendingNotification>(resolve => resolve(data))
  }).catch(error => {
    return new Promise<undefined>(resolve => resolve(undefined))
  })
}

export default getPendingReplyNotification;