import React, { Children } from "react";
import "./pageWrapper.scss";

type Props = {
    children: React.ReactNode;
}

const PageWrapper = ({ children }: Props) => {

    return (
        <div className="page-wrapper">
            {children}
        </div>
    )
}

export default PageWrapper;