import { IStatistic } from "../../pages/success/interfaces/statistic";
import { API_ROUTES } from "../apiRoutes";

function getUserStatistics(token: string): Promise<IStatistic[]> {
  return fetch(API_ROUTES.userStatisticsUrl, {
    method: "GET",
    headers: {
      "Authorization": "Token " + token,
      "Accept-Language": "cs"
    },
  }).then(response =>
    response.json()
  ).then(data => {
    return new Promise<IStatistic[]>(resolve => {resolve(data);})
  }).catch(error => {
    return new Promise<IStatistic[]>(resolve => resolve([]))
  })
}

export default getUserStatistics;