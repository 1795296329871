export const userVariablesMock = {
  "HRSitPlan": null,
  "EEcp": 1.0,
  "FU": null,
  "ValuesAndSmo": null,
  "SmoUrPlan": null,
  "session": 5.0,
  "ReaQui": "I will die soon",
  "HRSit": null,
  "SmoUrStrat": null,
  "Desires": null,
  "AdjArch": null,
  "Finished": 1.0,
  "PHASE": "EE",
  "Abst": null,
  "Name": "Dan",
  "EE": 10.0,
  "hasLogin": null,
  "AdjEnd": "-",
  "0Sex": null,
  "Name5p": "Dane",
  "SupPer": null,
  "PlanForCessationDay": null,
  "EQ": null,
  "SmokingCuesList": null,
  "EQcp": null,
  "VerbPast": "-",
  "CessationPlan": null,
  "ChangeTalkNeed": null,
  "simpleRegistered": null,
  "NoCig1": 11
}