import { API_ROUTES } from "../apiRoutes";

function skipSession(token: string): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: { "Authorization": "Token " + token, "Content-Type": "application/json" },
  };
  return  fetch(API_ROUTES.skipUrl, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return new Promise<boolean>(resolve => resolve(data.success));
    }).catch(e => {
      return new Promise<boolean>(resolve => resolve(false));
    })
}

export default skipSession;