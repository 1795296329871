import React, { useEffect, useState } from "react"
import SimpleInputEl, { SimpleInputType } from "./simpleInputEl";
import TextEl from "./textEl";

interface IMultipleSelectionAlternative {
  label: string;
  value: any;
}

interface MultipleSelectionInputElProps {
  labelText: string;
  varName: string;
  apiVariables: { [key: string]: any };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: string | string[]}>>;
  alternatives: IMultipleSelectionAlternative[];
  required?: boolean;
  lastOptionSimpleInput?: {
    labelText?: string;
    inputType: SimpleInputType;
    varName: string;
  }
}

const MultipleSelectionInputEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  alternatives, // all chekable options
  required,
  lastOptionSimpleInput, // if user can put his own text after checking last option 
}: MultipleSelectionInputElProps) => {

  const [validationMessage, setValidationMessage] = useState<string>("");
  
  useEffect(() => {
    if(apiVariables[varName] == null) {
      const newApiVariables = {...apiVariables}
      newApiVariables[varName] = []
      setApiVariables(newApiVariables)
    }
  }, [])

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newApiVariables = {...apiVariables};
    const curValue = apiVariables[varName] ?? [];
    // Add or remove newly checked/unchecked option from the variable value
    if(Array.isArray(curValue)) {
      newApiVariables[varName] = e.target.checked ?
        [...curValue, e.target.value] : curValue.filter((x: any) => x !== e.target.value);
    }
    handleValidity(newApiVariables);
    setApiVariables(newApiVariables);
  }

  function handleValidity(newApiVariables: { [key: string]: any }) {
    const curValue = newApiVariables[varName];
    if (required && (curValue === null || curValue?.length === 0 || 
      (lastOptionSimpleInput && curValue?.includes(curValue[0] === alternatives[alternatives.length - 1].value) &&
        newApiVariables[lastOptionSimpleInput.varName] === ""))) {
      setValidationMessage("Vyber prosím alespoň jednu možnost");
      return;
    }
    setValidationMessage("");
  }

  return (
    <div className="multiplechoice">
      <div className="multiplechoice-title"><strong><TextEl text={labelText} presentVarNames={Object.keys(apiVariables)} apiVariables={apiVariables}/></strong></div>
      {alternatives.map((alternative, index) =>
        <div className="multiplechoice-alternative" key={varName + alternative.value}>
          <input id={varName + alternative.label} name={varName} type="checkbox" value={alternative.value} checked={apiVariables[varName] != null && apiVariables[varName].includes(alternative.value)}
            onChange={e => handleOnChange(e)}></input>
          <label htmlFor={varName + alternative.label}>
            <TextEl text={alternative.label} presentVarNames={Object.keys(apiVariables)} apiVariables={apiVariables}/>
          </label>
        </div>
      )}
      {/* if user can put his own text after checking last option  */}
      {lastOptionSimpleInput && apiVariables[varName]?.includes(alternatives[alternatives.length - 1].value) &&
        <SimpleInputEl
          apiVariables={apiVariables}
          inputName={lastOptionSimpleInput.varName}
          inputType={lastOptionSimpleInput.inputType}
          setApiVariables={setApiVariables}
          labelText={lastOptionSimpleInput.labelText}
          cssClassName={"lastOptionSimpleInput"}
        />}
      {validationMessage !== "" &&
        <div className="element-validation-message">{validationMessage}</div>}
    </div>
  );
};

export default MultipleSelectionInputEl