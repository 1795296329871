import { IPaymentArgumentPage } from "../interfaces/paymentArgumentPage";

export enum PaymentArgumentsType {
  NORMAL = "normal",
  FEEDBACK = "feedback",
  FEEDBACK_END = "feedback_end",
  NORMAL_END = "normal_end",
}

// Payment Argument Window
export const paymentArguments: IPaymentArgumentPage[] = [
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_6_danger",
    title: "Odvykání bez pomoci končí až v 90\u00A0% neúspěchem.",
    text: "9 z 10 kuřáků, kteří se rozhodnou přestat svépomocí, si znovu zapálí cigaretu už v prvních třech měsících odvykání. Odborná pomoc se při odvykání vyplatí.",
  },
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_1_graph",
    title: "Adiquit je až 6× účinnější.",
    text: "Adiquit je jediná mobilní aplikace na odvykání kouření založená na skutečné klinické praxi. Podle klinických studií je odvykání s Adiquit až 6× účinnější."
  },
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_3_health",
    title: "Kdo nekouří, je zdravější.",
    text: "Bez cigaret se ti bude lépe dýchat, zlepší se ti fyzička, upraví se ti krevní tlak, klesne riziko infarktu a také sníží riziko desítek vážných onemocnění. Odvykání má smysl."
  },
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_4_money",
    title: "Kdo nekouří, je bohatší.",
    text: "Bez cigaret ušetříš. A je to spousta peněz na plnění snů. Až 36 000 Kč za rok. Tak pojď šetřit společně s Adiquit!"
  },
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_2_beauty",
    title: "Bez cigaret lepší vzhled!",
    text: "Po několika týdnech nekouření se ti zlepší kvalita vlasů, pleti, nehtů a zubů. Zahoď cigarety a rázem uvidíš v zrcadle mladší osobu!"
  },
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_community",
    title: "Staneš se členem komunity Adiquit.",
    text: "Odvykání není jednoduché. Když to ale zvládneš, zažiješ neopakovatelný pocit vítězství. Svůj úspěch můžeš sdílet s ostatními a podpořit je, aby to zvládli."
  },
  {
    type: PaymentArgumentsType.NORMAL,
    imgUrl: "adiquit_image_arguments_7_feedback",
    title: "Chápu, že nechceš dál pokračovat.",
    text: "Zajímalo by mě, proč ses tak rozhod{{VerbPast}}. Můžu tě poprosit o bleskové zhodnocení?"
  },
  {
    type: PaymentArgumentsType.FEEDBACK,
    title: "Prosím vyber jeden nebo více důvodů, proč nechceš dál aplikaci Adiquit používat:",
    text: "Zajímalo by mě, proč ses tak rozhod{{VerbPast}}. Můžu tě poprosit o bleskové zhodnocení?"
  },
  {
    type: PaymentArgumentsType.FEEDBACK_END,
    imgUrl: "adiquit_image_arguments_8_adam_thumbs_up",
    title: "Díky za tvůj názor.",
    text: "Mrzí mě, že jsem s tebou při odvykání nemohl být déle. Pevně věřím, že to přesto zvládneš.\n\nKdybys kdykoliv potřebova{{VerbPast}} pomoct, víš, kde mě hledat - v aplikaci Adiquit.\n\nTvůj Adam"
  },
  {
    type: PaymentArgumentsType.NORMAL_END,
    imgUrl: "adiquit_image_arguments_9_adam_waving",
    title: "Beru tvé rozhodnutí na vědomí.",
    text: "Díky, že jsi vyzkouše{{VerbPast}} aplikaci Adiquit. Kdybys kdykoliv potřebova{{VerbPast}} pomoct, víš, kde mě hledat - v aplikaci Adiquit.\n\nTvůj Adam"
  }

]

export const paymentArgumentFeedbackEl = {
  "variable_name": "paymentArgsFeedback",
  "label": "",
  "required": false,
  "value": [],
  "alternatives": [
      {
          "label": "Nejsem si jist{{AdjEnd}}, jestli chci opravdu přestat kouřit.",
          "value": "1"
      },
      {
          "label": "Nevěřím, že by mi mobilní aplikace dokázala pomoct.",
          "value": "2"
      },
      {
          "label": "Nevyhovuje mi, jak Adam v aplikaci komunikuje.",
          "value": "3"
      },
      {
          "label": "Terapie neřeší to, co bych potřebova{{VerbPast}}.",
          "value": "4"
      },
      {
          "label": "Mám s aplikací technické problémy.",
          "value": "5"
      },
      {
          "label": "Cena aplikace je příliš vysoká.",
          "value": "6"
      },
  ]
}