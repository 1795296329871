import React, { useState } from "react"
import { IApiVariableValue } from "../../interfaces/apiVariableValue"
import TextEl from "./apTextEl";


interface IApMultipleChoiceAlternative {
  label: string;
  value: any;
}

interface ApMultipleChoiceInputElProps {
  labelText: string;
  varName: string;
  apiVariables: { [key: string]: IApiVariableValue };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>;
  alternatives: IApMultipleChoiceAlternative[];
  required?: boolean;
}

const ApMultipleChoiceInputEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  alternatives,
  required,
}: ApMultipleChoiceInputElProps) => {
  
  const [validationMessage, setValidationMessage] = useState<string>("");

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newApiVariables = {...apiVariables};
    newApiVariables[varName].value = e.target.value;
    handleValidity(newApiVariables);
    setApiVariables(newApiVariables);
  }

  function handleValidity(newApiVariables: { [key: string]: IApiVariableValue }) {
    const curValue = newApiVariables[varName];
    if (required && (curValue.value === "" || curValue.value === null || curValue.value?.length === 0)) {
      setValidationMessage("Vyber prosím jednu možnost");
      curValue.valid = false;
      return;
    }
    curValue.valid = true;
    setValidationMessage("");
  }

  return (
    <div className="multiplechoice">
      <div><strong><TextEl text={labelText} apiVariables={apiVariables}/></strong></div>
      {alternatives.map(alternative =>
        <div className="multiplechoice-alternative" key={varName + alternative.value}>
          <input id={varName + alternative.label} name={varName} type="radio" value={alternative.value}
            onChange={e => handleOnChange(e)}></input>
          <label htmlFor={varName + alternative.label}>
            <TextEl text={alternative.label} apiVariables={apiVariables}/>
          </label>
        </div>          
      )}
      {validationMessage !== "" &&
        <div className="element-validation-message">{validationMessage}</div>}
    </div>
  );
};

export default ApMultipleChoiceInputEl