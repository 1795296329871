import React, { useEffect, useState } from "react";
import { STATIC_IMAGES } from "../../assets/staticImages";
import { ISurvey } from "./interface/survey";
import { ISurveyDetailBlockType } from "./interface/surveyDetailBlock";
import SurveyPageBuilder from "./surveyPageBuilder"

interface ISurveyBuilderProps {
  survey: ISurvey;
  languageVariables: { [key: string]: string },
  userVariables: { [key: string]: string };
  cancel: () => void;
  save: (varsToSave: {[key: string]: string}) => void;
  isDetailVisible: boolean;
}

const SurveyBuilder = ({
  survey,
  languageVariables,
  userVariables,
  cancel,
  save,
  isDetailVisible,
}: ISurveyBuilderProps) => {


  const [temporaryUserVariables, setTemporaryUserVariables] = useState<{ [key: string]: any }>(
    Object.assign({}, userVariables));
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [validationMessage, setValidationMessage] = useState<string>("");

  useEffect(() => {
    setTemporaryUserVariables(userVariables);
  }, [userVariables])

  useEffect(() => {
    setPageIndex(0);
  }, [isDetailVisible])

  function cancelUserVariables(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    setTemporaryUserVariables(userVariables)
    cancel();
  }

  function handleBackClick(e: any) {
    e.preventDefault();
    if(pageIndex > 0) {
      setValidationMessage("");
      setPageIndex(pageIndex - 1);
    }
  }

  function handleNextClick(e: any) {
    e.preventDefault();
    if(!handleFormValidity()) {
      setValidationMessage("Vyplň prosím všechna pole.")
      return;
    } else {
      setValidationMessage("");
    }

    const skipNextPage = survey.pages[pageIndex + 1]?.skip
    if(pageIndex !== survey.pages.length - 1 && (!skipNextPage || !skipNextPage(temporaryUserVariables))) {
      setPageIndex(pageIndex + 1);
    } else {
      save(getProcessedVariablesInBlocks());
    }    
  }
  
  function handleFormValidity(): boolean {    
    const currentPage = survey.pages[pageIndex];
    for (const block of currentPage.detailBlocks) {
      // NaN
      if(block.type === ISurveyDetailBlockType.INPUT_NUMBER) {
        if (isNaN(+temporaryUserVariables[block.varName])) {
          return false
        }
      }
      // empty
      if(block.type === ISurveyDetailBlockType.INPUT_NUMBER ||
        block.type === ISurveyDetailBlockType.INPUT_TEXT) {
        if (temporaryUserVariables[block.varName] === "" || temporaryUserVariables[block.varName] === null) {
          return false
        }
      }
      // Multi choice
      if(block.type === ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE) {
        if(temporaryUserVariables[block.varName] === "" || temporaryUserVariables[block.varName] == null ||
          (block.lastOptionSimpleInput && temporaryUserVariables[block.varName] === block.alternatives[block.alternatives.length - 1].value &&
          (temporaryUserVariables[block.lastOptionSimpleInput.varName] === "" || temporaryUserVariables[block.lastOptionSimpleInput.varName] == null ||
          isNaN(+temporaryUserVariables[block.lastOptionSimpleInput.varName])))) {
            return false;
        }
      }
      // Multi selection
      if(block.type === ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION) {
        if(temporaryUserVariables[block.varName].length === 0 || temporaryUserVariables[block.varName] == null ||
          (block.lastOptionSimpleInput && temporaryUserVariables[block.varName]?.includes(block.alternatives[block.alternatives.length - 1].value) &&
           (temporaryUserVariables[block.lastOptionSimpleInput.varName] === "" || temporaryUserVariables[block.lastOptionSimpleInput.varName] == null))) {
            return false;
        }
      }
    }
    return true
  }

  function getProcessedVariablesInBlocks(): { [key: string]: string } {
    const usedVariables: { [key: string]: any } = {};
    for (const page of survey.pages) {
      if(page.skip && page.skip(temporaryUserVariables)) {
        break;
      }
      page.detailBlocks.forEach(detailBlock => {
        if(detailBlock.type === ISurveyDetailBlockType.INPUT_NUMBER ||
           detailBlock.type === ISurveyDetailBlockType.INPUT_TEXT) {
            usedVariables[detailBlock.varName] = temporaryUserVariables[detailBlock.varName];
        } else if(detailBlock.type === ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE) {
          // IF input is multiple choice, has "any last option -> write own reason" and last option selected, dont send last option but the text in it
          if (detailBlock.lastOptionSimpleInput && temporaryUserVariables[detailBlock.varName] === detailBlock.alternatives[detailBlock.alternatives.length - 1]) {
            usedVariables[detailBlock.varName] = temporaryUserVariables[detailBlock.lastOptionSimpleInput.varName];
          } else {
            usedVariables[detailBlock.varName] = temporaryUserVariables[detailBlock.varName];
          }
        } else if(detailBlock.type === ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION) {
          // IF input is multiple selection, has "any last option -> write own reason" and last option selected, dont append last option but append the text in int
          if (detailBlock.lastOptionSimpleInput && temporaryUserVariables[detailBlock.varName].includes(detailBlock.alternatives[detailBlock.alternatives.length - 1].value)) {
            temporaryUserVariables[detailBlock.varName] = temporaryUserVariables[detailBlock.varName].filter((x: any) => x !== detailBlock.alternatives[detailBlock.alternatives.length - 1].value)
            usedVariables[detailBlock.lastOptionSimpleInput.varName] = temporaryUserVariables[detailBlock.lastOptionSimpleInput.varName];
          }
          usedVariables[detailBlock.varName] = temporaryUserVariables[detailBlock.varName].sort().join(",");
        }
      });
    }
    return usedVariables;
  }

  return (
    <div className="survey-detail-form" style={{display: isDetailVisible ? "flex" : "none"}}>
      <button onClick={(e) => {cancelUserVariables(e)}} className="cancel-button">
        <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
      </button>
      <form>
        <SurveyPageBuilder
          detailBlocks={survey.pages[pageIndex].detailBlocks}
          languageVariables={languageVariables}
          userVariables={temporaryUserVariables}
          setUserVariables={setTemporaryUserVariables}
          key={pageIndex}
        />
        {<div className="validation-message">{validationMessage}</div>}
        <div className="survey-detail-footer" style={{justifyContent: pageIndex !== 0 ? "space-between" : "flex-end"}}>
          <button onClick={(e) => {handleBackClick(e)}}
           style={{display: pageIndex !== 0 ? "block" : "none"}} >Zpět</button>
          <button onClick={e => handleNextClick(e)}>
          {pageIndex !== (survey.pages.length - 1) ? "Další" : "Odeslat"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SurveyBuilder