import React from "react";
import "./rewardItem.scss"


type Props = {
    linkText: string;
    text: string;
    link: string;
    imageLink: string;
}

const RewardItem = (props: Props) => {

    return (
        <li className="reward-item">
            <img className="reward-item-img" src={props.imageLink} />
            <div className="reward-item-text-wrapper">
                <a className="reward-item-link" href={props.link} target="_blank">{props.linkText}</a>
                <p className="reward-item-p">{props.text}</p>
            </div>
        </li>
    )
}

export default RewardItem;