import React from "react";
import "./badgeItem.scss"

interface BadgeItemProps {
  title: string;
  imageUrl: string;
  order: number;
  show: boolean; // condition for showing this badge
  onClick: any;
}

const BadgeItem = ({
  title,
  imageUrl,
  order,
  show,
  onClick,
}: BadgeItemProps) => {

  const iconFilePath = require(`./../../../assets/images/phoneApp/${imageUrl}`)

  return (
    <div className="badgeitem-wrapper" onClick={onClick}
        style={show ? {cursor: "pointer"} : {cursor: "initial"}}>
      <img className="badgeitem-img" src={iconFilePath} alt={imageUrl}/>
      <span className="badgeitem-title">{order}) {title}</span>
    </div>
  );
};

export default BadgeItem