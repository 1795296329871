import React, { useContext, useEffect, useRef, useState } from "react"
import { IPendingNotification } from "../../api/interfaces/replyNotification";
import TextEl from "../../common/components/textEl";
import { ListUtils } from "../../common/utils/listUtils";
import { ObjectUtils } from "../../common/utils/objectUtils";
import { DebugContext } from "../../context/debugProvider";
import { landingImages } from "./data/landingImages";
import { STATIC_IMAGES } from "../../assets/staticImages";
import getCheckpoints from "../../api/requests/getCheckpoints";
import { AuthContext } from "../../context/authProvider";
import loadCheckpoint from "../../api/requests/loadCheckpoint";
import { useHistory } from "react-router";
import deleteCheckpoint from "../../api/requests/deleteCheckpoint";
import createCheckpoint from "../../api/requests/createCheckpoint";
import { Link } from "react-router-dom";

interface IIntroProps {
  apiData: { [key: string]: any };
  userSummary: { [key: string]: any };
  setInSession: React.Dispatch<React.SetStateAction<boolean>>;
  restartSessionClick: () => void;
  skipSessionClick: () => void;
  pendingNotification: IPendingNotification | {};
  replyToNotification: (reply: boolean) => void;
}

const Intro = ({
  apiData,
  userSummary,
  setInSession,
  restartSessionClick,
  skipSessionClick,
  pendingNotification,
  replyToNotification
}: IIntroProps) => {

  const [isDebugMode, setIsDebugMode] = useContext(DebugContext)
  const [token, login, logout] = useContext(AuthContext);
  const [landingImageSrc, setLandingImageSrc] = useState<string>();
  const welcomePageTitleSessionAvailable = "Jsi připraven{{AdjEnd}} na dnešní sezení?"
  const welcomePageTextSessionAvailable = "Ahoj {{Name5p}}, jestli máš čas, klikni na tlačítko a posuneme se dále na tvé cestě k nekouření."
  const welcomePageTitleNoContext = "Ahoj {{Name5p}}!"
  const welcomePageTextNoContext = "Zatím nemám nic nového - připravené téma jsme už probrali. Mezitím můžeš zkusit využít tipy v sekci 'Má podpora'. Co nevidět se ti zase ozvu!"
  const welcomePagePendingNotificationTitle = "Jak se ti dnes vedlo?";

  const [checkpoints, setCheckpoints] = useState<any[]>([]);
  const [chosenCheckpointId, setChosenCheckpointId] = useState<number>();

  useEffect(() => {
    chooseLandingImage();

    getCheckpoints(token).then((x: any) => {setCheckpoints(x.checkpoints)})
  }, [])

  function chooseLandingImage() {
    const currentDate = new Date();
    const acceptableImages = landingImages.filter(x => 
      x.startTime < (`${currentDate.getHours()}:${currentDate.getMinutes()}`) &&
      x.endTime > (`${currentDate.getHours()}:${currentDate.getMinutes()}`) &&
      x.days.includes(currentDate.getDay().toString())
    ).map(x => x.image);
    const imgSrc = acceptableImages.length > 0 ? 
      ListUtils.getRandomListElement<string>(acceptableImages) : landingImages[0].image;
    setLandingImageSrc(require(`./../../assets/images/phoneApp/${imgSrc}.png`));
  }

  function loadCheckpointClick() {
    if(!chosenCheckpointId) return;
    loadCheckpoint(token, chosenCheckpointId).then(x => 
      window.location.reload()
    );
  }

  function createCheckpointClick() {
    createCheckpoint(token).then(x => 
      window.location.reload()
    );
  }

  function deleteCheckpointClick() {
    if(!chosenCheckpointId) return;
    deleteCheckpoint(token, chosenCheckpointId).then(x => 
      window.location.reload()
    );
  }

  return (
    <>
      {landingImageSrc != null ? <img src={landingImageSrc} alt="landinImage" className="appointment-intro-background-image"/> : "" }
      {!("extra" in pendingNotification) || !pendingNotification.extra.reply ?
        <>
          <h2><TextEl
            text={apiData.dead_end ? welcomePageTitleNoContext : welcomePageTitleSessionAvailable}
            apiVariables={userSummary.variables}
            presentVarNames={Object.keys(userSummary.variables)}
            key={apiData.dead_end ? welcomePageTitleNoContext : welcomePageTitleSessionAvailable}
          /></h2>
          <p className="appointment-intro-text">
            <TextEl
            text={apiData.dead_end ? welcomePageTextNoContext : welcomePageTextSessionAvailable}
            apiVariables={userSummary.variables}
            presentVarNames={Object.keys(userSummary.variables)}
            key={apiData.dead_end ? welcomePageTextNoContext : welcomePageTextSessionAvailable}
            />
          </p>
          {!apiData.dead_end ?
            <button className="intro-start-session-button" onClick={e => {setInSession(true);}}>Pojďme na to!</button>
            :
            <>
              <Link to="/home" className="appointment-intro-back-home-button">Zpět do přehledu</Link>
              {/* DEBUG MODE */}
              {isDebugMode === true ?
                <div className="debug-session-area" key={"debug: " + apiData + isDebugMode}>
                  <div className="debug-session-title">Debug Mode (episode: {apiData.title_admin}):</div>
                  <div className="debug-session-button-area">
                    <button onClick={e => {restartSessionClick()}}>Restart</button>
                    <button onClick={e => {skipSessionClick()}}>Další</button>
                  </div>
                  <div className="debug-session-title" style={{marginTop: "20px"}}>Checkpointy:</div>
                  {checkpoints.map(checkpoint => 
                    <button className={"debug-session-checkpoint " + (chosenCheckpointId === checkpoint.id ? "debug-session-checkpoint-chosen" : "")} 
                      onClick={e => setChosenCheckpointId(checkpoint.id)}>
                      {checkpoint.name + " " + checkpoint.created_at}
                    </button>
                  )}
                  <div className="debug-session-button-area">
                    <button onClick={e => {deleteCheckpointClick()}}>Smazat</button>
                    <button onClick={e => {loadCheckpointClick()}}>Načíst</button>
                    <button onClick={e => {createCheckpointClick()}}>Uložit</button>
                  </div>
                </div>
                : ""}
            </>
          }
        </>
      :
        <div className="pending-reply-notification">
          <h1>{welcomePagePendingNotificationTitle}</h1>
          {pendingNotification.content}
          <button className="button-container" onClick={e => replyToNotification(true)}>
            <img src={STATIC_IMAGES.okCheckmark} alt="okCheckmark" className="reply-notification-img"/>
            Ano, vydržel{userSummary.variables.AdjArch} jsem
          </button>
          <button className="button-container" onClick={e => replyToNotification(false)}>
            <img src={STATIC_IMAGES.noCross} alt="noCross" className="reply-notification-img"/>
            Ne, zapálil{userSummary.variables.AdjArch} jsem si
          </button>
        </div>}
    </>)
}

export default Intro;