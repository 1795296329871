import React, { useState } from "react"
import { IApiVariableValue } from "../../interfaces/apiVariableValue"
import TextEl from "./apTextEl";

interface IApMultipleSelectionAlternative {
  label: string;
  value: any;
}

interface ApMultipleSelectionInputElProps {
  labelText: string;
  varName: string;
  apiVariables: { [key: string]: IApiVariableValue };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>;
  alternatives: IApMultipleSelectionAlternative[];
  required?: boolean;
}

const ApMultipleSelectionInputEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  alternatives,
  required,
}: ApMultipleSelectionInputElProps) => {

  const [validationMessage, setValidationMessage] = useState<string>("");

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newApiVariables = {...apiVariables};
    const curValue = apiVariables[varName].value;
    // if not string, then it must be string[] but typescript doesnt have operator for type array?
    if(typeof curValue !== "string") {
      newApiVariables[varName].value = e.target.checked ?
        [...curValue, e.target.value] : curValue.filter((x: any) => x !== e.target.value);
    }
    handleValidity(newApiVariables);
    setApiVariables(newApiVariables);
  }

  function handleValidity(newApiVariables: { [key: string]: IApiVariableValue }) {
    const curValue = newApiVariables[varName];
    if (required && (curValue.value === "" || curValue.value === null || curValue.value?.length === 0)) {
      setValidationMessage("Vyber prosím alespoň jednu možnost");
      curValue.valid = false;
      return;
    }
    curValue.valid = true;
    setValidationMessage("");
  }

  return (
    <>
      <div className="multiplechoice">
        <div><strong><TextEl text={labelText} apiVariables={apiVariables}/></strong></div>
        {alternatives.map(alternative =>
          <div className="multiplechoice-alternative" key={varName + alternative.value}>
            <input id={varName + alternative.label} name={varName} type="checkbox" value={alternative.value}
              onChange={e => handleOnChange(e)}></input>
            <label htmlFor={varName + alternative.label}>
              <TextEl text={alternative.label} apiVariables={apiVariables}/>
            </label>
          </div>          
        )}
      </div>
      {validationMessage !== "" &&
        <div className="element-validation-message">{validationMessage}</div>}
    </>
  );
};

export default ApMultipleSelectionInputEl