import React, { Children } from "react";
import "./HomeWidgetWrapper.scss";
import { HashLink as Link } from 'react-router-hash-link';

type Props = 
{
    width: string;
    height: string;
    title: string;
    link: string;
    children: React.ReactNode;
}


const HomeWidgetWrapper = (props: Props) => {


    return (
        <>
        <div className="widget-wrapper" style={{ width: props.width, height: props.height }}>
            <div className="widget-top-area">
                <span className="widget-title">{props.title}</span>
                <Link to={props.link} className="widget-link">Více</Link>
            </div>
            <div className="widget-content-wrapper">
                {props.children}
            </div>
        </div>
        </>
    )
}

export default HomeWidgetWrapper;
