import { environment } from "../config/environment";

export const API_ROUTES = {
  userSummaryUrl: environment.currentServerUrl + "/api/user/summary/",
  userStatisticsUrl: environment.currentServerUrl + "/api/user/stats/",
  loginUrl: environment.currentServerUrl + "/api/auth/login/",
  simpleRegisterUrl: environment.currentServerUrl + "/api/auth/simple_register/",
  addLoginDetailsUrl: environment.currentServerUrl + "/api/auth/add-login/",
  evaluateExpressionUrl: environment.currentServerUrl + "/api/system/evaluate-expression/",
  sendPageVariablesUrl: environment.currentServerUrl + "/api/?next=1",
  setUserVariablesUrl: environment.currentServerUrl + "/api/user/variable/",
  getPageUrl: environment.currentServerUrl + "/api/",
  sendFeedbackUrl: environment.currentServerUrl + "/api/user/feedback/",
  restartUrl: environment.currentServerUrl + "/api/user/restart/",
  skipUrl: environment.currentServerUrl + "/api/user/next_step/",
  logoutUrl: environment.currentServerUrl + "/api/auth/logout/",
  getPaymentAmountUrl: environment.currentServerUrl + "/payments/api/amount",
  getPaymentInfoUrl: environment.currentServerUrl + "/payments/api/payment",
  getPendingReplyNotificationUrl: environment.currentServerUrl + "/api/reply/pending/",
  sentNotificationReplyUrl: environment.currentServerUrl + "/api/reply/receive/",
  deleteAccountUrl: environment.currentServerUrl + "/api/user/delete/",
  getCheckpointsUrl: environment.currentServerUrl + "/api/user/checkpoints/list",
  loadCheckpointUrl: environment.currentServerUrl + "/api/user/checkpoints/load",
  deleteCheckpointUrl: environment.currentServerUrl + "/api/user/checkpoints/delete",
  createCheckpointUrl: environment.currentServerUrl + "/api/user/checkpoints/create",
}