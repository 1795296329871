import React, { useContext, useEffect, useRef, useState } from "react";
import "./help.scss"

import sendFeedback from "../../api/requests/sendFeedback";
import { IFeedbackType } from "./interfaces/feedbackInfo";
import Hints from "../common/hints";
import PageWrapper from "../../common/components/pageWrapper";
import NavBar, { NavBarItem } from "../../common/navbar/navBar";
import { ListUtils } from "../../common/utils/listUtils"
import { tasteTipsTexts } from "./data/tasteTipsTexts";
import { AuthContext } from "../../context/authProvider";
import { DebugContext } from "../../context/debugProvider";
import { Link } from "react-router-dom";
import { STATIC_IMAGES } from "../../assets/staticImages";
import getCertainVariables from "../../api/requests/getCertainVariables";
import RandomTip from "./RandomTip";
import FeedbackFormular from "../../common/components/FeedbackFormular";

const Help = () => {

    //Hint text string + other props
    const hint = "Tady najdeš rychlou pomoc, pokud se při odvykání ocitneš v úzkých. Náhlá chuť na cigaretu tě pak už nepřekvapí.";
    let showHint:boolean = false;

    {/*//Copy paste section*/}
    //Phase variables
    const [token, login, logout] = useContext(AuthContext);

    //Popup windows init
    const aboutWindow = useRef<HTMLDivElement>(null);
    const tipWindow = useRef<HTMLDivElement>(null);
    const feedbackWindow = useRef<HTMLDivElement>(null);

    //Debug-related consts init
    const [debugCounter, setDebugCounter] = useState<number>(0);
    const [isDebugMode, setIsDebugMode] = useContext(DebugContext);

    //Feedback-related consts init
    const [feedback, setFeedback] = useState<string>("Vyplň text...")
    const [feedbackIsBug, setFeedbackIsBug] = useState<boolean>(false)
    const [feedbackValidation, setFeedbackValidation] = useState<string>("")
    const [phaseVariable, setPhaseVariable] = useState<string>()

    //function for making popup windows invisible/visible
    const toggleWindow = (window: HTMLDivElement | null) => {
        if (window) {
          if (window.style.display !== "none") {
            window.style.display = "none";
          } else {
            window.style.display = "flex";
          }
        }
        setDebugCounter(0);
      }

    //function for sending feedback  
    function sendFeedbackClick() {
        if(feedback === "") {
            setFeedbackValidation("Pro odeslání nesmí být pole pro feedback prázdné.")
            return;
        }
        setFeedbackValidation("");
        toggleWindow(feedbackWindow.current);
        // TODO: chodi chodi na REAL email administratorum i v DEBUG modu, tak to radsi zatim vypnu
        sendFeedback({feedback: feedback, type: feedbackIsBug ? IFeedbackType.BUG : IFeedbackType.FEEDBACK }, token);
        setFeedback("");
    }

    //Feth phase variables
    useEffect(() => {
        fetchPhaseVariables();
      }, [])

      function fetchPhaseVariables() {
        getCertainVariables(["PHASE"], token).then((x: any) => {
          if(x?.variables) {
            setPhaseVariable(x.variables["PHASE"]);
          }
        })
      }
    //End of copy paste section

    return (
        <PageWrapper>
            <div className="help-wrapper">
                <NavBar chosenItem={NavBarItem.Help}></NavBar>
                <div className="help-content">
                    <h1 className="help-title">Moje podpora</h1>
                    <div className="help-content-panels">
                        <div className="help-content-panel-1">
                            <img className="help-background-image" src={STATIC_IMAGES.help_background_image}/>
                            <Hints text={hint} order={1} show={showHint} />
                        </div>
                        <div className="help-content-panel-2">
                            {/* TODO dodělat SOS help se special zobrazenim viz puvodni verze v help_OLD */}
                            <ul className="help-options-list"> {/*TODO smazat style=display none, aby bylo videt i horni nabidka s nahodnym tipem apod. */}
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.cigarette} alt="cigarette"/>
                                    <button onClick={(e) => {toggleWindow(tipWindow.current)}}><span className="help-li-text">Mám chuť na cigaretu</span></button>
                                </li>
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.conversation} alt="conversation"/>
                                    {/*<button><span className="help-li-text">Adiquit buddy - zatím není implementováno</span></button>*/}
                                    <a href={"https://twitter.com/AdiquitTo"} target="_blank"><span className="help-li-text">Chci sdílet své zkušenosti</span></a>
                                </li>
                            </ul>

                            {/* TODO konec sekce sos help*/}
                            <ul className="help-options-list">
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.community} alt="community"/> 
                                    <a href={"https://www.facebook.com/Adiquithelps"} target="_blank"><span className="help-li-text">Moje komunita</span></a>
                                </li>
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.blog} alt="blog"/>
                                    <a href={"https://www.adiquit.cz/blog/"} target="_blank"><span className="help-li-text">Adamův blog</span></a>
                                </li>
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.back} alt="back"/>
                                    <Link to="/appointment/readonly"><span className="help-li-text">Zobrazit poslední obsah</span></Link>
                                </li>
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.pen} alt="pen"/>
                                    <button onClick={(e) => {toggleWindow(feedbackWindow.current)}}><span className="help-li-text">Zpětná vazba</span></button>
                                </li>
                                <li>
                                    <img className="help-li-img" src={STATIC_IMAGES.info} alt="info"/>
                                    <button onClick={(e) => {toggleWindow(aboutWindow.current)}}><span className="help-li-text">O aplikaci</span></button>
                                </li>
                            </ul>                
                        </div>
                    </div>

                    {/*Window for "about" button */}
                    <div ref={aboutWindow} className="help-about-window" style={{display: "none"}}>
                        <div className="help-about-header">
                            <img src={STATIC_IMAGES.logo} id="about-logo" alt="logo" onClick={e => {
                                if(debugCounter > 6) {
                                    setIsDebugMode(true);
                                    alert("debug mode nastaven");
                                }
                                setDebugCounter(debugCounter + 1);
                            }}/>
                            <button onClick={(e) => {toggleWindow(aboutWindow.current)}} className="cancel-button">
                                <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
                            </button>
                        </div>
                        <p>Adiquit je vědecky ověřený, interaktivní mobilní asistent, který pomáhá kuřákům během procesu odvykání. Na rozdíl od osobního poradenství nebo neosobních aplikací na odvykání kouření, Adiquit je terapeut ve tvé kapse – dostupný tehdy, kdy ho potřebuješ nejvíc.<br></br>
                        Aplikace Adiquit byla vyvinuta předními odborníky na léčbu závislosti na tabáku. Intenzivní léčebný program založený na klinicky ověřených postupech poskytuje kuřákům každodenní podporu a odbornou pomoc až po dobu několika měsíců. Efektivita léčebného programu byla prokázána na výzkumných studiích mezi kuřáky. V porovnání s odvykáním bez odborné pomoci je úspěšnost Adiquit více než 6x vyšší.</p>
                        <div className="help-about-links">
                            <a className="help-about-link" href="https://www.adiquit.cz/" target="_blank">Více informací</a>
                            <a className="help-about-link" href="https://www.adiquit.cz/obchodni-podminky/" target="_blank">Obchodní podmínky</a>
                            <a className="help-about-link" href="https://www.adiquit.cz/ochrana-osobnich-udaju/" target="_blank">Ochrana osobních údajů</a>
                        </div>
                    </div>

                    {/* Window for random tip for resisting tempattion */}
                    <div ref={tipWindow} className="help-tip-window" style={{display: "none"}}>
                        <button onClick={(e) => {toggleWindow(tipWindow.current)}} className="cancel-button">
                            <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
                        </button>
                        <RandomTip next_tip={true}></RandomTip>
                    </div>

                    {/* Window for sending feedback */}
                    <div ref={feedbackWindow} className="help-feedback-window" style={{display: "none"}}>
                        <div className="help-feedback-header">
                            <span className="help-feedback-heading">Jak jsi s aplikací spokojený?</span>
                            <button onClick={(e) => {toggleWindow(feedbackWindow.current)}} className="cancel-button">
                                <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
                            </button>
                        </div>
                        <textarea required onChange={e => setFeedback(e.target.value)} value={feedback}>Text...</textarea>
                        {feedbackValidation  === "" ? "" : <span className="validation-message">{feedbackValidation}</span>}
                        <div className="help-feedback-isbug">
                            <input type="checkbox" id="feedback-checkbox" name="feedback-checkbox"
                            onChange={e => {setFeedbackIsBug(e.target.checked)}}/>
                            <label htmlFor="feedback-checkbox">Jedná se o chybu</label>
                        </div>
                        <div className="help-feedback-buttons">
                            <button onClick={(e) => {toggleWindow(feedbackWindow.current)}}>Zrušit</button>
                            <button onClick={e => sendFeedbackClick()}>Odeslat</button>
                        </div>
                    </div>
                </div>
                <FeedbackFormular formular_src="https://docs.google.com/forms/d/e/1FAIpQLSeKEy0HuhDzf6A0SrivkyqjPzyLiPTYZic3xl083pWUkqiwlw/viewform?embedded=true" />
            </div>
        </PageWrapper>
    );
}

export default Help;