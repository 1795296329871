import { STATIC_IMAGES } from "../../../assets/staticImages";
import { ISurvey } from "../interface/survey";
import { ISurveyDetailBlockType, ISurveyDetailImageBlock, ISurveyDetailInputBlock } from "../interface/surveyDetailBlock";

export const surveyPrice: ISurvey = {
  title: "Vyplň cenu své oblíbené krabičky cigaret",
  img: STATIC_IMAGES.money2,
  successVarName: "profileStrength_price",
  showPredicate: (val: any) => val == null,
  pages: [
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_onboarding_9.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_NUMBER,
          text: "Řekni mi, kolik cigaret máš v krabičce...",
          placeholder: "Ks",
          varName: "onboarding_PackSize",
        } as ISurveyDetailInputBlock,
        {
          type: ISurveyDetailBlockType.INPUT_NUMBER,
          text: "... a kolik za ní většinou platíš",
          placeholder: "Czk",
          varName: "onboarding_PackPriceAmount",
        } as ISurveyDetailInputBlock,
      ]
    },
  ],
}