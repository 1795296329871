import React from 'react';
import { Redirect, Route } from "react-router";
import Login from './pages/login/login';
import Home from './pages/home/home';
import Onboarding from './pages/onboarding/onboarding';
import Success from './pages/success/success';
import Appointment from './pages/appointment/appointment';
import Plan from './pages/plan/plan';
import Profil from './pages/profil/profil';
import { BrowserRouter } from 'react-router-dom';
import Help from './pages/help/help';
import LoggedRoute from './common/components/loggedRoute';

const routes = (
  <>
    <BrowserRouter>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      {/* Parameter for enabling new message after successful registration */}
      <Route path="/login/:parameter?" component={Login} />
      <Route path="/onboarding" component={Onboarding} />
      {/* Parameter for splitting page which comes from "zobrazit posledni obsah" */}
      <LoggedRoute path="/appointment/:parameter?" Component={Appointment} />
      <LoggedRoute path="/profil" Component={Profil} />
      <LoggedRoute path="/home" Component={Home}/>
      <LoggedRoute path="/success" Component={Success} />
      <LoggedRoute path="/plan" Component={Plan} />
      <LoggedRoute path="/help" Component={Help} />
    </BrowserRouter>
  </>
)

export default routes;