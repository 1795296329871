import React from "react";

interface ApButtonElProps {
  text: string;
  onClick: any;
  readonly: boolean;
}

const ApButtonEl = ({
  text,
  onClick,
  readonly,
}: ApButtonElProps) => {

  return (
    <button className="button-next-page" disabled={readonly} onClick={e => {onClick()}}>{text}</button>
  );
};

export default ApButtonEl