import { API_ROUTES } from "../apiRoutes";

function getCertainVariables(askedVariables: string[], token: string): Promise<{} | undefined> {
  const requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Token " + token },
  };
  let urlUserVariables = API_ROUTES.setUserVariablesUrl + "?";
  for (const varName of askedVariables) {
    urlUserVariables += `&variables=${varName}`
  }
  return  fetch(urlUserVariables, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return new Promise<{}>(resolve => resolve(data));
    }).catch(e => {
      return new Promise<undefined>(resolve => resolve(undefined));
    })
}

export default getCertainVariables;