import React from "react";

interface ApImageElProps {
  srcUrl: string;
  title: string;
  customClassName: string;
}

const ApImageEl = ({
  srcUrl,
  title,
  customClassName,
}: ApImageElProps) => {

  return (
    <img src={srcUrl} alt={title}
     className={customClassName === "" ? "app-image" : customClassName} />
  );
};

export default ApImageEl