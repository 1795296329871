import React from "react";
import ApTextEl from "./buildingBlocks/apTextEl";
import ApImageEl from "./buildingBlocks/apImageEl";
import ApButtonEl from "./buildingBlocks/apButtonEl";
import ApSimpleInputEl, { ApSimpleInputType } from "./buildingBlocks/apSimpleInputEl";
import ApMultipleChoiceInputEl from "./buildingBlocks/apMultipleChoiceInputEl";
import ApMultipleSelectionInputEl from "./buildingBlocks/apMultipleSelectionInputEl";
import ApToggleEl from "./buildingBlocks/apToggleEl";
import ApToggleSetEl from "./buildingBlocks/apToggleSetEl";
import ApToggleSetMultiEl from "./buildingBlocks/apToggleSetMultiEl";
import { ObjectUtils } from "../../../common/utils/objectUtils";
import { IApiVariableValue } from "../interfaces/apiVariableValue";
import { IExpressionCacheValue } from "../interfaces/expressionCacheValue";
import { ICacheableDynamicElement } from "../interfaces/cacheableDynamicElement";
import { environment } from "../../../config/environment";

export class BuildingBlocksFactory {

  private readonly regexParsingVariablesFromText = new RegExp("{{[a-zA-Z_$0-9]*}}", "g")
 
  private elementList: ICacheableDynamicElement[];
  private apiVariables: { [key: string]: IApiVariableValue };
  private toggleVariables: { [key: string]: boolean };
  private setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>;
  private setToggleVariables: React.Dispatch<React.SetStateAction<{[key: string]: boolean}>>;
  private expressionCache: {[key: string]: IExpressionCacheValue};
  private isDeadEnd: boolean; // is end of appointment? (last page)
  private sendAnswer: () => void;
  private readonly: boolean; // is it redirected from "Zobrazit posledni obsah"

  constructor(elementList: ICacheableDynamicElement[],
    apiVariables: { [key: string]: IApiVariableValue}, toggleVariables: { [key: string]: boolean },
    setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>,
    setToggleVariables: React.Dispatch<React.SetStateAction<{[key: string]: boolean}>>,
    expressionCache: {[key: string]: IExpressionCacheValue},
    isDeadEnd: boolean, sendAnswer: () => void, readonly: boolean) {

    this.elementList = elementList;
    this.apiVariables = apiVariables;
    this.toggleVariables = toggleVariables;
    this.setApiVariables = setApiVariables;
    this.setToggleVariables = setToggleVariables;
    this.expressionCache = expressionCache;
    this.isDeadEnd = isDeadEnd;
    this.sendAnswer = sendAnswer;
    this.readonly = readonly;
  }

  public buildBlock(element: any, index: number): ICacheableDynamicElement {
    if(element.content === "" || element.content?.length === 0) {
      return {element: <div key={index}></div>, updateNeeded: false};
    }
    if (this.elementList !== undefined && this.elementList.length > 0 && !this.elementList[index].updateNeeded) {
      if(element.content_type ===  "text")
      return this.elementList[index];
    }
    switch(element.content_type) {
      case "text":
        return this.buildTextEl(element, index, this.apiVariables, this.elementList[index]?.updateNeeded);
        break;
      case "image":
        return this.buildImageEl(element, index);
        break;
      case "conditionalset":
        return this.buildConditionalSetEl(element, index, this.apiVariables, this.expressionCache);
        break;
      case "form":
        return this.buildFormEl(element, index, this.apiVariables, this.setApiVariables);
        break;
      case "button text":
        return this.buildButtonEl(element, index, this.isDeadEnd, this.sendAnswer,
           this.readonly || this.isAnyApiVariableNonValid(this.apiVariables));
        break
      case "toggle":
        return this.buildToggleEl(element, this.apiVariables, this.toggleVariables, this.setToggleVariables);
        break;
      case "toggleset":
        return this.buildToggleSetEl(element, index, this.apiVariables, this.setApiVariables);
        break;
      case "togglesetmulti":
        return this.buildToggleSetMultiEl(element, index, this.apiVariables, this.setApiVariables);
        break;
      // case "quiz", "video", "audio", "file": NOT CONSIDERED (not in any appointment)
      default:
        return {element: <div key={index}></div>, updateNeeded: false};
    }
  }

  public isAnyApiVariableNonValid(apiVariables: { [key: string]: IApiVariableValue }): boolean {
    // Check if user can go to the next page, if all inputs are valid
    return Object.keys(apiVariables).some(x => !apiVariables[x].valid)
  }

  public buildToggle(element: any, index: number) {
    if(element.content === "" || element.content?.length === 0) {
      return {element: <div key={index}></div>, updateNeeded: false};
    }
    if (element.content_type === "toggle") {
      return this.buildToggleEl(element, this.apiVariables, this.toggleVariables, this.setToggleVariables);
    } else {
      return this.elementList[index];
    }
  }

  private buildTextEl(element: any, index: number, apiVariables: { [key: string]: IApiVariableValue}, updateNeeded: boolean) {
    let result;
    if (ObjectUtils.isEmpty(element.variables)) {
      result = <ApTextEl
        text={element['content']} apiVariables={{}} key={index}
      />
    } else {
      updateNeeded = updateNeeded ?? this.isTextUpdateNeeded(element.content, apiVariables)
      result = <ApTextEl
        text={element.content} apiVariables={apiVariables} key={index}
      />
    }
    // UpdateNeeded was attempt to make elements without two way binding cachable so they dont have to rerende,
    // but it was hard to do it right. So far react seems to be fast enough without. 
    return {element: result, updateNeeded: true}
  }

  private buildImageEl(element: any, index: number): ICacheableDynamicElement {
    const imageSrcUrl = environment.serverUrl + "/" + element.content.url;
    return {
      element: <ApImageEl
        srcUrl={imageSrcUrl}
        title={element.content.title}
        customClassName={element.content.containerClass}
        key={index}
      />, updateNeeded: false
    }
  }

  private buildConditionalSetEl(element: any, index: number, apiVariables: { [key: string]: IApiVariableValue},
    expressionCache: {[key: string]: {result: boolean | undefined, sent: boolean}}): ICacheableDynamicElement {  
    let result = []
    for (let condition of element.content) {
      // Create empty element in place of empty ones
      if(expressionCache[condition.expression] == null || expressionCache[condition.expression].result == null) {
        return {
          element: <div key={index}></div>,
          updateNeeded: true
        }
      }
    }
    // Check if condition should be displayed, if yes, push them
    for (let condition of element.content) {
      if(condition.content === "") {
        continue;
      }
      if(expressionCache[condition.expression]?.result) {
        result.push(condition.content);
      }
    }

    return {
      element: <div key={index + " " + result.length}>{result.map((expText: string) => 
        <ApTextEl text={expText} apiVariables={apiVariables} key={expText}/>
        )}</div>,
      updateNeeded: true
    }
  }

  private buildFormEl(element: any, index: number, apiVariables: { [key: string]: IApiVariableValue},
    setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>): ICacheableDynamicElement {
    let result = [];
    for (let formPart of element.content) {
      switch(formPart.field_type) {
        case "string":
          result.push(<ApSimpleInputEl
            varName={formPart.variable_name} labelText={formPart.label}
            apiVariables={apiVariables} setApiVariables={setApiVariables}
            inputType={ApSimpleInputType.TEXT} key={formPart.variable_name}
            required={formPart.required}
          />);
          break;
        case "multiplechoice":
          result.push(<ApMultipleChoiceInputEl 
            labelText={formPart.label}
            apiVariables={apiVariables}
            varName={formPart.variable_name}
            setApiVariables={setApiVariables}
            alternatives={formPart.alternatives}
            key={formPart.variable_name}
            required={formPart.required}
          />);
          break;
        case "multipleselection":
          if (apiVariables[formPart.variable_name].value === "") {
            apiVariables[formPart.variable_name].value = [];
          }
          result.push(<ApMultipleSelectionInputEl
            labelText={formPart.label}
            apiVariables={apiVariables}
            varName={formPart.variable_name}
            setApiVariables={setApiVariables}
            alternatives={formPart.alternatives}
            key={formPart.variable_name}
            required={formPart.required}
          />);
          break;
        case "numeric":
          result.push(<ApSimpleInputEl
            varName={formPart.variable_name} labelText={formPart.label}
            apiVariables={apiVariables} setApiVariables={setApiVariables}
            inputType={ApSimpleInputType.NUMERIC} key={formPart.variable_name}
            required={formPart.required}
          />);
          break;
        case "text":
          result.push(<ApSimpleInputEl
            varName={formPart.variable_name} labelText={formPart.label}
            apiVariables={apiVariables} setApiVariables={setApiVariables}
            inputType={ApSimpleInputType.TEXTAREA} key={formPart.variable_name}
            required={formPart.required}
          />);
          break;
        case "email":
          result.push(<ApSimpleInputEl
            varName={formPart.variable_name} labelText={formPart.label}
            apiVariables={apiVariables} setApiVariables={setApiVariables}
            inputType={ApSimpleInputType.EMAIL} key={formPart.variable_name}
            required={formPart.required}
          />);
          break;
        case "password":
          result.push(<ApSimpleInputEl
            varName={formPart.variable_name} labelText={formPart.label}
            apiVariables={apiVariables} setApiVariables={setApiVariables}
            inputType={ApSimpleInputType.PASSWORD} key={formPart.variable_name}
            required={formPart.required}
          />);
          break;

      }
    }    
    return {
      element: <div key={index}>{result.map(formEl => formEl)}</div>,
      updateNeeded: true
    }
  }

  private buildToggleEl(element: any, apiVariables: { [key: string]: IApiVariableValue},
    toggleVariables: { [key: string]: boolean },
    setToggleVariables: React.Dispatch<React.SetStateAction<{[key: string]: boolean}>>): ICacheableDynamicElement {
    // Creates own variables for toggles so they dont mix up with variables from server
    if (!(element.toggle in toggleVariables)) {
      toggleVariables[element.toggle] = false
    }
    let updateToggleNeeded, updateContentNeeded;
    // Check for two way binding in toggle elements
    updateToggleNeeded = this.isTextUpdateNeeded(element.toggle, apiVariables);
    updateContentNeeded = this.isTextUpdateNeeded(element.content, apiVariables);
    const imgSrc = element.img_content.url !== "" ? environment.serverUrl + "/" + element.img_content.thumbnail : "";
    return {
      element: <ApToggleEl
        toggleText={element.toggle} contentText={element.content}
        imgTitle={imgSrc} toggleVarName={element.toggle}
        toggleVariables={toggleVariables} setToggleVariables={setToggleVariables}
        apiVariables={apiVariables} key={element.toggle}
      />,
      updateNeeded: updateToggleNeeded || updateContentNeeded
    }
  }

  private buildToggleSetEl(element: any, index: number, apiVariables: { [key: string]: IApiVariableValue},
    setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>
    ): ICacheableDynamicElement {
    return {
      element: <ApToggleSetEl
        labelText={element.content.label} varName={element.content.variable_name}
        apiVariables={apiVariables} setApiVariables={setApiVariables}
        alternatives={element.content.alternatives} key={"TGS" + (index)}
        required={true}
      />,
      updateNeeded: true
    }
  }

  private buildToggleSetMultiEl(element: any, index: number, apiVariables: { [key: string]: IApiVariableValue},
    setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>
    ): ICacheableDynamicElement {
    return {
      element: <ApToggleSetMultiEl
        labelText={element.content.label} varName={element.content.variable_name}
        apiVariables={apiVariables} setApiVariables={setApiVariables}
        alternatives={element.content.alternatives} key={"TGSM" + (index)}
        required={true}
      />,
      updateNeeded: true
    }
  }

  private buildButtonEl(element: any, index: number, isDeadEnd: boolean, sendAnswer: () => void, readonly: boolean): ICacheableDynamicElement {
    // Decide text for button element
    let buttonText = isDeadEnd ? "Hotovo" : "Další stránka";
    if (element.content !== "") { buttonText = element.content; }
    return {
      element: <ApButtonEl text={buttonText} onClick={sendAnswer} readonly={readonly} key={"BTN" + (index)}/>,
      updateNeeded: true
    }
  }

  private isTextUpdateNeeded(parsedText: string, apiVariables: { [key: string]: IApiVariableValue}): boolean {
    // Check if this variable is also displayed on this page (two-way-binding)
    const updateNeeded = this.regexParsingVariablesFromText.exec(parsedText)?.some(x => 
      apiVariables[x.substring(2,x.length-2)].dynamicInputBinded
    ) ?? false
    return updateNeeded;
  }

}