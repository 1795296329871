import { ISimpleRegisterLogin } from "../../pages/onboarding/interfaces/simpleRegisterLogin";
import { API_ROUTES } from "../apiRoutes";

function addLoginDetails(loginDetails: ISimpleRegisterLogin, tempToken: string): Promise<any> {
  return fetch(API_ROUTES.addLoginDetailsUrl,
    {
      body: JSON.stringify(loginDetails),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + tempToken,
        "Accept-Language": "cs",
      }
    })
    .then(responseLogin => {
      return responseLogin.json()
    })
    .then(dataLogin => {
      return new Promise<boolean>(resolve => {resolve(dataLogin);});
    })
    .catch(e => {
      return new Promise<boolean>(resolve => {resolve(false);});
    })
}

export default addLoginDetails;