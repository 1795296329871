import React, { useState } from "react"
import SimpleInputEl, { SimpleInputType } from "./simpleInputEl";
import TextEl from "./textEl";

interface IMultipleChoiceAlternative {
  label: string;
  value: any;
}

interface MultipleChoiceInputElProps {
  labelText: string;
  varName: string;
  apiVariables: { [key: string]: string };
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: string}>>;
  alternatives: IMultipleChoiceAlternative[];
  required?: boolean;
  lastOptionSimpleInput?: {
    labelText?: string;
    inputType: SimpleInputType;
    varName: string;
  }
}

const MultipleChoiceInputEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  alternatives, // all chekable options
  required,
  lastOptionSimpleInput, // if user can put his own text after checking last option 
}: MultipleChoiceInputElProps) => {

  const [validationMessage, setValidationMessage] = useState<string>("");

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newApiVariables = {...apiVariables};
    newApiVariables[varName] = e.target.value;
    handleValidity(newApiVariables);
    setApiVariables(newApiVariables);
  }

  function handleValidity(newApiVariables: { [key: string]: any }) {
    const curValue = newApiVariables[varName];
    if (required && (curValue === null || curValue?.length === "" ||
      (lastOptionSimpleInput && curValue === alternatives[alternatives.length - 1].value &&
        newApiVariables[lastOptionSimpleInput.varName] === ""))) {
      setValidationMessage("Vyber prosím alespoň jednu možnost");
      return;
    }
    setValidationMessage("");
  }

  return (
    <div className="multiplechoice">
      <div className="multiplechoice-title"><strong><TextEl text={labelText} presentVarNames={Object.keys(apiVariables)} apiVariables={apiVariables}/></strong></div>
      {alternatives.map((alternative, index) =>
        <div className="multiplechoice-alternative" key={varName + alternative.value}>
          <input id={varName + alternative.label} name={varName} type="radio" value={alternative.value} checked={apiVariables[varName] === alternative.value}
            onChange={e => handleOnChange(e)}></input>
          <label htmlFor={varName + alternative.label}>
            <TextEl text={alternative.label} presentVarNames={Object.keys(apiVariables)} apiVariables={apiVariables}/>
          </label>
        </div>
      )}
      {/* If user checks last option, simple input will appear. */}
      {lastOptionSimpleInput && apiVariables[varName] === (alternatives[alternatives.length - 1].value) &&
        <SimpleInputEl
          apiVariables={apiVariables}
          inputName={lastOptionSimpleInput.varName}
          inputType={lastOptionSimpleInput.inputType}
          setApiVariables={setApiVariables}
          labelText={lastOptionSimpleInput.labelText}
          cssClassName={"lastOptionSimpleInput"}
        />}
      {validationMessage !== "" &&
        <div className="element-validation-message">{validationMessage}</div>}
    </div>
  );
};

export default MultipleChoiceInputEl