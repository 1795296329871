import { API_ROUTES } from "../apiRoutes";
import { IFeedbackInfo } from "../../pages/help/interfaces/feedbackInfo";

function sendFeedback(feedbackInfo: IFeedbackInfo, token: string): Promise<boolean> {
  return fetch(API_ROUTES.sendFeedbackUrl,
    {
      body: JSON.stringify(feedbackInfo),
      method: "POST",
      headers: { "Authorization": "Token " + token, "Content-Type": "application/json" },
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return new Promise<boolean>(resolve => resolve(true))
    })
    .catch(e => {
      return new Promise<boolean>(resolve => resolve(false))
    }
  )
}

export default sendFeedback;