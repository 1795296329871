import React, { useState } from "react"
import { STATIC_IMAGES } from "../../assets/staticImages";
import "./profilName.scss"

interface IProfilNameProps {
  userName: string;
  userSex: boolean;
  changeName: (newName: string) => void;
}

const ProfilName = ({userName, userSex, changeName}: IProfilNameProps) => {

  const [name, setName] = useState<string>(userName);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  function handleChangeName() {
    if(name === "") {
      setValidationMessage("Nenech pole prázdné.")
      return;
    }
    setValidationMessage("");
    setSuccessMessage("Úspěšně změněno!")
    changeName(name);
  }
  
  return (
    <div className="profil-name-wrapper">
      <div className="profil-name-column-1">
        <span className="profil-name-title">Říkej mi...</span>
        <input className="profil-name-input" onChange={e => {
          setName(e.target.value);
          if(successMessage !== "") {
            setSuccessMessage("")
          }
        }} value={name}></input>
        <div className="profil-name-footer">
          {successMessage !== "" ? 
            <span className="success-message" key={successMessage}>{successMessage}</span> :
            <span className="validation-message">{validationMessage}</span>}
        </div>
        <button className="profil-name-button" onClick={e => handleChangeName()}>Uložit</button>
      </div>
      <div className="profil-name-column-2">
        {userName != null && (
          userSex
            ? <img className="profil-name-img" src={STATIC_IMAGES.man} alt="man"/>
            : <img className="profil-name-img" src={STATIC_IMAGES.woman} alt="woman"/>
        )}
      </div>
    </div>
  )
}

export default ProfilName;