import React, { useState } from "react";
import { STATIC_IMAGES } from "../../../assets/staticImages";
import TextEl from "../../../common/components/textEl";
import { PaymentArgumentsType } from "./../data/paymentArguments";
import { IPaymentArgumentPage } from "../interfaces/paymentArgumentPage";
import PaymentArgumentFeedback from "./paymentArgumentFeedback";


interface IPaymentArgumentPageProps {
  paymentArgument: IPaymentArgumentPage;
  cancel: (windowRefEl: HTMLDivElement | null) => void;
  changePaymentArgs: (newIndex: number) => void;
  paymentArgIndex: number;
  apiVariables: { [key: string]: any };
  windowRefEl: React.RefObject<HTMLDivElement>;
  goPayUrl: string | undefined;
}

const PaymentArgument = ({
  paymentArgument,
  cancel,
  changePaymentArgs,
  paymentArgIndex,
  apiVariables,
  windowRefEl,
  goPayUrl,
}: IPaymentArgumentPageProps) => {

  const iconFile = require(`./../../../assets/images/phoneApp/${paymentArgument?.imgUrl ?? "adiquit_icon_locked_content"}.png`);
  const initFeedbackVariables: {[key: string]: string | string[]} = {
    "paymentArgsFeedback": [],
    "paymentFeedbackOwn": "",
    ...apiVariables
  }
  
  const [feedbackVariables, setFeedbackVariables] = useState< {[key: string]: string | string[]} >(initFeedbackVariables);
  const [feedbackValidation, setFeedbackValidation] = useState<string>();

  function sendFeedback() {
    console.log("feedback: ", feedbackVariables["paymentArgsFeedback"], feedbackVariables["paymentFeedbackOwn"])
    if(feedbackVariables["paymentArgsFeedback"].length === 0  && feedbackVariables["paymentFeedbackOwn"] === "") {
      setFeedbackValidation("Vyplň prosím aspoň jeden důvod");
      return;
    }
    changePaymentArgs(8);
  }

  return (
    <React.Fragment>
      <div className="payment-argument-content">
        <button onClick={(e) => {cancel(windowRefEl.current)}} className="cancel-button">
          <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
        </button>
        {paymentArgument.imgUrl &&
          <img src={iconFile} alt={paymentArgument?.imgUrl}/>}
        <h2>{paymentArgument.title}</h2>
        <div className="payment-argument-form-text">
          {paymentArgIndex !== 7 ?
            <TextEl apiVariables={apiVariables}
              presentVarNames={Object.keys(apiVariables)}
              text={paymentArgument.text}/>
            :
            <PaymentArgumentFeedback
              apiVariables={feedbackVariables}
              setApiVariables={setFeedbackVariables}
            />
          }
        </div>
        {/* Decides which middle button will be rendered */}

          {paymentArgument.type === PaymentArgumentsType.NORMAL && paymentArgIndex !== 6 &&
            <a className="payment-argument-pay-button" href={goPayUrl}>
              Zaplať za terapii
            </a>}
          {paymentArgIndex === 6 &&
            <button className="payment-argument-pay-button" onClick={e => changePaymentArgs(7)}>
              Napsat, co mi vadí
            </button>}
          {paymentArgIndex === 7 &&
            <>
              <span className="feedback-validation">{feedbackValidation}</span>
              <button className="payment-argument-pay-button" onClick={e => sendFeedback()}>
                Odeslat
              </button>
            </>}          
          {(paymentArgIndex === 8 || paymentArgIndex === 9) &&
            <button className="payment-argument-pay-button" onClick={e => cancel(windowRefEl.current)}>
              Odložit terapii
            </button>}

        <div className={"payment-argument-control-area " + (paymentArgIndex === 9 || paymentArgIndex === 8 ? " none" : "")}>
          <button onClick={e => changePaymentArgs(paymentArgIndex - 1)} className={paymentArgIndex === 0 ? "hidden" : ""}>
            {paymentArgIndex !== 0 &&
              <>Zpět</>
            }
          </button>
          {/* Decide which next button will render  */}
          
            {paymentArgIndex === 6 &&
              <button onClick={e => changePaymentArgs(9)}>
                Nechci pokračovat
              </button>}
            {paymentArgIndex === 7 &&
              <button onClick={e => changePaymentArgs(paymentArgIndex + 1)}>
                Přeskočit a skončit
              </button>}
            {paymentArgIndex !== 6 && paymentArgIndex !== 7 &&
              <button onClick={e => changePaymentArgs(paymentArgIndex + 1)}>
                Další důvod
              </button>}
              
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentArgument