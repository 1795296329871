import { API_ROUTES } from "../apiRoutes";

function getPaymentAmout(token: string, code: string): Promise<{}> {
  const requestOptions = {
    body: JSON.stringify({code}),
    method: 'POST',
    headers: {
      "Authorization": "Token " + token,
      "Content-Type": "application/json",
      "Accept-Language": "cs"
    },
  };

  return  fetch(API_ROUTES.getPaymentAmountUrl, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return new Promise<{}>(resolve => resolve(data));
    }).catch(e => {
      return new Promise<{}>(resolve => resolve({}));
    })
}

export default getPaymentAmout;