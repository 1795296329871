import React, { useContext, useEffect, useState } from "react"
import deleteAccount from "../../api/requests/deleteAccount";
import getCertainVariables from "../../api/requests/getCertainVariables";
import getUserSummary from "../../api/requests/getUserSummary";
import { IUserSummary } from "../../api/interfaces/userSummary";
import sendUserVariables from "../../api/requests/sendUserVariables";
import { STATIC_IMAGES } from "../../assets/staticImages";
import { languageVariablesNames } from "../../common/data/languageVariables";
import NavBar, { NavBarItem } from "../../common/navbar/navBar";
import { ObjectUtils } from "../../common/utils/objectUtils";
import { environment } from "../../config/environment";
import { AuthContext } from "../../context/authProvider";
import { surveyHealth } from "./data/surveyHealth";
import { surveyPersonal } from "./data/surveyPersonal";
import { surveyPrice } from "./data/surveyPrice";
import { surveySmoking } from "./data/surveySmoking";
import { ISurvey } from "./interface/survey";
import "./profil.scss"
import ProfilName from "./profilName";
import SurveyBuilder from "./surveyBuilder";
import PageWrapper from "../../common/components/pageWrapper";
import Hints from "../common/hints";
import FeedbackFormular from "../../common/components/FeedbackFormular";

const Profil = () => {
  const changePasswordUrl = environment.serverUrl + "/recover_password/";

  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);

  const [token, login, logout] = useContext(AuthContext);
  const [userSummary, setUserSummary] = useState<{[key: string]: any}>();
  const [profileStrengthVariables, setProfileStrengthVariables] = useState<{[key: string]: any}>();
  const [profileStrength, setProfileStrength] = useState<number>(0);
  const surveys = [surveyPrice, surveySmoking, surveyHealth, surveyPersonal]
  const [currentSurvey, setCurrentSurvey] = useState<ISurvey>();
  
  const PROFILE_STRENGTH_KEYS = ["hasLogin", "profileStrength_smoking", "profileStrength_price", "profileStrength_health", "profileStrength_personal"]
  const MAX_PROFILE_STRENGTH = 100;

  const toggleSurveyDetail = () => {
    setIsDetailVisible(!isDetailVisible);
  }

  useEffect(() => {
    refreshUserSummary();
    fetchProfileStrengthVariables();
  }, [])

  function refreshUserSummary() {
    getUserSummary(token).then((summary: IUserSummary | undefined) => {
      if(summary) {
        console.log("received: ", summary)
        setUserSummary(summary)
      }
    })
  }

  function fetchProfileStrengthVariables() {
    getCertainVariables(PROFILE_STRENGTH_KEYS, token).then((x: any) => {
      if(x?.variables) {
        setProfileStrengthVariables(x.variables);
      }
    })
  }

  useEffect(() => {
    // get profile strength
    if(profileStrengthVariables) {
      let newProfileStrength = 0;
      for (const key of PROFILE_STRENGTH_KEYS) {
        if(profileStrengthVariables[key]) {
          newProfileStrength += MAX_PROFILE_STRENGTH / PROFILE_STRENGTH_KEYS.length
        }
      }
      setProfileStrength(newProfileStrength)
    }
  }, [profileStrengthVariables])

  function changeName(newName: string) {
    if(userSummary) {
      sendUserVariables({"Name5p": newName}, token)
        .then(x => console.log("send and received: ", x));      
      const userSummaryCopy = {...userSummary};
      userSummaryCopy.variables["Name5p"] = newName;
      setUserSummary(userSummaryCopy);
    }
  }

  function saveSurveyResults(varsToSave: {[key: string]: any}) {
    if (currentSurvey) {
      varsToSave[currentSurvey.successVarName] = true;
      toggleSurveyDetail();
      setUserSummary(undefined);
      setProfileStrengthVariables(undefined);
      sendUserVariables(varsToSave, token).then(x => {
        refreshUserSummary();
        fetchProfileStrengthVariables();
      });
    }
  }

  function handleDeleteClick() {
    if (window.confirm('Jste si jisti že chcete smazat svůj účet? Tato akce je nevratná.')) {
      deleteAccount(token);
      logout();
    } 
  }

  return (
    <PageWrapper>
      <div className="profil-wrapper">
        <NavBar chosenItem={NavBarItem.Profil}></NavBar>
        <div className="profil-content">
          <h1 className="profil-title">Můj profil</h1>  
          <div className="profil-content-panels">
            <div className="profil-content-panel-1">
              <div className="profile-strength-indicator">
                <div className="profile-strength-indicator-title">
                  <span className="profile-strength-indicator-title">Síla profilu:</span>
                  <label className="profile-strength-indicator-label" htmlFor="">{profileStrength}%</label>
                </div>
                <input className="profile-strength-indicator-input" type="range" disabled value={profileStrength}/>
                <Hints text="Doplň ve svém profilu údaje a získej výhody jako automatické zálohování a lepší přizpůsobení  terapie!" order={1} show={false} />
              </div>
              <ul className="profil-survey-list" style={{marginBottom: (profileStrength === 100) ? "0" : "15px"}}>
                {userSummary && profileStrengthVariables ? 
                  surveys.map(survey =>
                    <li className="profil-survey-list-item" key={survey.title}>
                      {profileStrengthVariables[survey.successVarName] !== true &&
                      <button className="profil-survey-button"
                        onClick={e => {
                        setCurrentSurvey(survey);
                        toggleSurveyDetail();
                        }}>
                        <img className="profil-survey-img" src={survey.img} alt={survey.title}/>
                        <span className="profil-survey-title">{survey.title}</span>
                      </button>}
                    </li>
                    ) 
                    : "Načítám dotazníky..."
                }
              </ul>
              <div className="profil-personal-info-processing">
                <span className="profil-span-title">Zpracování osobních údajů</span>
                <Hints text="Souhlasím se zpracováním svých osobních údajů (v rozsahu zejména počtu vykouřených cigaret a dalších v aplikaci zadávaných údajů) za účelem stanovení plánu odvykání kouření. Neposkytnutí tohoto souhlasu může mít vliv na možnost poskytnout služby v plném rozsahu a kvalitě. Souhlas lze kdykoli odvolat prostřednictvím e-mailu na [info@adiquit.com] nebo zrušením účtu kliknutím na 'Zrušit účet'." order={2} show={false} />
              </div>
            </div>

            <div className="profil-content-panel-2">
              {userSummary != null &&
                <ProfilName
                  userName={userSummary.variables["Name5p"]}
                  userSex={userSummary.variables["0Sex"]}
                  changeName={changeName}
                />
              }


              <ul className="profil-settings-list">
                <span className="profil-span-title">Vyber si co chceš udělat</span>
                <li className="profil-settings-list-item">
                  <a className="profil-settings-option" href={changePasswordUrl} target="_blank">
                    <img className="list-item-img" src={STATIC_IMAGES.change_password} alt="change_password"/>
                    <span className="list-item-span">Obnovit heslo</span>
                  </a>
                </li>
                <li className="profil-settings-list-item">
                  <button className="profil-settings-option" onClick={(e) => {logout()}}>
                      <img className="list-item-img" src={STATIC_IMAGES.logoutIcon} alt="logout"/>
                      <span className="list-item-span">Odhlásit se</span>
                  </button>
                </li>
                <li className="profil-settings-list-item">  
                  <button className="profil-settings-option" onClick={(e) => handleDeleteClick()}>
                    <img className="list-item-img" src={STATIC_IMAGES.delete_account} alt="delete_account"/>
                    <span className="list-item-span">Zrušit účet</span>
                  </button>
                </li>
              </ul>
            </div>

            {/* Window for survey page */}
            {userSummary != null && currentSurvey != null &&
              <SurveyBuilder
                save={saveSurveyResults}
                survey={currentSurvey}
                userVariables={userSummary.variables}
                cancel={toggleSurveyDetail}
                isDetailVisible={isDetailVisible}
                languageVariables={ObjectUtils.pickObjectSubset(userSummary.variables, languageVariablesNames)}
                key={currentSurvey.title}
              />
            }
          </div>
        </div>
        <FeedbackFormular formular_src="https://docs.google.com/forms/d/e/1FAIpQLScqIKyvkeXvCLlZOKDQsmXvJXOJDlJy96yinVXNeyPok4C2Rg/viewform?embedded=true" />
      </div>
    </PageWrapper>
  )
}

export default Profil;