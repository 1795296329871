import { IFinalGoodbyePage } from "../interfaces/finalGoodbyePage";

// Window defined by each page. This one appear after user gets last badge, or reach FIN/SUR phase
export const finalGoodbyePages: IFinalGoodbyePage[] = [
  {
    imgUrl: "adiquit_hero_image_final_session_1",
    title: "Urazi{{VerbPast}} jsi kus cesty!",
    text: "<p>Před měsícem jsi ještě kouři{{VerbPast}} mnoho cigaret denně. Pamatuješ, jak se s tebou táhl zápach cigaret na každém kroku? Ty a cigareta jste byli staří známí, kteří bez sebe nedali ani ránu.</p><p>Rozhod{{VerbPast}} ses to ale změnit, a proto ses obráti{{VerbPast}} na mě. Od té doby jsme společně ušli velký kus cesty a překonali všechny překážky. Tu hlavní práci jsi ale odved{{VerbPast}} ty - sta{{VerbPast}} ses nekuřákem!</p>",
    buttonText: "Další"
  },
  {
    imgUrl: "adiquit_hero_image_final_session_2",
    title: "Tvoje cesta pokračuje dál",
    text: "<p>Tvoje cesta stále pokračuje - to nejtěžší máš ale za sebou. Můžeš na sebe být právem pyšn{{AdjEnd}}! Od teď už to zvládneš sám{{AdjArch}}.</p>",
    buttonText: "Další"
  },
  {
    imgUrl: "adiquit_hero_image_final_session_3",
    title: "Jsi připraven{{AdjEnd}}",
    text: "<p>Nemusíš se ale bát, na cestu jsi dobře připraven{{AdjEnd}}. I když chuť na cigaretu může stále číhat za rohem, ty už víš, jak s ní bojovat.</p>",
    buttonText: "Další"
  },
  {
    imgUrl: "adiquit_hero_image_final_session_4",
    title: "Držím ti palce!",
    text: "<p>Ať se ti daří! Jsem rád,že jsem tě mohl poznat.</p><p>Adam</p>",
    buttonText: "Díky"
  },
]