import React, {useState} from "react";
import "./RandomTip.scss"

import { ListUtils } from "../../common/utils/listUtils";
import { tasteTipsTexts } from "./data/tasteTipsTexts";

type Props =
{
    next_tip: boolean;
}

const RandomTip = (props: Props) => {

    const tips: string[] = tasteTipsTexts
    const [randomTip, setRandomTip] = useState<string>(ListUtils.getRandomListElement(tips));

    if (props.next_tip === true)
    {
        return (
            <div className="randomtip-wrapper">
                <span className="randomtip-heading">Náhodný tip</span>
                <div className="randomtip-text">
                    {randomTip}
                </div>
                <button className="randomtip-next" onClick={(e) => {setRandomTip(ListUtils.getRandomListElement(tips.slice().filter(x => x !== randomTip)))}}>
                    Další tip
                </button>
            </div>
        );
    }
    else
    {
        return (
            <div className="randomtip-wrapper">
                <div className="randomtip-text">
                    {randomTip}
                </div>
            </div>
        );
    }
}

export default RandomTip;

