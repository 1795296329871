import { STATIC_IMAGES } from "../../../assets/staticImages";
import { SimpleInputType } from "../../../common/components/simpleInputEl";
import { ISurvey } from "../interface/survey";
import { ISurveyDetailBlockType, ISurveyDetailImageBlock, ISurveyDetailInputBlock, ISurveyDetailInputMultipleBlock } from "../interface/surveyDetailBlock";

export const surveyHealth: ISurvey = {
  title: "Vyplň informace o svém zdraví",
  img: STATIC_IMAGES.greenHearth,
  successVarName: "profileStrength_health",
  showPredicate: (val: any) => val == null,
  pages: [
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_11.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_NUMBER,
          text: "Kolik ti je let?",
          varName: "AnaData_userAge"
        } as ISurveyDetailInputBlock,
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_8.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION,
          text: "Jaký je tvůj aktuální zdravotní stav?",
          varName: "AnaData_userHealth",
          alternatives: [
            {
              label: "Dobrý, nemám žádné obtíže.",
              value: "1",
            },
            {
              label: "Léčím se s onemocněním dýchací soustavy (astma, zápal plic, CHOPN)",
              value: "2",
            },
            {
              label: "Léčím se se srdečně-cévním onemocněním (vysoký krevní tlak, infarkt)",
              value: "3",
            },
            {
              label: "Léčím se s diabetem",
              value: "4",
            },
            {
              label: "Léčím se s duševním onemocněním (deprese, úzkost)",
              value: "5",
            },
            {
              label: "Léčím se s nádorovým onemocněním",
              value: "6",
            },
            {
              label: "Jiné onemocnění:",
              value: "7",
            },
          ],
          lastOptionSimpleInput: {
            inputType: SimpleInputType.TEXT,
            varName: "AnaData_userHealth_text",
          }
        } as ISurveyDetailInputMultipleBlock,
      ]
    },
    {
      detailBlocks: [        
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_9.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE,
          text: "Proděla{{VerbPast}} jsi onemocnění covid-19?",
          varName: "AnaData_userCovid",
          alternatives: [
            {
              label: "Ne.",
              value: "1",
            },
            {
              label: "Ano, už jsem negativní",
              value: "2",
            },
            {
              label: "Ano, ještě se léčím",
              value: "3",
            },
          ]
        } as ISurveyDetailInputMultipleBlock,  
      ]
    },
    {
      detailBlocks: [        
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_10.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE,
          text: "Užíváš pravidelně nějaké léky?",
          varName: "AnaData_userMedicaments",
          alternatives: [
            {
              label: "Ano.",
              value: "1",
            },
            {
              label: "Ne",
              value: "2",
            },
          ]
        } as ISurveyDetailInputMultipleBlock,  
      ]
    },
  ],
}
