import { API_ROUTES } from "../apiRoutes";

function sendPageVariables(body: {[key: string]: any}, token: string): Promise<{} | undefined> {
  const requestOptions = {
    method: 'GET',
    headers: { "Authorization": "Token " + token },
  };
  let urlPageWithVariables = API_ROUTES.sendPageVariablesUrl;
  // Doesnt work because of Session Control. Page variables are send just by Send User Varibles
  // for (const varName of Object.keys(body)) {
  //   urlPageWithVariables += `&${varName}=${JSON.stringify(body[varName])}`
  //   console.log("url page: ", urlPageWithVariables)
  // }
  return  fetch(urlPageWithVariables, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return new Promise<{}>(resolve => resolve(data));
    }).catch(e => {
      return new Promise<undefined>(resolve => resolve(undefined));
    })
}

export default sendPageVariables;