import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser'; 
import { IApiVariableValue } from "../../interfaces/apiVariableValue"
import { vbTranslation } from "../../data/vbTranslation";
import { StringUtils } from "../../../../common/utils/stringUtils";

interface ApTextElProps {
  text: string;
  apiVariables: { [key: string]: IApiVariableValue };
}

const ApTextEl = ({
  text,
  apiVariables
}: ApTextElProps) => {

  const [userVariables, setUserVariables] = useState<{ [key: string]: string }>({})
  const translation: { [key: string]: { [key: string]: string}} = vbTranslation;

  useEffect(() => {
    // Translation from norwegian
    const userVars: { [key: string]: string } = {};
    for (const varName of Object.keys(apiVariables)) {
      const tmp: string | string[] = (apiVariables[varName]).value ?? ""
      if((apiVariables[varName]).isTranslationNeeded) {
        if(typeof tmp === "string") {
          // it is single string as for "OneForeignLanguageTerm"
          if((translation[varName])[tmp]) {
            userVars[varName] = (translation[varName])[tmp]
          }
          // It is "OneForeignLanguageTerm a SecondForeignLanguageTerm"
          else {
            userVars[varName] = StringUtils.replaceAllVariablesInTextWithoutMarkup(tmp, Object.keys(translation[varName]), translation[varName]);
          }
        } else {
          userVars[varName] = tmp.map(x => translation[varName][x]).join(", ")
        }
      } else {
        // If translation not needed, just replaceAll
        userVars[varName] = typeof tmp === "string" ? tmp : tmp.join(", ")
      }
    }
    setUserVariables(userVars);
  }, [apiVariables, translation, text])

  return (
    <React.Fragment>
      {ReactHtmlParser(
        StringUtils.replaceAllVariablesInText(text, Object.keys(userVariables), userVariables)
      )}
    </React.Fragment>
  );
};

export default ApTextEl