import React from "react";
import { STATIC_IMAGES } from "../../../assets/staticImages";
import { IStatistic } from "../interfaces/statistic";
import "./statisticItem.scss"

interface StatisticItemProps {
  statistic: IStatistic
}

const StatisticItem = ({
  statistic
}: StatisticItemProps) => {

  const iconFilePath = require(`./../../../assets/images/phoneApp/${statistic.icon}.png`)

  return (
    <>
      {
        statistic.show ?
        <React.Fragment>
          <div className="statisticItem-row">
            <img className="statisticItem-img" src={iconFilePath} alt={statistic.icon}/>
            <span className="statisticItem-title">{statistic.title}: </span>
            <span className="statisticItem-value">{statistic.val} {statistic.val_dim}</span>
          </div>
        </React.Fragment> :
        <React.Fragment>
          <div className="statisticItem-row">
            <img className="statisticItem-img" src={STATIC_IMAGES.lockedContentIconFilePath} alt={"locked_content"} style={{opacity: 0.5}}/>
            <span className="statisticItem-title" style={{opacity: 0.5}}>{statistic.title}</span>
          </div>
        </React.Fragment>
      }
    </>
  );
};

export default StatisticItem;