import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/authProvider";
import getCertainVariables from "../../api/requests/getCertainVariables";
import "./ProfileStrengthIndicator.scss"

const ProfileStrengthIndicator = () =>
{
    const [profileStrengthVariables, setProfileStrengthVariables] = useState<{[key: string]: any}>();
    const [profileStrength, setProfileStrength] = useState<number>(0);
    const [token] = useContext(AuthContext); //jeste login logout?

    const PROFILE_STRENGTH_KEYS = ["hasLogin", "profileStrength_smoking", "profileStrength_price", "profileStrength_health", "profileStrength_personal"]
    const MAX_PROFILE_STRENGTH = 100;

    useEffect(() => {
        fetchProfileStrengthVariables();
    }, [])

    function fetchProfileStrengthVariables() {
        getCertainVariables(PROFILE_STRENGTH_KEYS, token).then((x: any) => {
            if(x?.variables) {
                setProfileStrengthVariables(x.variables);
            }
    })
    }

    useEffect(() => {
        // get profile strength
        if(profileStrengthVariables) {
          let newProfileStrength = 0;
          for (const key of PROFILE_STRENGTH_KEYS) {
            if(profileStrengthVariables[key]) {
              newProfileStrength += MAX_PROFILE_STRENGTH / PROFILE_STRENGTH_KEYS.length
            }
          }
          setProfileStrength(newProfileStrength)
        }
      }, [profileStrengthVariables])

    return (
        <div className="indicator-wrapper">
          <input className="indicator-input" type="range" disabled value={profileStrength}/>
          <div className="indicator-title">
              <label className="indicator-label" htmlFor="">{profileStrength}%</label>
          </div>
        </div>
    )
}

export default ProfileStrengthIndicator;