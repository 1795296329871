import React from "react";
import { STATIC_IMAGES } from "../../assets/staticImages";
import TextEl from "../../common/components/textEl";
import { IFinalGoodbyePage } from "./interfaces/finalGoodbyePage";

interface IFinalWindowProps {
  finalGoodbyePage: IFinalGoodbyePage;
  cancel: () => void;
  changePage: () => void;
  apiVariables: { [key: string]: any };
}

const FinalWindow = ({
  finalGoodbyePage,
  cancel,
  changePage,
  apiVariables,
}: IFinalWindowProps) => {

  const iconFile = require(`./../../assets/images/phoneApp/${finalGoodbyePage?.imgUrl ?? "adiquit_icon_locked_content"}.png`); 

  return (
    <React.Fragment>
      <div className="appointment-final-goodbye-content">
        <button onClick={(e) => {cancel()}} className="cancel-button">
          <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
        </button>
        {finalGoodbyePage.imgUrl &&
          <img src={iconFile} alt={finalGoodbyePage?.imgUrl} key={finalGoodbyePage?.imgUrl}/>}
        <h2>     
          <TextEl apiVariables={apiVariables}
              presentVarNames={Object.keys(apiVariables)}
              text={finalGoodbyePage.title}/>
        </h2>
        <div className="appointment-final-goodbye-text">
          <TextEl apiVariables={apiVariables}
            presentVarNames={Object.keys(apiVariables)}
            text={finalGoodbyePage.text}/>
        </div>
        <button className="final-goodbye-next-button" onClick={e => changePage()}>{finalGoodbyePage.buttonText}</button>
      </div>
    </React.Fragment>
  );
};

export default FinalWindow