import React, { useEffect, useState } from "react";
import TextEl from "../../common/components/textEl";
import SimpleInputEl, { SimpleInputType } from "../../common/components/simpleInputEl";
import ImageTextEl from "../../common/components/imageEl";
import { ISurveyDetailBlockType, ISurveyDetailImageBlock, ISurveyDetailInputBlock, ISurveyDetailInputMultipleBlock, ISurveyDetailTextBlock, SurveyDetailBlock } from "./interface/surveyDetailBlock";
import MultipleChoiceInputEl from "../../common/components/multipleChoiceInputEl";
import MultipleSelectionInputEl from "../../common/components/multipleSelectionInputEl";

interface ISurveyPageBuilderProps {
  detailBlocks: SurveyDetailBlock[];
  languageVariables: { [key: string]: any },
  userVariables: { [key: string]: string };
  setUserVariables: React.Dispatch<React.SetStateAction<{[key: string]: any}>>
}

const SurveyPageBuilder = ({
  detailBlocks,
  languageVariables,
  userVariables,
  setUserVariables,
}: ISurveyPageBuilderProps) => {

  const [elements, setElements] = useState<JSX.Element[]>([]);

  useEffect(() => {
    buildBadgeDetail();
  }, [userVariables])


  function buildBadgeDetail() {
    const newArray: JSX.Element[] = []
    detailBlocks.forEach(detailBlock =>{
      switch(detailBlock.type) {
        case ISurveyDetailBlockType.TEXT_BLOCK:
          newArray.push(buildTextBlock(detailBlock));
          break;
        case ISurveyDetailBlockType.INPUT_TEXT:
          newArray.push(buildInputTextBlock(detailBlock));
          break;
        case ISurveyDetailBlockType.INPUT_NUMBER:
          newArray.push(buildInputNumberBlock(detailBlock));
          break;
        case ISurveyDetailBlockType.IMAGE_BLOCK:
          newArray.push(buildImageBlock(detailBlock));
          break;
        case ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE:
          newArray.push(buildInputMultipleChoice(detailBlock));
          break;
        case ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION:
          newArray.push(buildInputMultipleSelection(detailBlock));
          break;
      }
    })
    setElements(newArray);
  }

  function buildTextBlock(detailBlock: ISurveyDetailTextBlock): JSX.Element {
    return <TextEl
      text={detailBlock.text}
      presentVarNames={Object.keys(languageVariables)}
      apiVariables={userVariables}
      key={detailBlock.text}
    />
  }

  function buildInputTextBlock(detailBlock: ISurveyDetailInputBlock): JSX.Element {
    return <SimpleInputEl
      labelText={detailBlock.text}
      inputName={detailBlock.varName}
      apiVariables={userVariables}
      setApiVariables={setUserVariables}
      inputType={SimpleInputType.TEXTAREA}
      cssClassName={"success-user-variables-input"}
      key={detailBlock.text}
      required={true}
    />
  }

  function buildInputNumberBlock(detailBlock: ISurveyDetailInputBlock): JSX.Element {
    return <SimpleInputEl
      inputName={detailBlock.varName}
      labelText={detailBlock.text}
      apiVariables={userVariables}
      setApiVariables={setUserVariables}
      inputType={SimpleInputType.NUMBER}
      cssClassName={"success-user-variables-input"}
      key={detailBlock.varName}
      required={true}
      placeholder={detailBlock.placeholder}
    />
  }

  function buildImageBlock(detailBlock: ISurveyDetailImageBlock): JSX.Element {
    return <ImageTextEl
      imageUrl={detailBlock.imgUrl}
      className={"detail-image"}
      key={detailBlock.imgUrl}
    />
  }

  function buildInputMultipleChoice(detailBlock: ISurveyDetailInputMultipleBlock): JSX.Element {
    return <MultipleChoiceInputEl
      labelText={detailBlock.text}
      varName={detailBlock.varName}
      apiVariables={userVariables}
      setApiVariables={setUserVariables}
      alternatives={detailBlock.alternatives}
      lastOptionSimpleInput={detailBlock.lastOptionSimpleInput}
      key={detailBlock.text}
    />
  }

  function buildInputMultipleSelection(detailBlock: ISurveyDetailInputMultipleBlock): JSX.Element {
    return <MultipleSelectionInputEl
      labelText={detailBlock.text}
      varName={detailBlock.varName}
      apiVariables={userVariables}
      setApiVariables={setUserVariables}
      alternatives={detailBlock.alternatives}
      lastOptionSimpleInput={detailBlock.lastOptionSimpleInput}
      key={detailBlock.text}
    />
  }

  return (
    <>
      {elements.map(el => el)}
    </>
  )
};

export default SurveyPageBuilder