export enum IBadgeDetailBlockType {
  TEXT_BLOCK,
  INPUT_TEXT,
  INPUT_TEXT_FALLBACK, // decide between two variables which will be shown
  INPUT_NUMBER,
  IMAGE_BLOCK,
}

export interface IBadgeDetailTextBlock {
  type: IBadgeDetailBlockType.TEXT_BLOCK;
  text: string;
}

export interface IBadgeDetailInputBlock {
  type: IBadgeDetailBlockType.INPUT_TEXT |
   IBadgeDetailBlockType.INPUT_NUMBER;
  text: string;
  varName: string;
  disabled?: boolean;
}

export interface IBadgeDetailInputFallBackBlock {
  type: IBadgeDetailBlockType.INPUT_TEXT_FALLBACK;
  text: string;
  varName: string[];
}

export interface IBadgeDetailImageBlock {
  type: IBadgeDetailBlockType.IMAGE_BLOCK;
  imgUrl: string;
}

export type BadgeDetailBlock =
 IBadgeDetailTextBlock | IBadgeDetailInputBlock |
 IBadgeDetailImageBlock | IBadgeDetailInputFallBackBlock