import React from "react"
import "./message.scss"

interface IMessageProps {
  children: JSX.Element[] | JSX.Element,
  messageType: MessageType,
  specialClass: string | undefined;
}

export enum MessageType {
  Left = 0,
  Right = 1
}

// Component for wrapping content into message like UI
const Message = ({children, messageType, specialClass}: IMessageProps) => {
  return (
    <div className={(messageType === MessageType.Left ? "message-block-left" : "message-block-right") + " " + specialClass}>
      {children}
      <div className={messageType === MessageType.Left ? "message-arrow-left" : "message-arrow-right"}></div>
    </div>
  )
}

export default Message;