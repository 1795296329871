import React, { useContext, useState } from "react";
import Message, { MessageType } from "./components/message";
import "./onboarding.scss";
import { ISimpleRegisterInfo } from "./interfaces/simpleRegisterInfo";
import { ISimpleRegisterLogin } from "./interfaces/simpleRegisterLogin";
import simpleRegister from "../../api/requests/simpleRegister";
import { API_ROUTES } from "../../api/apiRoutes";
import addLoginDetails from "../../api/requests/addLoginDetails";
import { useHistory } from "react-router";
import { STATIC_IMAGES } from "../../assets/staticImages";
import { AuthContext, AuthProvider } from "../../context/authProvider";

const Onboarding = () => {
  const history = useHistory();

  const FEMALE = "female"
  const MALE = "male"
  const [gender, setGender] = useState<string | undefined>();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cigarettesDaily, setCigarettesDaily] = useState<string>("");
  const [validationTexts, setValidationTexts] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notneed, login, notneed2] = useContext(AuthContext);
  const [userToken, setUserToken] = useState<string>();
  const [messageAreasVisiblity, setMessageAreasVisibility] =
   useState<boolean[]>([true, false, false, false, false, false, false, false, false, false])

  function registerUserClicked() {
    if(!validate()) {
      return
    }
    sendRegisterRequest();
  }

  function validate(): boolean {
    const newValidationList = []
    if(gender === undefined || name === "" || email === "" || password === "" ||
    cigarettesDaily === "") {
      newValidationList.push("Vyplň prosím všechna pole");
    }
    if(cigarettesDaily && isNaN(+cigarettesDaily)) {
      newValidationList.push("Počet cigaret za den musí být číselný údaj");
    }
    if(email && !email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      newValidationList.push("Vyplň prosím korektní emailovou adresu");
    }
    if(password && password.length < 8) {
      newValidationList.push("Heslo musí mít alespoň 8 znaků");
    }
    setValidationTexts(newValidationList)
    return newValidationList.length === 0;
  }

  function handleLogin() {
    login(email, password).then((logSuccessful: boolean) => {      
      if (!logSuccessful) {
        const wrong_cred = document.getElementById('wrong');
        const right_cred = document.getElementById('right');
        if (wrong_cred != null && right_cred != null) {
          wrong_cred.style.display = "block";
          right_cred.style.display = "none";
        }
      } else {
        history.push("/home")
      }
    })
  }

  function sendRegisterRequest() {
    const simpleRegisterBody: ISimpleRegisterInfo = {
      Name5p: name,
      sex: gender === FEMALE ? 0 : 1,
      start_time: (new Date()).toJSON(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      NoCig1: +cigarettesDaily,
    }
    const addLoginBody: ISimpleRegisterLogin = {
      email: email,
      password: password,
    }
    // Registration is in two steps: 1) register user without mail and password 2) add login details
    setIsLoading(true);
    if(!userToken) {
      simpleRegister(simpleRegisterBody)
        .then(token => {
          if(token) {
            setUserToken(token);
            sendLoginDetails(addLoginBody, token)
          }
        })
        .catch(error => {console.log(error)})
    } else {
      // If user already created (error in adding login detail) use the user
      sendLoginDetails(addLoginBody, userToken)
    }
  }

  function sendLoginDetails(addLoginBody: ISimpleRegisterLogin, token: string) {
    addLoginDetails(addLoginBody, token)
    .then(res => {
      if(res?.error) {
        setValidationTexts([res.error]);
        setIsLoading(false);
      } else {
        handleLogin();
      }
    })
  }

  function showMessageArea(index: number) {
    // show message areas when the previous one starts filling
    if(!messageAreasVisiblity[index]) {
      const newMessageAreasVisibility = [...messageAreasVisiblity];
      newMessageAreasVisibility[index] = true;
      setMessageAreasVisibility(newMessageAreasVisibility);
    }
  }

  return (
    <div className="Onboarding">
      {/*
      <header className="Onboarding-header">
        <img className="logo-black" alt="logo" src={STATIC_IMAGES.logo} />
      </header>
      */}
      <div className="onboarding-container">
        <div className="image-container">
          <img alt="doctor" className="onboarding-welcome-adam" src={STATIC_IMAGES.doctor} />
        </div>
        <div className="message-container">
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[0] ? "message-animation1" : "message-block-invisible"}>
            <span>Aby nám to spolu fungovalo, měl bych se o tobě leccos dozvědět. Pověz mi prosím něco o sobě.</span>
          </Message>
          <Message messageType={MessageType.Right} specialClass={messageAreasVisiblity[0] ? "message-animation2" : "message-block-invisible"}>
            <span>Jsem:</span>
            <span style={{marginLeft: "5px"}}>
              <input type="radio" value={FEMALE} id="female" name="gender"
              onChange={e => {setGender(e.target.value); showMessageArea(1);}} required/>
              <label className="gender-option-label" htmlFor="female">žena</label>
              <input type="radio" value={MALE} id="male" name="gender"
              onChange={e => {setGender(e.target.value); showMessageArea(1);}} required/>
              <label className="gender-option-label" htmlFor="male">muž</label>
            </span>       
          </Message>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[1] ? "message-animation1" : "message-block-invisible"}>
            <span>Hynku, Viléme, Jarmilo? Pomoz mi se skloňováním a použij prosím 5. pád.</span>
          </Message>
          <Message messageType={MessageType.Right} specialClass={messageAreasVisiblity[1] ? "message-animation2" : "message-block-invisible"}>
            <span>Říkej mi:</span>
            <input type="text" onChange={e => {setName(e.target.value); showMessageArea(2)}} required/>
            <div className="right-arrow"></div>    
          </Message>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[2] ? "message-animation2" : "message-block-invisible"}>
            <span>Abych tě příště poznal, budu potřebovat ještě tvůj email a heslo</span>
          </Message>
          <Message messageType={MessageType.Right} specialClass={messageAreasVisiblity[2] ? "message-animation3 responsive-form-row" : "message-block-invisible responsive-form-row"}>
            <div className="form-input">
              <span>Email:</span>
              <input type="text" style={{marginRight: "20px"}}
              onChange={e => {setEmail(e.target.value); showMessageArea(3)}} required/>
            </div>
            <div className="form-input">
              <span>heslo:</span>
              <input type="password" onChange={e => {setPassword(e.target.value); showMessageArea(3)}}
              minLength={8} required placeholder="Minimálně 8 znaků"/>
            </div>
          </Message>
        </div>
      </div>

      <div className="onboarding-container" style={{paddingTop: "75px"}}>
        <div className="img-container" style={{paddingTop: "250px"}}>
          <div className="img-circle-right-normal">
            <img alt="cigarettes box" src={STATIC_IMAGES.cigarettes}/>
          </div>
          <div className="img-circle-right-small"></div>
          <div className="img-circle-right-smallest"></div>
        </div>
        <div className="message-container" style={{marginTop: "-40px"}}>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[3] ? "message-animation2 message-cigarettes-top-margin" : "message-block-invisible message-cigarettes-top-margin"}>
            <span>Kolik cigaret denně vykouříš, {name}?</span>
          </Message>
          <Message messageType={MessageType.Right} specialClass={messageAreasVisiblity[3] ? "message-animation3 message-cigarettes-bottom-margin" : "message-block-invisible message-cigarettes-bottom-margin"}>
            <div className="range-form-container">
              <div className="range-form">
                <span>0</span>
                <input type="range" style={{margin: "0px 15px"}} step="1" min="0" max="40" value={cigarettesDaily === "" ? "0" : cigarettesDaily}
                onChange={e => {setCigarettesDaily(e.target.value); showMessageArea(4)}} className="range-input" required/>
                <span>40</span>
              </div>
              <div>Denně vykouřím: <strong>{cigarettesDaily}</strong></div>
            </div>
          </Message>
        </div>
      </div>

      <div className="onboarding-container">
        <div className="img-container">
          <div className="img-circle-left-normal">
            <img alt="man hiking" src={STATIC_IMAGES.goal}/>
          </div>
          <div className="img-circle-left-small"></div>
          <div className="img-circle-left-smallest"></div>
        </div>
        <div className="message-container" style={{paddingTop: "90px"}}>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[4] ? "message-animation1" : "message-block-invisible"}>
            <span>Během přípravy si společně naplánujeme postup odvykání. Dobrá příprava je klíčem k úspěchu.</span>
          </Message>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[4] ? "message-animation2" : "message-block-invisible"}>
            <span>Na odvykání je dobré se nejdřív připravit. Během přípravy si společně naplánujeme postup odvykání. Dobrá příprava je klíčem k úspěchu.</span>
          </Message>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[4] ? "message-animation3" : "message-block-invisible"}>
            <span>Odvykání je jako výstup na horu. Začínáš v základním táboře. Nejdřív budeš studovat cestu a připravovat se na náročné situace.</span>
          </Message>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[4] ? "message-animation4" : "message-block-invisible"}>
            <span>V den D vyrazíš na cestu. Tvé odvykání začíná tím, že típneš poslední cigaretu a vydáš se za svým cílem</span>
          </Message>
          <Message messageType={MessageType.Left} specialClass={messageAreasVisiblity[4] ? "message-animation5" : "message-block-invisible"}>
            <span>A začneš stoupat. Možná přijdou pochybnosti, ty se ale neztratíš. Jsem zkušený průvodce a během naší cesty ti pomůžu přestat kouřit.</span>
          </Message>
        </div>
      </div>

      <div className={messageAreasVisiblity[4] ? "message-animation5 onboarding-container-end" : "message-block-invisible onboarding-container-end"}>
        <button className="final-button" onClick={registerUserClicked}>
          Pojďme na to! (Zaregistruj mě)
        </button>
        <div className="validation-box" >
          {validationTexts.map(validationText => <div>{validationText}</div>)}
        </div>
      </div>

      { isLoading &&
        <div className="spinner">
          <div className="spinner-text">
            Načítám...
          </div>
          <div className="loading-spinner"></div>
        </div>
      }

    </div>
  );
}

export default Onboarding;