export class SessionAdapter {

  private readonly regexParsingVariablesFromAngular = new RegExp("<span.*?variables.([a-zA-Z_$0-9]*).*?</span>", "g")
  private parsedSession: any;

  constructor(session: any) {
    this.parsedSession = JSON.parse(JSON.stringify(session))
    this.parseButtons(this.parsedSession.data, this.parsedSession.dead_end)
    this.parseAppointmentTexts(this.parsedSession.data);
   }

  public getParsedSessionData() {
    return this.parsedSession;
  }

  private parseAppointmentTexts(data: any) {
    data.forEach((element: any) => {
      switch(element.content_type) {
        case "text":
          this.parseTextPropertyOnElement(element, "content")
          break;
        case "conditionalset":
          for (const content of element.content) {
            this.parseTextPropertyOnElement(content, "content");
          }
          break;
        case "form":
          for (const formEl of element.content) {
            if(formEl.field_type === "string" || formEl.field_type === "numeric" ||
             formEl.field_type === "textarea" || formEl.field_type === "text") {
              this.parseTextPropertyOnElement(formEl, "label");
            } else if (formEl.field_type === "multiplechoice" || formEl.field_type === "multipleselection") {
              this.parseTextPropertyOnElement(formEl, "label");
              for (const alternativeEl of formEl.alternatives) {
                this.parseTextPropertyOnElement(alternativeEl, "label");
              }
            }
          }
          break;
        case "toggle":
          this.parseTextPropertyOnElement(element, "content")
          this.parseTextPropertyOnElement(element, "toggle")
          break;
        case "button text":
          this.parseTextPropertyOnElement(element, "content")
          break
        case "toggleset":
          this.parseTextPropertyOnElement(element.content, "label")
          for (const alternativeEl of element.content.alternatives) {
            this.parseTextPropertyOnElement(alternativeEl, "label");
            this.parseTextPropertyOnElement(alternativeEl, "text");
          }
          break;
        case "togglesetmulti":
          this.parseTextPropertyOnElement(element.content, "label")
          for (const alternativeEl of element.content.alternatives) {
            this.parseTextPropertyOnElement(alternativeEl, "label");
            this.parseTextPropertyOnElement(alternativeEl, "text");
          }
          break;
      }
    });
  }

  private parseButtons(data: any[], isDeadEnd: boolean) {
    // Sometimes appointment has more buttons than needed or no buttons at all. Put exactly one button at each appointment
    const buttonWithTextNumber = data.filter((x: any) => x.content_type === "button text" && x.content !== "").length;
    if (buttonWithTextNumber > 0) return;
    let buttonNumber = data.filter((x: any) => x.content_type === "button text").length;
    for (const index in data) {
      console.log("INDEX here: ", index)
      if(data[index].content_type === "button text") {
        if (buttonNumber === 1) {
          data[index].content = isDeadEnd ? "Hotovo" : "Další stránka";
        } else {
          buttonNumber = buttonNumber - 1;
        }
      }
    }
  }

  private parseTextPropertyOnElement(element: {[key: string]: any}, property: string): void {    
    const parsedText = this.parseTextFromAngular(element[property]);
    element[property] = parsedText;
  }

  // Transform angular <span ng-content="variable".....> into {{variable}}
  private parseTextFromAngular(text: string): string {
    const parsedText: string = text.replace(this.regexParsingVariablesFromAngular, '{{$1}}')
    return parsedText;
  }
}
