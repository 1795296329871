export const tasteTipsTexts: string[] = [
  "Změň chuť v ústech. Vezmi do ruky kartáček, vytlač na něj pastu a začni si pomalu,  důkladně čistit zuby… Přece si je potom nezašpiníš kouřem!",
  "Nalej si do sklenice vodu, džus nebo limonádu. Pomalu se napij. Soustřeď se na každý doušek. Tekutiny uklidňují. Vypij to do dna...",
  "Změň chuť v ústech. Vezmi si mentolovou žvýkačku nebo bonbon. Vnímej, jak se jejich chuť rozplývá a šíří…",
  "Pěkně po anglicku: což si dát šálek čaje? Mátový, meduňkový nebo heřmánkový mírní napětí, které první dny abstinence zcela přirozeně provází.",
  "Zavři oči, nosem se zhluboka nadechni, ústy pomalu vydechni. Jednou, dvakrát, desetkrát. Klidný dech zklidní stres.",
  "Dej si něco k snědku. Malého, ale dobrého. Připrav si své oblíbené jídlo a v klidu si na něm pochutnej.",
  "Rozpumpuj tělo, vyčistíš si hlavu. Vyběhni a seběhni nejbližší schody, udělej několik dřepů/kliků, vydýchej se, pochval se.",
  "Pusť si oblíbenou písničku, ponoř se do její melodie, soustřeď se na jednotlivé nástroje. Přidat drobnou taneční kreaci? Jasně!",
  "Všichni kolem kouří a ty nevíš, co s rukama? Drž se mobilu, otevři oblíbenou hru a rozjeď to, až se i z tebe bude kouřit.",
  "K čemu jsou přátelé, když ne k pomoci v nouzi? Pošli jim zprávu z bojiště, vymysli společnou akci anebo je prostě pozdrav.",
  "Skoč na Instagram a podívej se, co pěkného kdo přidal ke tvým oblíbeným tématům. Přenes svou pozornost, zabav se.",
  "Posaď se do klidné místnosti, nadechni se, zavři oči a přenes se v myšlenkách na oblíbené místo. Představ si obrazy, zvuky, vůně…",
  "Zopakuj si (nahlas), proč chceš přestat kouřit. Pěkně jeden důvod po druhém. Když důvody trvají, chuť na cigaretu přejde.",
  "Zkus ošálit svoje reflexy. Připrav si sklenici oblíbeného nealkoholického nápoje a vypij ho brčkem. Je to trochu jako šlukování, chuť na cigaretu ustoupí.",
  "Sáhni po nikotinovém spreji nebo žvýkačce. Uvolněný nikotin nechej v ústech působit a pak ho vyplivni. (Nepolykej ho, dráždí žaludek.)",
  "Nevíš, co s rukama? Zabav prsty hlavolamem, antistresovým míčkem nebo plastelínou.",
  "Změň činnost. Odejdi z místnosti, otevři okno, zalij kytky, protáhni se. Přinuť mozek, aby se zabýval něčím jiným.",
  "Naber si do úst ústní vodu a nechej ji tam aspoň 20 vteřin. Šokuj své chuťové buňky, dají chvíli pokoj.",
  "Dej si větší dávku ovoce. Překvap tělo něčím, co by nečekalo.",
  "Ulev svému zvyku něco vypouštět. Skoč si pro bublifuk a překonej svůj dětský rekord ve velikosti bubliny. V každém kole máš deset pokusů.",
  "Nafoukni co nejrychleji nafukovací balónek. Potěš se svou kapacitou plic.",
  "Otevři noviny nebo web a mrkni se, co nového v politice. Přiměj se dočíst aspoň jeden článek. Když vydržíš tohle, zvládneš všechno.",
  "Jóga potlačuje stres a zahání negativní myšlenky všeho druhu. Zkus lotosový květ nebo přihlášku do nejbližšího kurzu. Hned teď!",
  "Jestlipak si pamatuješ nějakou školní básničku? Zapni mozek a odveď jeho pozornost ke klasikům. Takže, jak to bylo, pohádko…?",
  "Hraje hudba? Tak proč netancuješ? Nikdo se nedívá, a jestli ano, tak ať něco vidí!",
  "Jedna cigareta hoří šest až sedm minut. To je skvělý čas na dvě-tři stránky rozečtené knihy.",
  "Co nového na YouTube? Běž se podívat. Chvilka u videa zdraví nepokazí.",
  "Je po ruce někdo blízký? Pevně a dlouze ho obejmi. Zaplaví tě endorfiny, hormony štěstí. A vědomí, že na to nejsi sám.",
  "Nedá pokoj, mozek jeden? Pozlob ho křížovkou nebo sudoku.",
  "Zabav hlavu i ruce, vezmi pastelky, vymaluj antistresovou omalovánku nebo probuď fantazii a nakresli, co první tě napadne.",
  "Že bys měl na stole absolutní pořádek? Přiveď ho k dokonalosti, zaměstnej mysl a zbav ji nepříjemných podprahových podnětů.",
  "Zvedni se a vyraz do parku nebo do lesa. Dívej se a poslouchej, co všechno se tu děje. Ptáci (telefony) zpívají, listí (větve) šumí, potok (kašna) bublá. Dolce vita!",
  "Dej si rychlou studenou sprchu. Šok! Na kouření pěknou chvíli nepomyslíš.",
  "Jak se daří ledničce? Nemusíš ji vyluxovat. Raději si sepiš, co je třeba nakoupit. Nekouřit a ještě hladovět, to je k nepřežití!",
  "Co to kouká z prádelního koše? Šup s prádlem do pračky! Umíš ji i zapnout?",
  "Dřez nám hlásí haldu špinavého nádobí. Dej ho do myčky anebo ho ručně umyj. Mysli na to, jak jsi pilný, úžasný. Zvládneš všechno, na co pomyslíš!",
]