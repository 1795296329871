import { API_ROUTES } from "../apiRoutes";

function sendNotificationReply(reply: boolean, token: string): Promise<any | undefined> {
  return fetch(API_ROUTES.sentNotificationReplyUrl, {
    method: "POST",
    headers: { "Authorization": "Token " + token, "Content-Type": "application/json" },
    // strangely, server only accepts "ja" or "nei"
    body: JSON.stringify({reply: reply ? "ja" : "nei"}),
  }).then(response =>
    response.json()
  ).then(data => {
    return new Promise<any>(resolve => resolve(data))
  }).catch(error => {
    return new Promise<undefined>(resolve => resolve(undefined))
  })
}

export default sendNotificationReply;