import { SimpleInputType } from "../../../common/components/simpleInputEl";

export enum ISurveyDetailBlockType {
  TEXT_BLOCK,
  IMAGE_BLOCK,
  INPUT_TEXT,
  INPUT_NUMBER,
  INPUT_MULTIPLE_CHOICE, // choose only one
  INPUT_MULTIPLE_SELECTION, // choose multiple 
}

export interface ISurveyDetailTextBlock {
  type: ISurveyDetailBlockType.TEXT_BLOCK;
  text: string;
}

export interface ISurveyDetailInputBlock {
  type: ISurveyDetailBlockType.INPUT_TEXT | ISurveyDetailBlockType.INPUT_NUMBER;
  text: string;
  varName: string;
  placeholder?: string;
}

export interface ISurveyDetailInputMultipleBlock {
  type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE | ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION;
  text: string;
  varName: string;
  alternatives: {
    label: string;
    value: string;
  }[];
  lastOptionSimpleInput?: {
    labelText?: string;
    inputType: SimpleInputType;
    varName: string;
  }
}

export interface ISurveyDetailImageBlock {
  type: ISurveyDetailBlockType.IMAGE_BLOCK;
  imgUrl: string;
}

export type SurveyDetailBlock =
 ISurveyDetailTextBlock | ISurveyDetailImageBlock |
 ISurveyDetailInputBlock | ISurveyDetailInputMultipleBlock