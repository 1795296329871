import React, { useState } from "react";
import { IApiVariableValue } from "../../interfaces/apiVariableValue";
import TextEl from "./apTextEl";

interface ApToggleSetElAlternative {
  label: string;
  value: any;
  text: string;
}

interface ApToggleSetElProps {
  labelText: string;
  varName: string;
  apiVariables: {[key: string]: IApiVariableValue},
  setApiVariables: React.Dispatch<React.SetStateAction<{[key: string]: IApiVariableValue}>>;
  alternatives: ApToggleSetElAlternative[];
  required?: boolean;
}

// This is both multi selection and toggle, so if you click something it toggles content
const ApToggleSetEl = ({
  labelText,
  varName,
  apiVariables,
  setApiVariables,
  alternatives,
  required,
}: ApToggleSetElProps) => {

  const [validationMessage, setValidationMessage] = useState<string>("");

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newApiVariables = {...apiVariables};
    newApiVariables[varName].value = e.target.value;
    handleValidity(newApiVariables);
    setApiVariables(newApiVariables);
  }

  function handleValidity(newApiVariables: { [key: string]: IApiVariableValue }) {
    const curValue = newApiVariables[varName];
    if (required && (curValue.value === "" || curValue.value === null || curValue.value?.length === 0)) {
      setValidationMessage("Vyber prosím jednu možnost");
      curValue.valid = false;
      return;
    }
    curValue.valid = true;
    setValidationMessage("");
  }

  return (    
    <div className="multiplechoice">
    <div><strong><TextEl text={labelText} apiVariables={apiVariables}/></strong></div>
    {alternatives.map((alternative, index) =>
      <div key={varName + " " + index}>
        <div className="multiplechoice-toggle-alternative">
          <input id={varName + alternative.label} name={varName} type="radio" value={+alternative.value}
          onChange={e => {handleOnChange(e)}}></input>
          <label htmlFor={varName + alternative.label}>
            <TextEl text={alternative.label} apiVariables={apiVariables}/>
          </label>
        </div>
        {alternative.value === apiVariables[varName].value &&
          <div className="multiplechoice-toggle-alternative-text">
          <TextEl text={alternative.text} apiVariables={apiVariables}/>
          </div>
        }
      </div>)}
    {validationMessage !== "" &&
      <div className="element-validation-message">{validationMessage}</div>}
  </div>
  );
};

export default ApToggleSetEl
