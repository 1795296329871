import React from "react"
import { Link } from "react-router-dom"
import { STATIC_IMAGES } from "../../assets/staticImages"
import './navBar.scss'

interface INavBarProps {
    chosenItem: NavBarItem,
}

  export enum NavBarItem {
    Home = 0,
    Appointment = 1,
    Success = 2,
    Plan = 3,
    Help = 4,
    Profil = 5,
}

const NavBar = ({chosenItem}: INavBarProps) => {

    return (
        //<header>
            <div className="navbar-wrapper">
                <div className="navbar-home">
                    <Link to="/home"><img className="logo" alt="logo" src={STATIC_IMAGES.logo} /></Link> 
                </div>
                <div className="navbar-items">
                    <ul className="navbar-ul">
                        <li className="navbar-li">
                            <img className="navbar-img" alt="homeImage" src={STATIC_IMAGES.home}/>
                            <Link to="/home" className={"navbar-home-title" + (chosenItem === NavBarItem.Home ? " navbar-chosen-item" : "")}>Přehled</Link>

                        </li>
                        <li className="navbar-li">
                            <img className="navbar-img" alt="greenHearth" src={STATIC_IMAGES.greenHearth}/>
                            <Link to="/appointment" className={"navbar-item-title" + (chosenItem === NavBarItem.Appointment ? " navbar-chosen-item" : "")}>Sezení</Link>
                        </li>
                        <li className="navbar-li">
                            <img className="navbar-img" alt="medal" src={STATIC_IMAGES.medal}/>
                            <Link to="/success" className={"navbar-item-title" + (chosenItem === NavBarItem.Success ? " navbar-chosen-item" : "")}>Mé úspěchy</Link>
                        </li>
                        <li className="navbar-li">
                            <img className="navbar-img" alt="checklist" src={STATIC_IMAGES.checklist}/>
                            <Link to="/plan" className={"navbar-item-title" + (chosenItem === NavBarItem.Plan ? " navbar-chosen-item" : "")}>Můj plán</Link>
                        </li>
                        <li className="navbar-li">
                            <img className="navbar-img" alt="checklist" src={STATIC_IMAGES.cigaretteStub}/>
                            <Link to="/help" className={"navbar-item-title" + (chosenItem === NavBarItem.Help ? " navbar-chosen-item" : "")}>Moje podpora</Link>
                        </li>
                        <li className="navbar-li">
                            <img className="navbar-img" alt="account" src={STATIC_IMAGES.account}/> 
                            <Link to="/profil" className={"navbar-item-title" + (chosenItem === NavBarItem.Profil ? " navbar-chosen-item" : "")}>Můj účet</Link>
                        </li>
                    </ul>
                </div>
            </div>
        //</header> 
    )
}

export default NavBar;
