import React, { useContext, useEffect, useRef, useState } from "react"
import NavBar, { NavBarItem } from "../../common/navbar/navBar";
import { IStatistic } from "./interfaces/statistic";
import StatisticItem from "./components/statisticItem";
import getUserStatistics from "../../api/requests/getUserStatistics"
import "./success.scss"
//a
import Rewards from "./components/rewards";
import Hints from "../common/hints";
import PageWrapper from "../../common/components/pageWrapper";
import FeedbackFormular from "../../common/components/FeedbackFormular";

import { AuthContext } from "../../context/authProvider";
import sendUserVariables from "../../api/requests/sendUserVariables";
import { ObjectUtils } from "../../common/utils/objectUtils";
import { STATIC_IMAGES } from "../../assets/staticImages";
import { languageVariablesNames } from "../../common/data/languageVariables";
import getCertainVariables from "../../api/requests/getCertainVariables";
import Badges from "./components/Badges";


const Success = () => {
  const [token, login, logout] = useContext(AuthContext);

  const varNamesNeeded = ["VerbPast", "AdjArch", "AdjEnd", "Name5p", "EEcp", "PHASE", "FU", "NoCig1", "ReaQui", "ChangeTalkNeed",
    "SmoUrPlan", "CessationPlan", "PlanForCessationDay", "SmokingCuesList", "HRSitPlan", "SupPer",
    "ValuesAndSmo", "PlanForDayBeforeCessationDay"];

  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [statistics, setStatistics] = useState<IStatistic[]>([]);
  const [userVariables, setUserVariables] = useState<{[key: string]: any}>({});


  //vojta
  const hintFirst = "Zajímá tě, co všechno se ti při odvykání podařilo dosáhnout? Rychlý přehled o důležitých číslech najdeš zde.";
  const hintSecond = "Přehled tvých úspěchů a pokroků terapie. Klepnutím na odznáček si můžeš připomenout obsah jednotlivých sezení.";
  const hintThird = "Tady najdeš odměny, které jsem ti připravil. Pomůžou ti například s hubnutím, nebo překonat chuť na cigaretu.";
  let showHintFirst:boolean = true;
  let showHintSecond:boolean = true;
  let showHintThird:boolean = true;

  useEffect(() => {
    setLoadingCount(loadingCount + 1);
    getUserStatistics(token).then(stats => {setStatistics(stats); setLoadingCount(loadingCount - 1)})
  }, [])

  return (
    <PageWrapper>
      <div className="success-wrapper">
        <NavBar chosenItem={NavBarItem.Success}></NavBar>
        <div className="success-content">
          <h1 className="success-title">Mé úspěchy</h1>
          <div className="success-content-panels">
            <div className="success-content-panel-1">
            {/* Content panel 1 */}
              <div className="success-statistics-wrapper-description">
                <span className="success-description-title">Mé statistiky</span>
                <img className="success-description-img" src={STATIC_IMAGES.successStatisticsBackground} alt="statistics"/>
                <Hints text={hintFirst} order={1} show={showHintFirst} />
              </div>
            {/* Content panel 1 */}
              <div className="badges-wrapper">
                <span className="badges-title">Neodemčené odznaky:</span>
                <Badges badge_type="notacquired" />
              </div>

            {/* Content panel 1 */}
              <div className="badges-wrapper">
                <span className="badges-title">Odemčené odznaky:</span>
                <Badges badge_type="acquired" />
              </div>
                {/* Content panel 1 */}
              <div className="success-rewards-wrapper-description">
                <span className="success-description-title">Mé odměny</span>
                <img className="success-description-img" src={STATIC_IMAGES.success_rewards_background_image} alt="statistics"/>
                <Hints text={hintThird} order={3} show={showHintThird} />
              </div>
            </div>
            <div className="success-content-panel-2">
            {/* Content panel 2 */}
              <div className="success-statistics-wrapper">
                <div className="success-statistics-wrapper-title">Co už jsme spolu zažili</div>
                {loadingCount > 0 && "Načítá se obsah..."}
                {statistics.slice().map(stat => <StatisticItem statistic={stat} key={stat.title}/>)}
              </div>

            {/* Content panel 2 */}
              <div className="success-badges-wrapper-description"> 
                <span className="success-description-title" id="success-badges-area">Mé odvykání</span>
                <img className="success-description-img" src={STATIC_IMAGES.successBadgesBackground} alt="fire"/>  
                <Hints text={hintSecond} order={2} show={showHintSecond} />
              </div>
              {/* Content panel 2*/}
              <div className="success-rewards-wrapper-table">
                {loadingCount > 0 && "Načítá se obsah..."}
                <Rewards />
              </div>
            </div>
          </div>
        </div>
        <FeedbackFormular formular_src="https://docs.google.com/forms/d/e/1FAIpQLSd0lnFQK3ddTUCgZMG4L52eOgBGjdjNq8Wa3uDLLv3ucMQ2cA/viewform?embedded=true" />
      </div>
    </PageWrapper>
  )
}

export default Success;