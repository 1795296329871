import React, { useEffect, useState } from "react"
import "./memento.scss"
import { IMemento } from "../data/mementos"
import SimpleInputEl, { SimpleInputType } from "../../../common/components/simpleInputEl";

interface IMementoProps {
  memento: IMemento;
  userVariables: { [key: string]: any };
  setUserVariables: (variables: {[key: string]: string}, varName: string) => void ;
}

const Memento = ({
  memento,
  userVariables,
  setUserVariables
}: IMementoProps) => {

  const [temporaryUserVariables, setTemporaryUserVariables] = useState<{ [key: string]: string }>(
    Object.assign({}, userVariables));
  const mementoImage = require(`./../../../assets/images/phoneApp/${memento.imageName}`)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);

  useEffect(() => {    
    console.log(temporaryUserVariables[memento.variableName] != null, temporaryUserVariables[memento.variableName] !== "")
    setIsSaveButtonDisabled(temporaryUserVariables[memento.variableName] == null || temporaryUserVariables[memento.variableName] === "");
  }, [temporaryUserVariables])

  function saveUserVariables() {
    if(!isFormValid()) {
      console.log("New value not valid")
      return; 
    }
    console.log("inputVars", temporaryUserVariables)
    setUserVariables(temporaryUserVariables, memento.variableName);
  }

  function isFormValid(): boolean {    
    if(temporaryUserVariables[memento.variableName] === "") {
      return false
    }
    return true
  }

  return (
    <div className="plan-memento">
      <div className="plan-memento-header">
        <span className="plan-memento-heading">{memento.title}</span>
        <img src={mementoImage} alt="memento image"/>
      </div>
      <div className="plan-memento-body">
        <div className="plan-memento-text">
          <SimpleInputEl
            inputName={memento.variableName}
            labelText={""}
            apiVariables={temporaryUserVariables}
            setApiVariables={setTemporaryUserVariables}
            inputType={SimpleInputType.TEXTAREA}
            cssClassName={"memento-user-variable-input"}
            required={true}
          />
        </div>
        {/* <button onClick={(e) =>
          setTemporaryUserVariables(Object.assign({}, userVariables))}>Zrušit změnu</button> */}
        <button className="plan-memento-save-button" onClick={(e) => {saveUserVariables()}}
          disabled={isSaveButtonDisabled}>
            Uložit
        </button>
      </div>
    </div>
  )
}

export default Memento;