import React, { useEffect, useState } from "react"
import { STATIC_IMAGES } from "./assets/staticImages";
import { AuthProvider } from "./context/authProvider";
import { DebugProvider } from "./context/debugProvider";
import { Offline } from "react-detect-offline";
import routes from "./routes";

const App = () => {

  const [displayFinalWindow, setDisplayFinalWindow] = useState<boolean>(false);  
  const MAX_TABLET_WIDTH = 950

  useEffect(() => {
    if (window.innerWidth < MAX_TABLET_WIDTH) {
      setDisplayFinalWindow(true);
    }
  }, [])

  return (
    <AuthProvider>
      <DebugProvider>
        {routes}
        {/* Window which shows when screen to small, to promote mob app */}
        {<div className="app-small-screen-window" style={{display: displayFinalWindow ? "flex" : "none"}}>
          <button onClick={(e) => {setDisplayFinalWindow(false)}} className="cancel-button">
            <img src={STATIC_IMAGES.cancelImg} alt="cancel"/>
          </button>
          <img src={STATIC_IMAGES.doctor} alt="doctor" />
          <h2>Vyzkoušej mobilní aplikaci.</h2>
          <div> 
            Tyto stránky nejsou optimalizované pro malé obrazovky. Pokud používáš telefon nebo tablet, nezdráhej se najít mě na systému Android nebo IOS.
            Budu tvůj kapesní terapeut! Více na <a href="https://www.adiquit.cz/">https://www.adiquit.cz/</a>
          </div>
          <button className="app-small-button" onClick={e => setDisplayFinalWindow(false)}>Chápu</button>
        </div>}
        {/* Show indication if user offline. But the api is not perfect and it has to send periodic checks to pages */}
        {/* <Offline>
          <div id="app-offline-notice">
            Aplikace se nemohla spojit se serverem a přešla do offline režimu. V tomto režimu můžete prohlížet poslední dostupný obsah ale nemůžete měnit existující data a procházet sezení.
          </div>
        </Offline> */}
      </DebugProvider>
    </AuthProvider>
  )
}

export default App;