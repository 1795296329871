import React, { useContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import BadgeDetailBuilder from "../badgeDetailBuilder";
import BadgeItem from "../components/badgeItem";
import { badgeData } from "../data/badges";
import { IBadge } from "../interfaces/badge";
import getCertainVariables from "../../../api/requests/getCertainVariables";
import { AuthContext } from "../../../context/authProvider";
import { languageVariablesNames } from "../../../common/data/languageVariables";
import sendUserVariables from "../../../api/requests/sendUserVariables";
import { ObjectUtils } from "../../../common/utils/objectUtils";
import "./Badges.scss"

type Props = 
{
    badge_type: string;
}

const Badges = (props: Props) =>
{
    const [token, login, logout] = useContext(AuthContext);
    const varNamesNeeded = ["VerbPast", "AdjArch", "AdjEnd", "Name5p", "EEcp", "PHASE", "FU", "NoCig1", "ReaQui", "ChangeTalkNeed",
        "SmoUrPlan", "CessationPlan", "PlanForCessationDay", "SmokingCuesList", "HRSitPlan", "SupPer",
        "ValuesAndSmo", "PlanForDayBeforeCessationDay"];

    const [loadingCount, setLoadingCount] = useState<number>(0);


    const [userVariables, setUserVariables] = useState<{[key: string]: any}>({});
    const [badges, setBadges] = useState<IBadge[]>(badgeData.map(x => Object.assign({}, x)));
    const notAcquiredBadges = badges.filter(badge => !userVariables[badge.userVariableName] || !badge.showPredicate(userVariables[badge.userVariableName]))
    const acquiredBadges = badges.slice().filter(badge => userVariables[badge.userVariableName] &&
        badge.showPredicate(userVariables[badge.userVariableName]));
    const [currentBadgeIndex, setCurrentBadgeIndex] = useState<number | undefined>(undefined);
    const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);

    useEffect(() => {
        setLoadingCount(loadingCount + 1);
        getUserVariablesAndUpdateBadges();
    }, [])

    function getUserVariablesAndUpdateBadges() {
        setLoadingCount(loadingCount + 1);
        getCertainVariables(varNamesNeeded, token).then((x: any) => {
          if(x?.variables) {
            setLoadingCount(loadingCount - 1)
            setUserVariables(x.variables)
          }
        })
    }

    useEffect(() => {
        if(Object.keys(userVariables)?.length === 0) {
          return;
        }
        const newBadges: IBadge[] = [];
        // Replace language variables in text
        badges.forEach(function(badge, index) {
          badge.title = languageVariablesNames.reduce((acc, varName: string) => {
            return acc.replace(`{{${varName}}}`, userVariables[varName]);
          }, badgeData[index].title)
          newBadges.push(badge);
        })
        setBadges(newBadges);
    }, [userVariables])

    function changeCurrentBadgeIndex(newIndex: number | undefined) {
        if(newIndex == null || newIndex < 0 || newIndex >= acquiredBadges?.length) {
          return;
        }
        setCurrentBadgeIndex(newIndex);
    }

    const toggleBadgeDetail = () => {
    setIsDetailVisible(!isDetailVisible);
    }

    const saveUserVariables = (changedVarNames: string[], userVars: {[key: string]: string}) => {
    toggleBadgeDetail();
    console.log("Sending: ", changedVarNames, userVars, ObjectUtils.pickObjectSubset(userVars, changedVarNames))
    sendUserVariables(ObjectUtils.pickObjectSubset(userVars, changedVarNames), token)
        .then(result => {
            if(result) {
                getUserVariablesAndUpdateBadges();
            }
        })
    }

    if (props.badge_type == "acquired")
    {
        return (
            <>
            <ul className="badges-wrapper-list">  
                {loadingCount > 0 &&  "Načítá se obsah..."}
                {acquiredBadges.slice().reverse().map(badge => 
                <li className="badge-li">
                    <BadgeItem
                    title={badge.title}
                    imageUrl={badge.imageUrl}
                    order={badge.order}
                    show={true}
                    onClick={(e: any) => {
                        setCurrentBadgeIndex(acquiredBadges.map(x => x.title).indexOf(badge.title));
                        toggleBadgeDetail()}}
                    key={badge.title}
                    />
                </li>)}
            </ul>

            {createPortal(
                <BadgeDetailBuilder
                    badge={currentBadgeIndex != null ? acquiredBadges[currentBadgeIndex] : undefined}
                    languageVariables={ObjectUtils.pickObjectSubset(userVariables, languageVariablesNames)}
                    userVariables={userVariables}
                    cancel={toggleBadgeDetail}
                    save={saveUserVariables}
                    key={(currentBadgeIndex != null ? acquiredBadges[currentBadgeIndex] : undefined)?.title + isDetailVisible.toString()}
                    isDetailVisible={isDetailVisible}
                    currentBadgeIndex={currentBadgeIndex}
                    setCurrentBadgeIndex={changeCurrentBadgeIndex}
                    badgeElementsNumber={acquiredBadges.length}
                />, 
                document.body
            )}
            </>
        )
    }
    else if (props.badge_type == "notacquired")
    {
        return (
            <ul className="badges-wrapper-list">
                {
                    // Get next not acquired badge to show as locked
                    loadingCount > 0 ? "" :
                    (notAcquiredBadges.length > 0 ?
                    notAcquiredBadges.map(lockedBadge => 
                        <li className="badge-li">
                            <BadgeItem
                                /* key={lockedBadge.id} +  v badges.tsx pridat id prop */
                                title={lockedBadge.title}
                                imageUrl={"adiquit_icon_locked_content.png"}
                                order={lockedBadge.order}
                                show={false}
                                onClick={() => {}}
                            />
                        </li>)
                    :
                    <span>Získal jsi už všechny odznaky.</span>)
                }
            </ul>
        )
    }
    else
    {
        return (
            <span className="badges-error">Error: use a valid class name for Badges component!</span>
        )
    }
}

export default Badges;