// Translation for norwegian variables in sessions
export const vbTranslation = {
  "ChoTop": {
    "røykeslutt og vekt": "Jak mám přestat kouřit a nepřibrat na váze?",
    "stressmestring": "Jak budu zvládat bez cigaret stres?",
    "teknikker for å håndtere følelser": "Jak budu přemáhat zlost, smutek nebo trému?",
    "røykeslutt og identitet": "Kdo budu, když nebudu kuřák?",
    "røyking, røykfrihet og fest": "Jaké to je být ve společnosti a už si nezapálit?",
  },
  "Desires": {
   'ta vare på helsen din': 'žít zdravěji',
    'bli kvitt røykhosten': 'přestat kašlat',
    'få bedre kondis og lettere pust': 'mít lepší kondici a nezadýchávat se',
    'slippe bekymringer og dårlig samvittighet': 'zbavit se obav a výčitek svědomí',
    'ha et bra utseende': 'vypadat zdravě a svěže',
    'få mer indre ro': 'mít vnitřní klid',
    'få bedre selvtillit': 'získat sebedůvěru',
    'føle at du har kontroll': 'získat zpět kontrolu nad vlastním životem',
    'ha bedre råd': 'ušetřit spoustu peněz',
  },
  'HRSit': {
    'når du er på fest': 'když se veselím na večírku',
    'når du er sammen med venner som røyker': 'když jsem s přáteli, kteří kouří',
    'når du ser partneren din røyke': 'když jsem se svým protějškem, který kouří',
    'når du går eller kjører forbi et sted som selger røyk': 'když půjdu kolem obchodu, trafiky nebo benzinky',
    'når du er og handler': 'při nákupu',
    'når du er på kafé': 'nad kávou',
    'når du er på pub eller utested': 'v hospodě nebo v baru',
    'når du er på ferie': 'na dovolené',
    'om morgenen': 'ráno',
    'når du kjeder deg': 'když se nudím nebo při čekání',
    'etter at du har spist': 'po jídle',
    'etter en krangel': 'když se rozčílím nebo se s někým pohádám',
    'når du trenger en pause på jobben': 'během pracovní pauzy',
    'når du drikker kaffe': 'při kávě',
    'når du drikker vin': 'když si dám pivo, víno nebo panáka',
    'når du er trist': 'když budu smutn{{AdjEnd}}',
    'når du føler deg ensom': 'když se budu cítit osaměl{{AdjEnd}}',
  },
  'PreRLpsRea': {
    'en fest': 'večírkem, oslavou',
    'en ferie': 'dovolenou',
    'at du var sammen med venner': 'trávením společného času s přáteli, kolegy',
    'en vanskelig livshendelse': 'težkým obdobím, životní krizí',
    'at du følte deg ensom': 'pocitem osamělosti',
    'Other': 'žádným z uvedených',
  },
  'SucAttrib': { 
    'du': 'Já.', // !!! nutno nahrazovat posledni...
    'familien din': 'rodina',
    'vennene dine': 'přátelé',
    'partneren din': 'partner/ka',
    'kollegaene dine': 'kolegové v práci',
    'jeg': 'Adam',
  },
  'SmoUrStrat': {
    'få en annen smak i munnen': 'dostat do úst jinou chuť',
    'gjøre noe hyggelig': 'dělat něco příjemného',
    'finne på noe annet': 'změnit aktivitu nebo místo',
    'bevege deg': 'se pohybovat',
    'gjøre noe med rastløsheten i fingrene': 'zaměstnat své prsty',
    'bruke nikotinerstatning': 'použít nikotinové náhražky',
    'tenke lure tanker': 'se pokusit překonat chuť na cigaretu myšlenkami',
    'få støtte fra andre': 'hledat podporu u ostatních',
    'roe deg ned': 'se uklidnit',
    'vente og se om røykesuget går over': 'počkat, jestli chuť kouřit přejde',
  },
  'Values': {
    'ansvarlighet og pålitelighet': 'zodpovědnost a spolehlivost',
    'familie': 'rodina',
    'frihet og selvstendighet': 'svoboda jednání, samostatnost',
    'god helse': 'zdraví',
    'humor': 'pohoda, dobrá nálada',
    'indre fred': 'vnitřní klid',
    'integritet': 'upřímnost k sobě',
    'kjærlighet': 'láska',
    'kreativitet og nysgjerrighet': 'zvídavost, kreativita',
    'kunnskap': 'znalosti, vědomosti',
    'mot': 'odvaha',
    'natur': 'příroda',
    'nøkternhet': 'umírněnost',
    'popularitet': 'oblíbenost, popularita',
    'rettferdighet': 'spravedlnost',
    'seksualitet': 'sexualita',
    'selvrespekt': 'sebeúcta',
    'spenning og moro': 'vzrušení, zábava',
    'tilstedeværelse': 'užívání si života',
    'toleranse': 'tolerance',
    'tradisjon': 'tradice',
    'vennskap': 'přátelství',
    'ydmykhet': 'pokora',
    'ærlighet': 'poctivost',
    'å ha et bra utseende': 'vzhled, přitažlivost',
    'åndelighet og åndelig vekst': 'duchovní rozvoj',
  }
}