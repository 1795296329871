import { STATIC_IMAGES } from "../../../assets/staticImages";
import { SimpleInputType } from "../../../common/components/simpleInputEl";
import { ISurvey } from "../interface/survey";
import { ISurveyDetailBlockType, ISurveyDetailImageBlock, ISurveyDetailInputMultipleBlock } from "../interface/surveyDetailBlock";

export const surveyPersonal: ISurvey = {
  title: "Vyplň informace o sobě",
  img: STATIC_IMAGES.home,
  successVarName: "profileStrength_personal",
  showPredicate: (val: any) => val == null,
  pages: [
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_12.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE,
          text: "Kde momentálně žiješ?",
          varName: "AnaData_townSize",
          alternatives: [
            {
              label: "Ve městě s více než 1 milionem obyvatel",
              value: "1",
            },
            {
              label: "Ve městě nad 100 000 obyvatel",
              value: "2",
            },
            {
              label: "Ve městě nad 10 000 obyvatel",
              value: "3",
            },
            {
              label: "V menším městě do 10 000 obyvatel",
              value: "4",
            },
            {
              label: "V obci do 2 000 obyvatel",
              value: "5",
            },
          ]
        } as ISurveyDetailInputMultipleBlock
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_13.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_SELECTION,
          text: "Jak jsi na tom se zaměstnáním?",
          varName: "AnaData_userJob",
          alternatives: [
            {
              label: "Jsem zaměstnán{{AdjArch}} na plný úvazek",
              value: "1",
            },
            {
              label: "Jsem zaměstnán{{AdjArch}} na částečný úvazek",
              value: "2",
            },
            {
              label: "Jsem živnostník (OSVČ)",
              value: "3",
            },
            {
              label: "Jsem nezaměstnan{{AdjEnd}}",
              value: "4",
            },
            {
              label: "Jsem student/ka prezenčního studia",
              value: "5",
            },
            {
              label: "Jsem student/ka dálkového/kombinovaného studia",
              value: "6",
            },
            {
              label: "Jsem na rodičovské dovolené",
              value: "7",
            },
            {
              label: "Jsem v důchodu",
              value: "8",
            },
            {
              label: "Jsem aktuálně v pracovní neschopnosti v důsledku nemoci",
              value: "9",
            },
            {
              label: "Jiný status: ",
              value: "10",
            },
          ],
          lastOptionSimpleInput: {
            inputType: SimpleInputType.TEXT,
            varName: "AnaData_userJob_text",
          }
        } as ISurveyDetailInputMultipleBlock,
      ]
    },
    {
      detailBlocks: [
        {
          type: ISurveyDetailBlockType.IMAGE_BLOCK,
          imgUrl: "adiquit_image_questionnaire_14.png",
        } as ISurveyDetailImageBlock,
        {
          type: ISurveyDetailBlockType.INPUT_MULTIPLE_CHOICE,
          text: "Nejvyšší dosažené vzdělání?",
          varName: "AnaData_userEducation",
          alternatives: [
            {
              label: "Základní",
              value: "1",
            },
            {
              label: "Střední",
              value: "2",
            },
            {
              label: "Vyšší odborné (OSVČ)",
              value: "3",
            },
            {
              label: "Vysokoškolské (bakalářský titul)",
              value: "4",
            },
            {
              label: "Vysokoškolské (magisterský titul)",
              value: "5",
            },
            {
              label: "Vysokoškolské (doktorský titul nebo vyšší)",
              value: "6",
            },
          ]
        } as ISurveyDetailInputMultipleBlock

      ]
    },
  ],
}