import React from "react";
import ReactHtmlParser from 'react-html-parser'; 

interface TextElProps {
  text: string;
  presentVarNames: string[];
  apiVariables: { [key: string]: string | string[]};
}

const TextEl = ({
  text,
  presentVarNames,
  apiVariables
}: TextElProps) => {

  function getStringVarValue(varName: string): string {
    const curValue = apiVariables[varName];
    if(Array.isArray(curValue)) {
      return curValue.join(", ")
    } else {
      return curValue;
    }
  }

  return (
  <React.Fragment>
    {ReactHtmlParser(
      presentVarNames.reduce((acc, varName) => {
        // Split and join is the same as replaceAll() which typescript doesnt have
        return acc.split(`{{${varName}}}`).join(getStringVarValue(varName));
      }, text)
    )}
  </React.Fragment>);
};

export default TextEl